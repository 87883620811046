import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { useState, useEffect, ReactNode } from "react";
import Button from "../../../components/Button";
import useAddNoteMutation from "../../../hooks/data/mutations/useAddNoteMutation";
import { TransactionNote } from "../../../services/transactionsService";
import Skeleton from "../../../components/Skeleton";
import { useSnackbar } from "../../../components/Snackbar";
import Table from "../../../components/Table";
import { getTransactionNotesTableColumns } from "./transactionNotesTableColumns";
import Panel from "../../customerSettings/Panel";

export type TransactionNotesTableMeta = {
  onNoteSaved: (text: string) => void;
  onNoteCanceled: () => void;
  isSavingNote: boolean;
};

export type Props = {
  pspReference: string;
  transactionNotes: TransactionNote[];
  timeZoneCode: string;
  isLoading?: boolean;
  isFetching?: boolean;
};

function TransactionNotes({
  pspReference,
  transactionNotes,
  timeZoneCode,
  isLoading = false,
  isFetching = false,
}: Props): JSX.Element {
  const [tableNotes, setTableNotes] =
    useState<TransactionNote[]>(transactionNotes);
  const [isAddingRow, setIsAddingRow] = useState<boolean>(false);
  const { mutate: saveNote, isLoading: isSaving } = useAddNoteMutation();
  const { openSnackbar } = useSnackbar({
    position: "bottom",
    duration: 5000,
  });

  useEffect(() => {
    setTableNotes(transactionNotes);
  }, [transactionNotes]);

  function handleAddNote() {
    setIsAddingRow(true);
    setTableNotes((prevNotes) => [
      {
        createdBy: "",
        dateCreated: "",
        noteText: "",
      },
      ...prevNotes,
    ]);
  }

  function handleNoteSaved(noteText: string) {
    saveNote(
      { pspReference, noteText },
      {
        onSuccess() {
          setIsAddingRow(false);
          setTableNotes((prevNotes) => [
            ...prevNotes.filter(
              (n) =>
                n.noteText !== "" && n.createdBy !== "" && n.dateCreated !== ""
            ),
          ]);
          openSnackbar("Note added.", { type: "success" });
        },
        onError() {
          openSnackbar("Failed to add note. Please try again.", {
            type: "error",
          });
        },
      }
    );
  }

  function handleNoteCanceled() {
    setIsAddingRow(false);
    setTableNotes((prevNotes) =>
      prevNotes.filter(
        (n) => n.noteText !== "" && n.createdBy !== "" && n.dateCreated !== ""
      )
    );
  }

  function getContent(): ReactNode {
    if (isLoading || (isFetching && tableNotes.length === 0)) {
      return (
        <div className="flex w-full gap-x-20">
          <Skeleton className="w-32" />
          <Skeleton className="w-32" />
          <Skeleton className="w-96" />
        </div>
      );
    }

    if (tableNotes.length === 0) {
      return <div className="px-3 pb-3">There are no notes to display.</div>;
    }

    return (
      <div className="flex flex-col gap-y-2">
        <div>All notes are in {timeZoneCode} Time zone.</div>
        <Table
          id="notes"
          columns={getTransactionNotesTableColumns()}
          data={tableNotes}
          isLoading={isFetching}
          sorting={[
            {
              id: "dateCreated",
              desc: true,
            },
          ]}
          showPaginator={false}
          className="max-h-60 overflow-y-auto"
          meta={{
            onNoteSaved: handleNoteSaved,
            onNoteCanceled: handleNoteCanceled,
            isSavingNote: isSaving,
          }}
        />
      </div>
    );
  }

  return (
    <Panel className="my-2 w-full rounded-lg border border-gray-200 shadow-sm">
      <Panel.Header title="Notes" className="w-full rounded-b-none">
        <Button
          size="sm"
          onClick={handleAddNote}
          disabled={isAddingRow || isLoading}
          className="flex items-center text-base"
          icon={<PlusIcon />}
        >
          Add Note
        </Button>
      </Panel.Header>
      <Panel.Content>
        <div className="overflow-x-auto pt-2">{getContent()}</div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionNotes;
