import { useState } from "react";
import Button, { ButtonProps } from "./Button";
import Popover from "./Popover";
import useClickOutside from "../hooks/useClickOutside";

type DropdownButtonOption = {
  text: string;
  onClick: () => void;
};

type Props = ButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    options: DropdownButtonOption[];
    className?: string;
  };

function DropdownButton({
  options,
  className,
  children,
  ...otherProps
}: Props): JSX.Element {
  const [buttonEl, setButtonEl] = useState<HTMLElement | null>(null);
  const optionsEl = useClickOutside(() => setShowOptions(false));
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div ref={setButtonEl} className={className}>
      <Button
        {...otherProps}
        onClick={(e) => {
          if (otherProps.onClick) {
            otherProps.onClick(e);
          }
          setShowOptions(true);
        }}
        separator
      >
        {children}
      </Button>
      <Popover
        open={showOptions}
        placement="bottom-end"
        offset={[0, 5]}
        referenceEl={buttonEl}
      >
        <div
          ref={optionsEl}
          className="flex w-44 flex-col rounded border border-primary bg-white text-primary"
        >
          {options.map((option) => (
            <div
              key={option.text}
              onClick={() => {
                option.onClick();
                setShowOptions(false);
              }}
              className="cursor-pointer p-2 hover:bg-primary hover:text-white"
            >
              {option.text}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
}

export default DropdownButton;
