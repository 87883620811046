import { twJoin } from "tailwind-merge";
import { ReactElement } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  to: string;
  icon?: ReactElement;
  toggleMenu: () => void;
  children: React.ReactNode;
};

function MenuItem({ to, icon, toggleMenu, children }: Props): JSX.Element {
  return (
    <li className="cursor-pointer">
      <NavLink to={to} onClick={toggleMenu}>
        {({ isActive }) => (
          <div
            className={twJoin(
              "flex w-full gap-x-4 rounded-md p-2 transition-all duration-200 hover:bg-gray-200/40",
              isActive && "bg-gray-200/70 font-semibold"
            )}
          >
            {icon}
            {children}
          </div>
        )}
      </NavLink>
    </li>
  );
}

export default MenuItem;
