import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { createColumnHelper } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";
import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import CountryFlagIcon from "../../components/CountryFlagIcon";
import HasAccess, { csrAdminRoles } from "../../components/HasAccess";
import TableHeader from "../../components/TableHeader";
import { AccountHolder, OnboardingStatus } from "../../services/accountService";
import { AccountHoldersTableMeta } from "./OnboardingSummary";
import HOPButton from "../../components/HOPButton";
import HOPBalanceButton from "../../components/HOPBalanceButton";
import HomeIcon from "../../pages/home/HomeIcon";
import CurrencyDollarIcon from "@heroicons/react/24/outline/CurrencyDollarIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/outline/ClipboardDocumentListIcon";
import { getTransactionsPageFilteredByAccountHolderUrl } from "../../utils/transaction";
import Popover from "../../components/Popover";
import { useRef, useState } from "react";
import { link } from "fs";

type OnboardingStatusColumnProps = {
  value: OnboardingStatus;
  accountHolder: AccountHolder;
  onInviteUserClick: (accountHolder: AccountHolder) => void;
};

function OnboardingStatusColumn({
  value,
  accountHolder,
  onInviteUserClick,
}: OnboardingStatusColumnProps) {
  const [statusColumnRef, setStatusColumnRef] =
    useState<HTMLSpanElement | null>(null);
  const [showVerificationErrors, setShowVerificationErrors] = useState(false);

  const inBalancePlatform = accountHolder.inBalancePlatform ?? false;
  const isMigrated = accountHolder.migrated ?? false;
  const verificationErrors =
    accountHolder.verificationErrors?.split("\r\n") ?? [];

  return (
    <div className="flex items-center gap-x-3">
      <>
        <span
          ref={setStatusColumnRef}
          onMouseEnter={() => setShowVerificationErrors(true)}
          onMouseLeave={() => setShowVerificationErrors(false)}
          className={twJoin(
            "rounded-md border px-2 text-sm font-medium",
            value === "Complete" &&
              "border-green-200 bg-green-100 text-green-800",
            value === "In Progress" &&
              "border-yellow-200 bg-yellow-50 text-yellow-700",
            value === "KYC Needed" &&
              "border-orange-500 bg-orange-400 text-white"
          )}
        >
          {value === "In Progress" || value === "KYC Needed" ? (
            <HasAccess
              allowedRoles={[...csrAdminRoles]}
              notAllowedComponent={value}
            >
              {inBalancePlatform || isMigrated ? (
                <HOPBalanceButton
                  accountHolder={accountHolder.adyenAccountHolderCode}
                >
                  {value}
                </HOPBalanceButton>
              ) : (
                <HOPButton accountHolder={accountHolder.adyenAccountHolderCode}>
                  {value}
                </HOPButton>
              )}
            </HasAccess>
          ) : (
            value
          )}
        </span>
        {value === "KYC Needed" && (
          <Popover
            open={showVerificationErrors && verificationErrors.length > 0}
            placement="bottom"
            referenceEl={statusColumnRef}
          >
            <div className="rounded-lg bg-black p-2 text-white shadow-lg">
              <ul>
                {verificationErrors.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            </div>
          </Popover>
        )}
      </>
      <span className="mb-1 h-5 w-5 min-w-[24px]">
        {value === "In Progress" && (
          <HasAccess allowedRoles={[...csrAdminRoles]}>
            <EnvelopeIcon
              onClick={() => onInviteUserClick(accountHolder)}
              className="cursor-pointer text-primary-700"
              data-testid="mail"
            />
          </HasAccess>
        )}
      </span>
    </div>
  );
}

const columnHelper = createColumnHelper<AccountHolder>();
export const getAccountHoldersTableColumns = () => [
  columnHelper.accessor("edition", {
    header: () => <TableHeader text="Edition" />,
    cell: (info) => {
      return <div className="font-medium">{info.getValue()}</div>;
    },
    size: 60,
    minSize: 50,
  }),
  columnHelper.accessor("jrId", {
    header: () => <TableHeader text="Id" />,
    cell: (info) => {
      return <div className="truncate font-medium">{info.getValue()}</div>;
    },
    size: 100,
    minSize: 50,
  }),
  columnHelper.accessor("companyName", {
    header: () => <TableHeader text="Business" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 160,
    minSize: 50,
  }),
  columnHelper.accessor("companyEmail", {
    header: () => <TableHeader text="Email" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 230,
    minSize: 50,
  }),
  columnHelper.accessor("contactFullName", {
    header: () => <TableHeader text="Contact" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 160,
    minSize: 50,
  }),
  columnHelper.accessor("country", {
    header: () => <TableHeader text="Country" />,
    cell: (info) => {
      return (
        <div className="flex items-center gap-x-3">
          <CountryFlagIcon country={info.getValue()} />
          <span>{info.getValue()}</span>
        </div>
      );
    },
    size: 80,
    minSize: 50,
  }),
  columnHelper.accessor("balancePlatformAccountHolderId", {
    header: () => <TableHeader text="Platform Info" />,
    cell: (info) => {
      const accountHolder = info.row.original;
      const inBalancePlatform = accountHolder.inBalancePlatform;
      const migrated = accountHolder.migrated;

      const { onMigrateClick } = info.table.options
        .meta as AccountHoldersTableMeta;

      function getContent() {
        if (inBalancePlatform) {
          return <span>Balance</span>;
        }

        if (accountHolder.onboardingStatus.toUpperCase() === "COMPLETE") {
          if (migrated) {
            return <span>Classic</span>;
          } else {
            return (
              <Button size="sm" onClick={() => onMigrateClick(accountHolder)}>
                Migrate
              </Button>
            );
          }
        } else {
          return <span>Classic</span>;
        }
      }

      return <div className="flex justify-center">{getContent()}</div>;
    },
    size: 110,
    minSize: 80,
  }),
  columnHelper.accessor("migrationDate", {
    header: () => <TableHeader text="Migration" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 90,
    minSize: 50,
  }),
  columnHelper.accessor("onboardingStatus", {
    header: () => <TableHeader text="Onboarding" />,
    cell: (info) => {
      const accountHolder = info.row.original;
      const value = info.getValue() as OnboardingStatus;
      const { onInviteUserClick } = info.table.options
        .meta as AccountHoldersTableMeta;

      return (
        <OnboardingStatusColumn
          value={value}
          accountHolder={accountHolder}
          onInviteUserClick={onInviteUserClick}
        />
      );
    },
    size: 140,
    minSize: 50,
  }),
  columnHelper.accessor("id", {
    header: () => <TableHeader text="Operations" />,
    cell: (info) => {
      const accountHolder = info.row.original;

      return (
        <div className="flex items-center gap-x-3">
          <HomeIcon
            label=""
            to={getTransactionsPageFilteredByAccountHolderUrl(
              accountHolder.adyenAccountHolderCode
            )}
            icon={<CurrencyDollarIcon className="w-7 text-primary-700" />}
          />
          <HomeIcon
            accountHolderCode={accountHolder.adyenAccountHolderCode}
            label=""
            to="/reports"
            icon={
              <ClipboardDocumentListIcon className="w-7 text-primary-700" />
            }
          />
        </div>
      );
    },
    size: 75,
    minSize: 50,
  }),
  columnHelper.accessor("locations", {
    header: () => <TableHeader text="Settings" />,
    cell: (info) => {
      const accountHolder = info.row.original;
      const { onAddLocationClick } = info.table.options
        .meta as AccountHoldersTableMeta;
      const onboardingStatusValue = info.row.original.onboardingStatus;

      if (
        onboardingStatusValue === "In Progress" ||
        onboardingStatusValue === "KYC Needed"
      ) {
        return (
          <HasAccess allowedRoles={[...csrAdminRoles]}>
            <div>
              <NavLink
                to={`/customer-settings/${accountHolder.adyenAccountHolderCode}`}
                className="inline-block w-28 cursor-pointer  rounded-md border border-yellow-200 bg-yellow-50 px-2 text-center text-sm font-medium text-yellow-700"
              >
                Settings
              </NavLink>
            </div>
          </HasAccess>
        );
      }

      if (onboardingStatusValue === "Complete") {
        if (accountHolder.accounts.length === 0) {
          return (
            <div>
              <HasAccess
                allowedRoles={[...csrAdminRoles]}
                notAllowedComponent={
                  <span className="text-sm">Locations Unavailable</span>
                }
              >
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => onAddLocationClick(accountHolder)}
                  className="inline-block w-28 cursor-pointer text-center text-sm"
                >
                  Add location
                </Button>
              </HasAccess>
            </div>
          );
        }

        return (
          <div>
            <NavLink
              to={`/customer-settings/${accountHolder.adyenAccountHolderCode}`}
              className="inline-block w-28 cursor-pointer rounded-lg border border-primary-700 px-2 py-1 text-center text-sm text-primary-700 hover:shadow-md"
            >
              Settings
            </NavLink>
          </div>
        );
      }
    },
    size: 110,
    minSize: 50,
  }),
];
