import {
  getAccountHolderNotes,
  GetAccountHolderNotesResponse,
  UserNotesBody,
} from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useAccountHolderNotesQuery(
  body: UserNotesBody,
  queryOptions?: UseQueryOptions<GetAccountHolderNotesResponse, string>
) {
  return useQuery<GetAccountHolderNotesResponse, string>(
    ["accountHolderNotes", body],
    () => getAccountHolderNotes(body),
    {
      ...queryOptions,
    }
  );
}
