import { useForm } from "react-hook-form";
import Select from "../../components/Select";
import editions from "../../data/editions.json";
import countries from "../../data/countries.json";
import states from "../../data/states.json";
import legalEntityTypes from "../../data/legalEntityTypes.json";
import legalEntityOrgTypes from "../../data/legalEntityOrgTypes.json";
import Input from "../../components/Input";
import useTimezonesQuery from "../../hooks/data/queries/useTimezonesQuery";
import useMerchantAccountsQuery from "../../hooks/data/queries/useMerchantAccountsQuery";
import {
  caPostalCodeRegexp,
  emailRegexp,
  usAndCaPhoneRegexp,
  usPostalCodeRegexp,
} from "../../utils/regexp";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import { Status } from "../../types";

function getFormattedPhoneNumber(number: string) {
  if (!number) return number;

  const phoneNumber = number.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
    3,
    6
  )} ${phoneNumber.slice(6, 10)}`;
}

type Props = {
  companyId: string;
  formData: AddNewBalanceClientFormData | null;
  onSubmit: (data: AddNewBalanceClientFormData) => void;
  onCancel: () => void;
  onboardingStatus: Status;
};

export type AddNewBalanceClientFormData = {
  edition: string;
  jackrabbitId: string;
  country: string;
  timezone: string;
  balancePlatform: string;
  description: string;
  firstName: string;
  lastName: string;
  companyEmail: string;
  phone: string;
  legalEntityType: string;
  legalEntityLegalName: string;
  legalEntityRegistrationNumber: string;
  legalEntityOrganizationType: string;
  legalEntityCity: string;
  legalEntityState: string;
  legalEntityPostalCode: string;
  legalEntityStreet1: string;
  legalEntityStreet2: string;
};

function AddNewBalanceClientForm({
  companyId,
  formData,
  onSubmit,
  onCancel,
  onboardingStatus,
}: Props): JSX.Element {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddNewBalanceClientFormData>({
    mode: "onBlur",
  });
  const onFormSubmit = handleSubmit(onSubmit);

  const country = watch("country");
  const isCountrySelected = Boolean(country || formData?.country);

  const legalEntityType = Number(watch("legalEntityType"));

  const {
    data: timezones,
    isLoading: isLoadingTimezones,
    error: timezonesError,
  } = useTimezonesQuery(country, {
    enabled: isCountrySelected,
    onSuccess() {
      if (formData?.timezone) {
        setValue("timezone", formData.timezone);
      }
    },
  });

  const {
    data: merchantAccounts,
    isLoading: isLoadingMerchantAccounts,
    error: merchantAccountsError,
  } = useMerchantAccountsQuery(companyId);

  return (
    <form onSubmit={onFormSubmit}>
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-x-6 sm:flex-row">
          {/* Account Holder Info */}
          <div className="flex flex-col">
            <h3 className="mt-3 mb-5 text-xl font-medium">
              Account Holder Info
            </h3>
            <div className="mt-4 grid grid-cols-2 grid-rows-3 gap-x-4 gap-y-10">
              {/* EDITION */}
              <Select
                id="edition"
                label="Edition"
                options={editions}
                errorMessage={errors?.edition?.message}
                required
                {...register("edition", {
                  value: formData?.edition,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
              {/* ID */}
              <Input
                id="jackrabbitId"
                label="Jackrabbit Id"
                errorMessage={errors?.jackrabbitId?.message}
                type="text"
                minLength={1}
                maxLength={42}
                {...register("jackrabbitId", {
                  onBlur: (e) => {
                    setValue("jackrabbitId", e.target.value.trim());
                  },
                  value: formData?.jackrabbitId,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
              {/* COUNTRY */}
              <Select
                id="country"
                label="Country"
                options={countries}
                errorMessage={errors?.country?.message}
                required
                {...register("country", {
                  value: formData?.country,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />

              {/* TIMEZONE */}
              <Select
                id="timezone"
                label="Timezone"
                loading={isCountrySelected && isLoadingTimezones}
                options={
                  timezones?.map((x) => ({
                    value: x.code,
                    label: x.name,
                  })) ?? []
                }
                errorMessage={timezonesError || errors?.timezone?.message}
                {...register("timezone", {
                  disabled: !isCountrySelected || isLoadingTimezones,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
              {/* BALANCE PLATFORM */}
              <Select
                id="balancePlatform"
                label="Balance Platform"
                options={
                  merchantAccounts
                    ?.filter((x) => x.balancePlatformId !== null)
                    .map((x) => ({
                      value: x.balancePlatformId,
                      label: x.balancePlatformId,
                    })) || []
                }
                required
                disabled={isLoadingMerchantAccounts}
                loading={isLoadingMerchantAccounts}
                containerProps={{
                  className: "col-span-2",
                }}
                errorMessage={
                  merchantAccountsError || errors?.balancePlatform?.message
                }
                {...register("balancePlatform", {
                  value: formData?.balancePlatform,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            <h3 className="mt-6 mb-7 text-xl font-medium">Contact</h3>
            <div className="grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-10">
              {/* FIRST NAME */}
              <Input
                id="firstName"
                label="First Name"
                errorMessage={errors?.firstName?.message}
                type="text"
                {...register("firstName", {
                  value: formData?.firstName,
                  required: { value: true, message: "This field is required" },
                })}
              />
              {/* LAST NAME */}
              <Input
                id="lastName"
                label="Last Name"
                errorMessage={errors?.lastName?.message}
                type="text"
                {...register("lastName", {
                  value: formData?.lastName,
                  required: { value: true, message: "This field is required" },
                })}
              />
              {/* EMAIL */}
              <Input
                id="companyEmail"
                label="Email"
                errorMessage={errors?.companyEmail?.message}
                type="text"
                {...register("companyEmail", {
                  value: formData?.companyEmail,
                  required: {
                    value: true,
                    message: "This field is required.",
                  },
                  pattern: {
                    value: emailRegexp,
                    message: "Please enter a valid email.",
                  },
                })}
              />
              {/* PHONE */}
              <Input
                id="phone"
                label="Phone"
                errorMessage={errors?.phone?.message}
                type="text"
                adornmentText="+1"
                {...register("phone", {
                  onChange: (e) => {
                    e.target.value = getFormattedPhoneNumber(e.target.value);
                  },
                  value: formData?.phone,
                  required: {
                    value: true,
                    message: "This field is required.",
                  },
                  pattern: {
                    value: usAndCaPhoneRegexp,
                    message: "Please enter a valid phone.",
                  },
                })}
              />
            </div>
          </div>

          {/* Legal Entity Info */}
          <div className="flex flex-col">
            <h3 className="mt-6 mb-5 text-xl font-medium sm:mt-3">
              Legal Entity Info
            </h3>
            <div className="mt-4 grid grid-cols-3 grid-rows-3 gap-x-4 gap-y-10 sm:grid-rows-3">
              {/* LEGAL ENTITY TYPE */}
              <Select
                id="legalEntityType"
                label="Legal Entity Type"
                options={legalEntityTypes}
                errorMessage={errors?.edition?.message}
                containerProps={{
                  className: "col-span-1",
                }}
                required
                {...register("legalEntityType", {
                  value: formData?.legalEntityType,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />

              <div className="col-span-2"></div>

              {/* LEGAL NAME */}
              {legalEntityType > 1 && (
                <div className="col-span-2">
                  <Input
                    id="legalEntityLegalName"
                    label={
                      legalEntityType === 3 ? "Business Name" : "Legal Name"
                    }
                    errorMessage={errors?.legalEntityLegalName?.message}
                    type="text"
                    {...register("legalEntityLegalName", {
                      value: formData?.legalEntityLegalName,
                      required: {
                        value: legalEntityType !== 1,
                        message: "This field is required.",
                      },
                    })}
                  />
                </div>
              )}
              {legalEntityType === 2 && (
                <Select
                  id="legalEntityOrganizationType"
                  label="Organization Type"
                  options={legalEntityOrgTypes}
                  errorMessage={errors?.legalEntityOrganizationType?.message}
                  required
                  containerProps={{
                    className: "col-span-2",
                  }}
                  {...register("legalEntityOrganizationType", {
                    value: formData?.legalEntityOrganizationType,
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              )}

              <div />
            </div>
            <h4 className="mt-6 mb-7 text-xl">Address</h4>
            <div className="grid grid-cols-3 grid-rows-2 gap-x-4 gap-y-10">
              {/* POSTAL CODE */}

              {/* ADDRESS 1 */}
              <Input
                id="legalEntityStreet1"
                label="Address 1"
                errorMessage={errors?.legalEntityStreet1?.message}
                type="text"
                {...register("legalEntityStreet1", {
                  value: formData?.legalEntityStreet1,
                  required: { value: true, message: "This field is required." },
                })}
              />
              {/* ADDRESS 2 */}
              <Input
                id="legalEntityStreet2"
                label="Address 2"
                errorMessage={errors?.legalEntityStreet2?.message}
                type="text"
                {...register("legalEntityStreet2", {
                  value: formData?.legalEntityStreet2,
                })}
              />

              {/* CITY */}
              <Input
                id="legalEntityCity"
                label="City"
                errorMessage={errors?.legalEntityCity?.message}
                type="text"
                {...register("legalEntityCity", {
                  value: formData?.legalEntityCity,
                  required: { value: true, message: "This field is required." },
                })}
              />
              {/* STATE/PROVINCE */}
              <Select
                id="legalEntityState"
                label="State/Province"
                options={
                  isCountrySelected
                    ? states.filter(
                        (x) => x.country === (formData?.country || country)
                      )
                    : []
                }
                errorMessage={errors?.legalEntityState?.message}
                containerProps={{
                  className: "col-span-2",
                }}
                required
                disabled={!isCountrySelected}
                {...register("legalEntityState", {
                  value: formData?.legalEntityState,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />

              <Input
                id="legalEntityPostalCode"
                label="Postal Code"
                errorMessage={errors?.legalEntityPostalCode?.message}
                type="text"
                {...register("legalEntityPostalCode", {
                  value: formData?.legalEntityPostalCode,
                  required: { value: true, message: "This field is required." },
                  pattern: {
                    value:
                      country === "US"
                        ? usPostalCodeRegexp
                        : caPostalCodeRegexp,
                    message:
                      country === "US"
                        ? "Invalid postal code for United States."
                        : "Invalid postal code for Canada. Allowed formats are: LNL NLN or LNLNLN.",
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            variant="secondary"
            onClick={onCancel}
            type="button"
            className="w-32"
          >
            Cancel
          </Button>
          <Button
            className="flex w-32"
            type="submit"
            disabled={!isValid || onboardingStatus === "loading"}
            icon={
              onboardingStatus === "loading" && (
                <Spinner className="mt-0.5 mr-1 text-white" />
              )
            }
          >
            <span>Create</span>
          </Button>
        </div>
      </div>
    </form>
  );
}

export default AddNewBalanceClientForm;
