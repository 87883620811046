import { Transition, Popover } from "@headlessui/react";
import { Fragment } from "react";

type Props = {
  children: React.ReactNode;
};

function FilterOverlay({ children }: Props): JSX.Element {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-150"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel
        focus
        className="absolute -left-4 z-10 mt-2 w-auto px-4"
        data-testid="popoverPanel"
      >
        <div className="rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5">
          {children}
        </div>
      </Popover.Panel>
    </Transition>
  );
}

export default FilterOverlay;
