import { useState } from "react";
import useCreateAccountHolderMutation from "../../hooks/data/mutations/useCreateAccountHolderMutation";
import { AccountHolder } from "../../services/accountService";
import AddNewClassicClientForm, {
  AddNewClassicClientFormData,
} from "./AddNewClassicClientForm";
import OnboardingError from "./OnboardingError";
import InviteUser from "./InviteUser";
import { OnboardingType } from "./OnboardingSummary";
import AddNewBalanceClientForm, {
  AddNewBalanceClientFormData,
} from "./AddNewBalanceClientForm";
import useCreateAccountHolderBalanceMutation from "../../hooks/data/mutations/useCreateAccountHolderBalance";

type Props = {
  companyId: string;
  currentAccountHolder: AccountHolder | null;
  onboardingType: OnboardingType | null;
  onClientAdded: (
    addedClientFormData:
      | AddNewClassicClientFormData
      | AddNewBalanceClientFormData
  ) => void;
  onUserInvited: (userEmail: string) => void;
  onCancel: () => void;
};

function AddNewClientStep({
  companyId,
  currentAccountHolder,
  onboardingType,
  onClientAdded,
  onUserInvited,
  onCancel,
}: Props): JSX.Element {
  const [createAccountHolderErrors, setCreateAccountHolderErrors] =
    useState<string>("");
  const [formData, setFormData] = useState<any | null>(null);
  const {
    mutate: mutateClassic,
    reset: resetClassic,
    status: statusClassic,
    isSuccess: isSuccessClassic,
    isLoading: isLoadingClassic,
    isError: isErrorClassic,
    isIdle: isIdleClassic,
  } = useCreateAccountHolderMutation();

  const {
    mutate: mutateBalance,
    reset: resetBalance,
    status: statusBalance,
    isSuccess: isSuccessBalance,
    isLoading: isLoadingBalance,
    isError: isErrorBalance,
    isIdle: isIdleBalance,
  } = useCreateAccountHolderBalanceMutation();

  async function handleClassicClientFormSubmit(
    formData: AddNewClassicClientFormData
  ) {
    setFormData(formData);
    mutateClassic(
      {
        accountHolderCode: `${formData.edition}-${formData.jackrabbitId}`,
        accountHolderDetails: {
          address: {
            country: formData.country,
            timeZoneCode: formData.timezone,
          },
          businessDetails: {
            legalBusinessName: formData.companyName,
            doingBusinessAs: formData.companyName,
            shareHolders: [
              {
                name: {
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                },
                address: {
                  country: formData.country,
                  timeZoneCode: formData.timezone,
                },
                email: formData.companyEmail,
              },
            ],
          },
          email: formData.companyEmail,
        },
        legalEntity: "Business",
        merchantAccountId: formData.merchantAccountId,
        merchantAccount: formData.merchantAccountName,
      },
      {
        onSuccess: () => {
          onClientAdded(formData);
        },
        onError: (error) => {
          setCreateAccountHolderErrors(error);
        },
      }
    );
  }

  async function handleBalanceClientFormSubmit(
    formData: AddNewBalanceClientFormData
  ) {
    setFormData(formData);
    const legalEntityType = Number(formData.legalEntityType);
    const legalEntityName = formData.legalEntityLegalName ?? "";

    mutateBalance(
      {
        edition: formData.edition,
        jackrabbitId: formData.jackrabbitId,
        country: formData.country,
        timeZone: formData.timezone,
        balancePlatform: formData.balancePlatform,
        description: formData.description ?? "",
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyEmail: formData.companyEmail,
        phone: "+1" + formData.phone,
        legalEntity: {
          type: legalEntityType,
          organization:
            legalEntityType === 2
              ? {
                  legalName: legalEntityName,
                  registrationNumber:
                    formData.legalEntityRegistrationNumber ?? "",
                  type: formData.legalEntityOrganizationType,
                  registeredAddress: {
                    city: formData.legalEntityCity,
                    country: formData.country,
                    postalCode: formData.legalEntityPostalCode,
                    stateOrProvince: formData.legalEntityState,
                    street: formData.legalEntityStreet1,
                    street2: formData.legalEntityStreet2,
                  },
                }
              : null,
          individual:
            legalEntityType === 1 || legalEntityType === 3
              ? {
                  name: {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                  },
                  email: formData.companyEmail,
                  residentialAddress: {
                    city: formData.legalEntityCity,
                    country: formData.country,
                    postalCode: formData.legalEntityPostalCode,
                    stateOrProvince: formData.legalEntityState,
                    street: formData.legalEntityStreet1,
                    street2: formData.legalEntityStreet2,
                  },
                }
              : null,
          soleProprietorship:
            legalEntityType === 3
              ? {
                  name: legalEntityName,
                  countryOfGoverningLaw: formData.country,
                  registeredAddress: {
                    city: formData.legalEntityCity,
                    country: formData.country,
                    postalCode: formData.legalEntityPostalCode,
                    stateOrProvince: formData.legalEntityState,
                    street: formData.legalEntityStreet1,
                    street2: formData.legalEntityStreet2,
                  },
                }
              : null,
        },
        store: {
          description: (legalEntityName + " Store").trim(),
          shopperStatement: (legalEntityName.slice(0, 16) + " Store").trim(),
          phoneNumber: "+1" + formData.phone,
          reference: formData.jackrabbitId,
          address: {
            city: formData.legalEntityCity,
            country: formData.country,
            postalCode: formData.legalEntityPostalCode,
            stateOrProvince: formData.legalEntityState,
            line1: formData.legalEntityStreet1,
          },
        },
      },
      {
        onSuccess: () => {
          onClientAdded(formData);
        },
        onError: (error) => {
          setCreateAccountHolderErrors(error);
        },
      }
    );
  }

  function getContent(): React.ReactNode {
    if (onboardingType === "classic") {
      if (isSuccessClassic || currentAccountHolder) {
        return (
          <InviteUser
            showSuccessMessage={isSuccessClassic}
            onInviteClick={onUserInvited}
          />
        );
      }

      if (isIdleClassic || isLoadingClassic) {
        return (
          <AddNewClassicClientForm
            companyId={companyId}
            formData={formData}
            onboardingStatus={statusClassic}
            onSubmit={handleClassicClientFormSubmit}
            onCancel={onCancel}
          />
        );
      }

      if (isErrorClassic) {
        return (
          <OnboardingError
            error={createAccountHolderErrors}
            onClose={resetClassic}
          />
        );
      }
    } else if (onboardingType === "balance") {
      if (isSuccessBalance || currentAccountHolder) {
        return (
          <InviteUser
            showSuccessMessage={isSuccessBalance}
            onInviteClick={onUserInvited}
          />
        );
      }

      if (isIdleBalance || isLoadingBalance) {
        return (
          <AddNewBalanceClientForm
            companyId={companyId}
            formData={formData}
            onboardingStatus={statusBalance}
            onSubmit={handleBalanceClientFormSubmit}
            onCancel={onCancel}
          />
        );
      }

      if (isErrorBalance) {
        return (
          <OnboardingError
            error={createAccountHolderErrors}
            onClose={resetBalance}
          />
        );
      }
    }
  }

  return <div>{getContent()}</div>;
}

export default AddNewClientStep;
