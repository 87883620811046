import { createColumnHelper } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";
import TableHeader from "../../components/TableHeader";
import { AccountHolderUser } from "../../services/accountService";

const columnHelper = createColumnHelper<AccountHolderUser>();
export const getUserDetailsTableColumns = () => [
  columnHelper.accessor("firstName", {
    header: () => <TableHeader text="User Name" />,
    cell: (info) => {
      const firstName = info.getValue();
      const lastName = info.row.original.lastName;
      return <div className="truncate">{`${firstName} ${lastName}`}</div>;
    },
    minSize: 200,
    size: 200,
  }),
  columnHelper.accessor("email", {
    header: () => <TableHeader text="Email" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 200,
    size: 300,
  }),
  columnHelper.accessor("role", {
    header: () => <TableHeader text="Role" />,
    cell: (info) => {
      return <div className="truncate font-medium">{info.getValue()}</div>;
    },
    minSize: 200,
    size: 200,
  }),
  columnHelper.accessor("status", {
    header: () => <TableHeader text="Status" />,
    cell: (info) => {
      const active = info.getValue();

      return (
        <div className="flex items-center gap-3">
          {active === null ? (
            <span className="my-0.5 h-5" />
          ) : (
            <span
              className={twJoin(
                "flex w-16 justify-center rounded-md border px-2 text-sm font-medium",
                active
                  ? "border-green-200 bg-green-100 text-green-800"
                  : "border-red-200 bg-red-100 text-red-700"
              )}
            >
              {active ? "Active" : "Inactive"}
            </span>
          )}
        </div>
      );
    },
    minSize: 100,
    size: 100,
  }),
];
