import {
  UseQueryOptions,
  useQueries,
  useQueryClient,
} from "@tanstack/react-query";
import {
  GetTransactionLedgerBody,
  GetTransactionLedgerResponse,
  getTransactionLedger,
} from "../../../services/transactionsService";

export default function useTransactionLedgerQuery(
  body: GetTransactionLedgerBody,
  transactionsQueryOptions?: UseQueryOptions<
    GetTransactionLedgerResponse,
    string
  >,
  allTransactionsQueryOptions?: UseQueryOptions<
    GetTransactionLedgerResponse,
    string
  >
) {
  const queryClient = useQueryClient();

  const allTransactionsBody: GetTransactionLedgerBody = {
    ...body,
    pageNumber: 1,
    pageSize: 100_000,
  };

  return useQueries<UseQueryOptions<GetTransactionLedgerResponse, string>[]>({
    queries: [
      {
        queryKey: ["transactionLedger", body],
        queryFn: () => getTransactionLedger(body),
        onSuccess(data) {
          data.pageElements.forEach((t) => {
            const currentTransactionData = queryClient.getQueryData([
              "transaction",
              t.pspReference,
            ]);

            if (!currentTransactionData) {
              queryClient.setQueryData(["transaction", t.pspReference], t);
            }
          });
        },
        ...transactionsQueryOptions,
      },
      {
        queryKey: ["transactionLedgerAll", allTransactionsBody],
        queryFn: () => getTransactionLedger(allTransactionsBody),
        ...allTransactionsQueryOptions,
      },
    ],
  });
}
