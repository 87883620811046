import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import useCreateTransferFundsMutation from "../../hooks/data/mutations/useCreateTransferFundsMutation";
import { useSnackbar } from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import { convertToAdyenNumber } from "../../utils/number";
import Textarea from "../../components/Textarea";
import { accountCreditDescriptionRegexp } from "../../utils/regexp";
import useMerchantAccountsSysConfigQuery from "../../hooks/data/queries/useMerchantAccountsSysConfigQuery";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  accountHolderCode: string;
  merchantAccountId: string;
  primaryAccountAccountId: string;
};

function AccountCreditModal({
  isOpen,
  onClose,
  accountHolderCode,
  merchantAccountId,
  primaryAccountAccountId,
}: Props): JSX.Element {
  const [amountValue, setAmountValue] = useState("");
  const [description, setDescription] = useState("");
  const amountRef = useRef<HTMLInputElement | null>(null);
  const { mutate, isLoading } = useCreateTransferFundsMutation();

  const companyId = "JackRabbitTech"; // TODO: Harcoded for now
  const { data: merchantAccounts } =
    useMerchantAccountsSysConfigQuery(companyId);
  const merchantAccount = merchantAccounts?.find(
    (x) => x.merchantAccountId === merchantAccountId
  );

  const descriptionIsValid = description.match(accountCreditDescriptionRegexp);

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => amountRef.current?.focus(), 0);
    }
  }, [isOpen]);

  function applyCredit() {
    mutate(
      {
        amount: {
          value: amountValue,
          currency: merchantAccount?.merchantCurrency ?? "USD",
        },
        notes: description,
        counterparty: primaryAccountAccountId,
        merchantAccountId: merchantAccountId,
        accountHolderCode,
      },
      {
        onSuccess() {
          openSnackbar("Account credit was successfully created.", {
            type: "success",
          });
          setAmountValue("");
          setDescription("");
          onClose();
        },
        onError() {
          openSnackbar(
            "Failed to update create account credit. Please try again.",
            {
              type: "error",
            }
          );
          amountRef.current?.focus();
        },
      }
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Account Credit"
      panelClasses="!w-80"
      titleClasses="text-left"
      onClose={() => {
        setAmountValue("");
        setDescription("");
        onClose();
      }}
    >
      <div className="mt-10 flex flex-col">
        <Input
          type="number"
          ref={amountRef}
          value={amountValue}
          onChange={(e) => setAmountValue(e.target.value)}
          label="Amount"
          adornmentText="USD"
          className="mb-6"
        />
        <Textarea
          label="Description"
          rows={2}
          maxLength={80}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          errorMessage={
            description && !descriptionIsValid
              ? `There are some invalid characters. Valid characters are: [a-z] [A-Z] [0-9] / - ?
: ( ) . , ' + Space`
              : ""
          }
          errorMessageContainerProps={{
            className: "static",
          }}
        />
        <Button
          onClick={applyCredit}
          disabled={isLoading || amountValue === "" || description === ""}
          icon={isLoading && <Spinner className="text-gray-400" />}
        >
          Enter
        </Button>
      </div>
    </Modal>
  );
}

export default AccountCreditModal;
