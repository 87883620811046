import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
  DepositDetailReportBody,
  GetDepositDetailReportResponse,
  getDepositDetailReport,
} from "../../../services/transactionsService";

export default function useDepositDetailReportQuery(
  body: DepositDetailReportBody,
  queryOptions?: UseQueryOptions<GetDepositDetailReportResponse, string>
) {
  return useQuery<GetDepositDetailReportResponse, string>(
    ["depositDetailReport", body],
    () => getDepositDetailReport(body),
    queryOptions
  );
}
