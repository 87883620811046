import { createColumnHelper } from "@tanstack/react-table";
import { ReactNode } from "react";
import Table from "../../../components/Table";
import { TransactionLifecycle as TransLifecycle } from "../../../services/transactionsService";
import Skeleton from "../../../components/Skeleton";
import MainAmountCell from "./MainAmountCell";
import TableHeader from "../../../components/TableHeader";
import { DateTime } from "luxon";
import { longDateFormat } from "../../../utils/dateTime";
import { sortDateTimes, sortNumbers } from "../../../utils/sorting";
import Panel from "../../customerSettings/Panel";

const columnHelper = createColumnHelper<TransLifecycle>();
const columns = [
  columnHelper.accessor("journalType", {
    header: () => <TableHeader text="Journal Type" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 200,
    minSize: 100,
  }),
  columnHelper.accessor("journalCreatedDate", {
    header: () => <TableHeader text="Date" />,
    cell: (info) => {
      const utcTime = info.row.original.utcTime;
      return <div className="truncate">{utcTime || info.getValue()}</div>;
    },
    size: 200,
    minSize: 100,
    sortingFn: (rowA, rowB, columnId) => {
      const rowADate = DateTime.fromFormat(
        rowA.getValue(columnId),
        longDateFormat
      );
      const rowBDate = DateTime.fromFormat(
        rowB.getValue(columnId),
        longDateFormat
      );

      return sortDateTimes(rowADate, rowBDate);
    },
  }),
  columnHelper.accessor("auditUser", {
    header: () => <TableHeader text="Audit User" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 300,
    minSize: 150,
  }),
  columnHelper.accessor("mainAmount", {
    header: () => <TableHeader text="Main Amount" />,
    cell: (info) => {
      return <MainAmountCell lifecycle={info.row.original} />;
    },
    size: 120,
    minSize: 100,
    sortingFn: (rowA, rowB, columnId) => {
      const rowAAmount = rowA.original.mainAmountRaw;
      const rowBAmount = rowB.original.mainAmountRaw;
      return sortNumbers(rowAAmount, rowBAmount);
    },
  }),
];

type Props = {
  transactionLifecycles: TransLifecycle[];
  timeZoneCode: string;
  isLoading?: boolean;
};

function TransactionLifecycle({
  transactionLifecycles,
  timeZoneCode,
  isLoading = false,
}: Props): JSX.Element {
  function getContent(): ReactNode {
    if (isLoading) {
      return (
        <div className="flex w-full gap-x-20">
          <Skeleton className="w-32" />
          <Skeleton className="w-32" />
          <Skeleton className="w-32" />
          <Skeleton className="w-96" />
        </div>
      );
    }

    if (transactionLifecycles.length > 0) {
      return (
        <div className="flex flex-col gap-y-2">
          <div>All lifecycle events are in {timeZoneCode} Time zone.</div>
          <Table
            id="lifecycles"
            columns={columns}
            data={transactionLifecycles}
            isLoading={isLoading}
            sorting={[
              {
                id: "journalCreatedDate",
                desc: true,
              },
            ]}
            showPaginator={false}
            className="max-h-60 overflow-y-auto"
          />
        </div>
      );
    }
    return <div className="px-3 pb-3">Pending EOD report.</div>;
  }

  return (
    <Panel className="my-2 w-full rounded-lg border border-gray-200 shadow-sm">
      <Panel.Header title="Payment Lifecycle" className="rounded-b-none" />
      <Panel.Content>
        <div className="pt-2 pb-1">{getContent()}</div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionLifecycle;
