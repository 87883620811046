import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { AccountHolder } from "../../services/accountService";
import { AddNewClassicClientFormData } from "./AddNewClassicClientForm";
import AddNewClientStep from "./AddNewClientStep";
import SignUpStep from "./SignUpStep";
import { OnboardingType } from "./OnboardingSummary";
import { AddNewBalanceClientFormData } from "./AddNewBalanceClientForm";

type Props = {
  onboardingType: OnboardingType | null;
  currentAccountHolder: AccountHolder | null;
  onClose: () => void;
};

type ModalStep = "AddNewClient" | "SignUp";

function OnboardingClientModal({
  onboardingType,
  currentAccountHolder,
  onClose,
}: Props): JSX.Element {
  const companyId = "JackRabbitTech"; // TODO: Harcoded for now
  const [invitedUserEmail, setInvitedUserEmail] = useState<string>("");
  const [addedClient, setAddedClient] = useState<
    AddNewClassicClientFormData | AddNewBalanceClientFormData | null
  >(null);
  const [modalStep, setModalStep] = useState<ModalStep>("AddNewClient");

  useEffect(() => {
    if (onboardingType) {
      setModalStep("AddNewClient");
    }
  }, [onboardingType]);

  function handleModalClose() {
    onClose();
  }

  function getModalTitle() {
    if (modalStep === "AddNewClient") {
      return "Create New Client";
    }

    if (modalStep === "SignUp") {
      return "Sign Up";
    }
    return "";
  }

  function getModalContent(): React.ReactNode {
    if (modalStep === "AddNewClient") {
      return (
        <AddNewClientStep
          companyId={companyId}
          currentAccountHolder={currentAccountHolder}
          onboardingType={onboardingType}
          onCancel={handleModalClose}
          onClientAdded={setAddedClient}
          onUserInvited={(userEmail) => {
            setInvitedUserEmail(userEmail);
            setModalStep("SignUp");
          }}
        />
      );
    }

    if (modalStep === "SignUp") {
      return (
        <SignUpStep
          accountHolderInfo={{
            edition: currentAccountHolder
              ? currentAccountHolder.edition
              : addedClient?.edition ?? "",
            jackrabbitId: currentAccountHolder
              ? currentAccountHolder.jrId
              : addedClient?.jackrabbitId.toString() ?? "",
            companyName: currentAccountHolder
              ? currentAccountHolder.companyName
              : onboardingType === "classic"
              ? (addedClient as AddNewClassicClientFormData)?.companyName
              : (addedClient as AddNewBalanceClientFormData)
                  ?.legalEntityLegalName,
            firstName: currentAccountHolder ? "" : addedClient?.firstName,
            lastName: currentAccountHolder ? "" : addedClient?.lastName,
          }}
          invitedUserEmail={invitedUserEmail}
          onSignUpCancel={handleModalClose}
        />
      );
    }
  }

  return (
    <Modal
      isOpen={onboardingType !== null}
      title={getModalTitle()}
      titleClasses="text-left mb-4"
      onClose={handleModalClose}
    >
      {getModalContent()}
    </Modal>
  );
}

export default OnboardingClientModal;
