import {
  getMerchantAccounts,
  GetMerchantAccountsResponse,
} from "../../../services/accountService";
import { useQuery } from "@tanstack/react-query";

export default function useMerchantAccountsQuery(companyId: string = "") {
  return useQuery<GetMerchantAccountsResponse, string>(
    ["merchantAccounts", companyId],
    () => getMerchantAccounts(companyId)
  );
}
