import { useMemo } from "react";
import Table from "../../components/Table";
import Error from "../../components/Error";
import useUserDetailsQuery from "../../hooks/data/queries/useUserDetailsQuery";
import {
  MerchantAccount,
  WebhookConfiguration,
} from "../../services/accountService";
import { AccountHolderUser } from "../../services/accountService";
import { getEmptyMerchantAccount } from "../../utils/accounts";
import { getEmptyUser } from "../../utils/users";
import { getMerchantAccountTableColumns } from "./merchantAccountsTableColumns";
import { getUserDetailsTableColumns } from "../customerSettings/userDetailsTableColumns";
import useMerchantAccountsSysConfigQuery from "../../hooks/data/queries/useMerchantAccountsSysConfigQuery";
import HasAccess, { csrRoles } from "../../components/HasAccess";
import useWebhookConfigQuery from "../../hooks/data/queries/useWebhookConfigQuery";
import { getWebhooksConfigurationsTableColumns } from "./webhooksConfigurationsTableColumns";

function SystemSettings(): JSX.Element {
  const companyId = "JackRabbitTech"; // TODO: Harcoded for now
  const {
    data: userDetailsData,
    isLoading: isLoadingUserDetails,
    error: usersError,
  } = useUserDetailsQuery();
  const {
    data: merchantAccounts,
    isLoading: isLoadingMerchantAccounts,
    error: merchantAccountsError,
  } = useMerchantAccountsSysConfigQuery(companyId);
  const {
    data: webhookConfigData,
    isLoading: isLoadingWebhookConfig,
    error: webhookConfigError,
  } = useWebhookConfigQuery();

  const memoUsers = useMemo(() => {
    if (isLoadingUserDetails && !userDetailsData) {
      return Array.from<AccountHolderUser>({ length: 5 }).fill(getEmptyUser());
    }
    return userDetailsData?.at(0)?.userInfo ?? [];
  }, [userDetailsData, isLoadingUserDetails]);

  const memoMerchantAccounts = useMemo(() => {
    if (isLoadingMerchantAccounts && !merchantAccounts) {
      return Array.from<MerchantAccount>({ length: 5 }).fill(
        getEmptyMerchantAccount()
      );
    }
    return merchantAccounts ?? [];
  }, [merchantAccounts, isLoadingMerchantAccounts]);

  const memoWebhookConfigurations = useMemo(() => {
    if (isLoadingWebhookConfig && !webhookConfigData) {
      return Array.from<WebhookConfiguration>({ length: 6 }).fill({
        edition: "",
        environment: "",
        url: "",
      });
    }
    return webhookConfigData ?? [];
  }, [webhookConfigData, isLoadingWebhookConfig]);

  return (
    <HasAccess
      allowedRoles={[...csrRoles]}
      notAllowedComponent={
        <Error message="You're not allowed to view this page." />
      }
    >
      <div className="flex flex-col px-6">
        <h1 className="mb-4 text-3xl">System Settings</h1>

        <h3 className="my-3 text-xl">Adyen Merchant Accounts</h3>
        {merchantAccountsError ? (
          <Error message={merchantAccountsError} />
        ) : (
          <Table
            id="merchantAccounts"
            data={memoMerchantAccounts}
            columns={getMerchantAccountTableColumns()}
            isLoading={isLoadingUserDetails}
            showPaginator={false}
            noRowsText="There are no merchant accounts to display."
          />
        )}

        <h3 className="my-3 text-xl">Webhook Configurations</h3>
        {webhookConfigError ? (
          <Error message={webhookConfigError} />
        ) : (
          <Table
            id="webhookConfigurations"
            data={memoWebhookConfigurations}
            columns={getWebhooksConfigurationsTableColumns()}
            isLoading={isLoadingWebhookConfig}
            showPaginator={false}
            noRowsText="There are no webhook configurations to display."
          />
        )}

        <h3 className="my-3 text-xl">CSR User Management</h3>
        {usersError ? (
          <Error message={usersError} />
        ) : (
          <Table
            id="userDetails"
            data={memoUsers}
            columns={getUserDetailsTableColumns()}
            isLoading={isLoadingMerchantAccounts}
            showPaginator={false}
            noRowsText="There are no users to display."
          />
        )}
      </div>
    </HasAccess>
  );
}

export default SystemSettings;
