import { MonthlyFeesDueItem } from "../services/transactionsService";
import { generateCsvData, downloadCsvFile } from "./csv";

export function downloadMonthlyFeesDueCSV(
  items: MonthlyFeesDueItem[],
  reportName: string
) {
  const csvData = generateCsvData<MonthlyFeesDueItem>(items, [
    { label: "Account Holder Code", value: "accountHolderCode" },
    { label: "Business Name", value: "businessName" },
    { label: "Amount", value: "amount" },
  ]);
  downloadCsvFile(csvData, reportName);
}
