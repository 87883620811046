import CountryFlagIcon from "../../components/CountryFlagIcon";
import DetailField from "../../components/DetailField";
import { TransactionDetail } from "../../services/transactionsService";
import { getPaymentVariantFullLabel } from "../../utils/transaction";
import Panel from "../customerSettings/Panel";

export type Props = {
  transactionDetail?: TransactionDetail | null;
  isLoading?: boolean;
};

function TransactionCardDetail({
  transactionDetail,
  isLoading = false,
}: Props): JSX.Element {
  return (
    <Panel className="my-3 w-full rounded-lg border border-gray-200 shadow-sm md:w-[49%]">
      <Panel.Header title="Card Details" className="rounded-b-none" />
      <Panel.Content>
        <div className="grid grid-cols-1 grid-rows-3 gap-x-8 gap-y-4 p-4 md:p-2 lg:grid-cols-2">
          <DetailField label="Card Number" isLoading={isLoading}>
            {transactionDetail?.cardNumber}
          </DetailField>
          <DetailField label="Expiry Date" isLoading={isLoading}>
            {transactionDetail?.expiryDate}
          </DetailField>
          <DetailField label="Card Type" isLoading={isLoading}>
            <span className="flex items-center gap-x-2">
              {transactionDetail?.cardType
                ? getPaymentVariantFullLabel(transactionDetail?.cardType)
                : ""}
            </span>
          </DetailField>
          <DetailField label="Funding Source" isLoading={isLoading}>
            {transactionDetail?.fundingSource}
          </DetailField>
          <DetailField label="Card Holder" isLoading={isLoading}>
            {transactionDetail?.cardHolderName}
          </DetailField>
          <DetailField label="CVC/CVV" isLoading={isLoading}>
            {transactionDetail?.cvc}
          </DetailField>
          <DetailField label="Issuer Country" isLoading={isLoading}>
            <span className="flex items-center gap-x-2">
              {transactionDetail?.issuerCountry ? (
                <CountryFlagIcon country={transactionDetail?.issuerCountry} />
              ) : (
                ""
              )}
              {transactionDetail?.issuerCountry}
            </span>
          </DetailField>
          <DetailField label="AVS" isLoading={isLoading}>
            {transactionDetail?.avs}
          </DetailField>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionCardDetail;
