import { useQuery } from "@tanstack/react-query";
import {
  getCurrentUserAccountWithExtraFields,
  UserAccount,
} from "../../../utils/userAccounts";

export default function useCurrentUserAccount() {
  return useQuery<UserAccount, string>(["currenUser"], () =>
    getCurrentUserAccountWithExtraFields()
  );
}
