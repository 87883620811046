import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  GetReportBody,
  getReportCSV,
  ReportCSVResponse,
  ReportType,
} from "../../../services/transactionsService";

export default function useReportCSVQuery(
  type: ReportType,
  body: GetReportBody,
  queryOptions?: UseQueryOptions<ReportCSVResponse, string>
) {
  return useQuery<ReportCSVResponse, string>(
    ["reportCSV", type, body],
    () => getReportCSV(type, body),
    queryOptions
  );
}
