import UsFlag from "../assets/us.svg";
import CaFlag from "../assets/ca.svg";
import GbFlag from "../assets/gb.svg";
import NLflag from "../assets/nl.svg";


type Props = {
  country: string;
};

function getSvg(country: string) {
  switch (country) {
    case "US":
      return UsFlag;
    case "CA":
      return CaFlag;
    case "UK":
    case "GB":
      return GbFlag;
    case "NL":
      return NLflag;
  }
  return "";
}

function getCountryName(country: string) {
  switch (country) {
    case "US":
      return "United States";
    case "CA":
      return "Canada";
    case "UK":
    case "GB":
      return "Great Britain";
    case "NL":
      return "Netherlands";
  }
  return "";
}

function CountryFlagIcon({ country }: Props): JSX.Element {
  const svg = getSvg(country);

  if (!svg) {
    return <span />;
  }

  return (
    <img src={svg} alt={getCountryName(country)} className="w-6 rounded" />
  );
}

export default CountryFlagIcon;
