import { getThemeIdByMerchantAccount } from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useThemeIdByMerchantAccountId(
  merchantAccountId: string,
  queryOptions?: UseQueryOptions<string, string>
) {
  return useQuery<string, string>(
    ["themeId", merchantAccountId],
    () => getThemeIdByMerchantAccount(merchantAccountId),
    queryOptions
  );
}
