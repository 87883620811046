import { SortingState } from "@tanstack/react-table";

export type UserRole =
  | "ADMIN"
  | "REPRESENTATIVE"
  | "STAKEHOLDER"
  | "OWNER"
  | "CONTROLLER"
  | "SIGNATORY"
  | "USER";

export type UserType = "CSR" | "Customer";

export type Status = "idle" | "loading" | "success" | "error";

export type ChangePageEvent = "first" | "prev" | "next" | "last";

export type PaginatorProps = {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  activeNextPage: boolean;
  activePrevPage: boolean;
  pageSizeOptions?: number[];
  onPageChange: (pageEvent: ChangePageEvent, totalPages: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

export enum FilterType {
  Equals = 1,
  NotEquals = 2,
  GreaterThan = 3,
  GreaterThanOrEqual = 4,
  LessThan = 5,
  LessThanOrEqual = 6,
  StartsWith = 7,
  EndsWith = 8,
  Contains = 9,
}

export enum OrderType {
  Asc = 1,
  Desc = 2,
}

export type DefaultPostBody = {
  filters: { name: string; value: any; type: FilterType }[];
  orders: { name: string; type: number }[];
  pageNumber: number;
  pageSize: number;
};

export type DefaultLedgerPostBody = {
  pageInfo: {
    pageNumber: number;
    pageSize: number;
    filters: { name: string; value: any; type: FilterType }[];
    orders: { name: string; type: number }[];
  };
  ianaTimezone: string;
};

export type DefaultListResponse<T> = {
  pageElements: T[];
  totalElements: number;
  totalPages: number;
  activePrevPage: boolean;
  activeNextPage: boolean;
};

export type PageInfo = {
  pageNumber: number;
  pageSize: number;
};

export type SortingType = {
  name: string;
  type: OrderType;
};

export type SortInfo = {
  sort: SortingState;
};
