import { DateTime } from "luxon";

type SortOrder = "asc" | "desc";

export function sortISODate(a: string, b: string, sortOrder: SortOrder) {
  const aDate = DateTime.fromISO(a);
  const bDate = DateTime.fromISO(b);

  if (aDate < bDate) return sortOrder === "desc" ? 1 : -1;
  if (aDate > bDate) return sortOrder === "desc" ? -1 : 1;
  return 0;
}

export function sortDateTimes(a: DateTime, b: DateTime) {
  if (a.toMillis() === b.toMillis()) return 0;
  return a > b ? 1 : -1;
}

export function sortNumbers(a: number, b: number) {
  if (a === b) return 0;
  return a > b ? 1 : -1;
}

export function sortStrings(a: string, b: string) {
  return a.localeCompare(b, "en");
}
