import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import useMigrateAccountHolderMutation from "../../hooks/data/mutations/useMigrateAccountHolderMutation";
import { useEffect } from "react";

type Props = {
  isOpen: boolean;
  accountHolderCode: string;
  onClose: () => void;
  onAccountHolderMigrated: () => void;
};

function MigrateConfirmationModal({
  isOpen,
  accountHolderCode,
  onClose,
  onAccountHolderMigrated,
}: Props): JSX.Element {
  const { mutate, isLoading, error, reset } = useMigrateAccountHolderMutation();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  function handleMigrateClick() {
    mutate(accountHolderCode, {
      onSuccess() {
        onAccountHolderMigrated();
      },
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      title={"Migrate Account holder"}
      onClose={onClose}
      titleClasses="text-left mb-6"
      panelClasses="min-w-[400px]"
    >
      {error ? (
        <Error
          message={`There was an error while trying to migrate ${accountHolderCode}. Please try again.`}
        />
      ) : (
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col items-center break-words">
            <div>
              Account holder {accountHolderCode} will be migrated to the Balance
              Platform.
            </div>
            <div>Do you want to continue?</div>
          </div>
          <div className="flex justify-center gap-x-4">
            <Button variant="secondary" size="sm" onClick={onClose}>
              No
            </Button>
            <Button
              size="sm"
              onClick={handleMigrateClick}
              disabled={isLoading}
              icon={isLoading && <Spinner className="text-white" />}
            >
              Yes
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default MigrateConfirmationModal;
