import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  updateAccount,
  UpdateAccountBody,
} from "../../../services/accountService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";
import { BankAccountItem } from "../queries/useNormalizedBankAccountsQuery";

export default function useUpdateAccountMutation(accountHolderCode: string) {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, Error, UpdateAccountBody>(
    (body) => updateAccount(body),
    {
      async onSuccess(_, updatedAccount) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          accountHolderCode,
        ]);

        if (!accountConfigData) return;

        const updatedAccountIndex = accountConfigData.accounts.findIndex(
          (a) => a.id === updatedAccount.locationId
        );

        const updatedAccountPreviousValue =
          accountConfigData?.accounts[updatedAccountIndex];

        const primaryAccountFieldUpdated =
          updatedAccount.primaryAccount !==
          updatedAccountPreviousValue.primaryAccount;

        const bankUUIDFieldUpdated =
          updatedAccount.bankUUID !== updatedAccountPreviousValue.bankUUID;

        const updatedAccounts = accountConfigData?.accounts.map((account, i) =>
          i === updatedAccountIndex
            ? { ...account, ...updatedAccount }
            : primaryAccountFieldUpdated
            ? { ...account, primaryAccount: false }
            : account
        );

        queryClient.setQueryData(["accountConfig", accountHolderCode], {
          ...accountConfigData,
          accounts: updatedAccounts,
        });

        if (bankUUIDFieldUpdated) {
          const bankAccountsData = queryClient.getQueryData<BankAccountItem[]>([
            "bankAccounts",
            accountHolderCode,
          ]);
          const oldBankAccount = bankAccountsData?.find(
            (d) => d.id === updatedAccountPreviousValue.bankUUID
          );
          const updatedBankAccount = bankAccountsData?.find(
            (d) => d.id === updatedAccount.bankUUID
          );

          await createNote({
            accountHolderCode,
            noteContent: [
              {
                fieldName: `Bank UUID (for location ${updatedAccountPreviousValue.locationCode})`,
                fromValue: oldBankAccount ? oldBankAccount.label : "",
                toValue: updatedBankAccount ? updatedBankAccount.label : "",
              },
            ],
          });
        }

        if (primaryAccountFieldUpdated) {
          await createNote({
            accountHolderCode,
            noteContent: [
              {
                fieldName: `Primary Account (for location ${updatedAccountPreviousValue.locationCode})`,
                fromValue: updatedAccountPreviousValue.primaryAccount
                  ? "Yes"
                  : "No",
                toValue: updatedAccount.primaryAccount ? "Yes" : "No",
              },
            ],
          });
        }

        queryClient.invalidateQueries(["accountConfig", accountHolderCode]);
      },
    }
  );
}
