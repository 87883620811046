import { twMerge } from "tailwind-merge";
import React from "react";
import Spinner from "./Spinner";

type Option = {
  value: string | number;
  label: string;
  selected?: boolean;
};

type Props = {
  id: string;
  label?: string;
  options: Option[];
  errorMessage?: string;
  loading?: boolean;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  containerProps?: React.HTMLAttributes<HTMLElement>;
};

const Select = React.forwardRef<
  HTMLSelectElement,
  Props & React.SelectHTMLAttributes<HTMLSelectElement>
>(
  (
    {
      id,
      label = "",
      options,
      errorMessage = "",
      loading = false,
      labelProps = {},
      containerProps = {},
      ...selectProps
    },
    ref
  ): JSX.Element => {
    const { className: containerClassName = "", ...otherContainerProps } =
      containerProps;
    return (
      <div
        {...otherContainerProps}
        className={"relative flex flex-col " + containerClassName}
      >
        <select
          ref={ref}
          id={id}
          {...selectProps}
          onInvalid={(e) => e.preventDefault()}
          className={twMerge(
            "peer w-full rounded-md border-gray-300 px-2 py-1 pr-7 shadow-sm placeholder:invisible focus:border-primary-700/50 focus:ring-1 focus:ring-primary-700",
            errorMessage && "border-1 border-error-300",
            selectProps.disabled &&
              "cursor-not-allowed bg-gray-50 text-gray-500",
            selectProps.className
          )}
        >
          <option value="" hidden></option>
          {options.map((option, index) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {label && (
          <label
            htmlFor={id}
            {...labelProps}
            className="absolute top-1.5 left-2 truncate text-sm text-gray-500 transition-all
                peer-valid:-top-6 peer-valid:font-medium peer-valid:text-gray-800
                peer-focus:-top-6 peer-focus:font-medium peer-focus:text-gray-800"
          >
            {label}
          </label>
        )}
        <span className="absolute top-9 left-2 mb-3 inline-block text-sm font-medium text-error-300">
          {errorMessage}
        </span>
        {loading && (
          <div className="absolute right-3 top-2 h-4 w-4">
            <Spinner className="bg-white text-primary-700" />
          </div>
        )}
      </div>
    );
  }
);

export default Select;
