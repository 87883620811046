import { ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import { Transition } from "@headlessui/react";

type Props = {
  open: boolean;
  placement: Placement;
  offset?: [number, number];
  referenceEl: HTMLElement | null;
  children: ReactNode;
};

function Popover({
  open,
  placement,
  offset = [0, 10],
  referenceEl,
  children,
}: Props): JSX.Element {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceEl, popperElement, {
    placement,
    modifiers: [{ name: "offset", options: { offset } }],
  });

  return (
    <>
      {createPortal(
        <Transition
          show={open}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </div>
        </Transition>,
        document.getElementById("popper")!
      )}
    </>
  );
}

export default Popover;
