import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";

function isViewingReportDetail() {
  return window.location.pathname.match(/\/reports\/\S*\/\S*/);
}

function Layout(): JSX.Element {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  function toggleMenuIsOpen() {
    setMenuIsOpen((previous) => !previous);
  }

  return (
    <>
      {!isViewingReportDetail() && (
        <>
          <Header toggleMenu={toggleMenuIsOpen} />
          <Menu open={menuIsOpen} toggleMenu={toggleMenuIsOpen} />
        </>
      )}
      <main className="container mx-auto">
        <Outlet />
      </main>
    </>
  );
}

export default Layout;
