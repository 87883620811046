import { ChangePageEvent } from "../types";

export function getPaginatorPage(
  currentPageNumber: number,
  totalPages: number,
  event: ChangePageEvent
): number {
  switch (event) {
    case "first":
      return 1;
    case "prev":
      if (currentPageNumber === 1) return 1;
      return currentPageNumber - 1;
    case "next":
      if (currentPageNumber === totalPages) return totalPages;
      return currentPageNumber + 1;
    case "last":
      return totalPages;
  }
}
