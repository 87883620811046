import DetailField from "../../components/DetailField";
import { TransactionDetail } from "../../services/transactionsService";
import Panel from "../customerSettings/Panel";

export type Props = {
  transactionDetail?: TransactionDetail | null;
  isLoading?: boolean;
};

function Transaction3DSDetail({
  transactionDetail,
  isLoading = false,
}: Props): JSX.Element {
  return (
    <Panel className="my-2 w-full rounded-lg border border-gray-200 shadow-sm md:w-[49%]">
      <Panel.Header title="3DS" className="rounded-b-none" />
      <Panel.Content>
        <div className="grid grid-cols-1 grid-rows-4 gap-x-8 gap-y-4 p-4 md:p-2 lg:grid-cols-2 ">
          <DetailField label="Liability Shift" isLoading={isLoading}>
            {transactionDetail?.dsLiabilityShift}
          </DetailField>
          <div className="hidden lg:block"></div>
          <DetailField label="*3DS Version" isLoading={isLoading}>
            {transactionDetail?.dsVersion}
          </DetailField>
          <div className="hidden lg:block"></div>
          <DetailField label="*3DS Offered" isLoading={isLoading}>
            {transactionDetail?.dsOffered}
          </DetailField>
          <DetailField label="*3DS Offered Response" isLoading={isLoading}>
            {transactionDetail?.dsOfferedResponse}
          </DetailField>
          <DetailField label="*3DS Authenticated" isLoading={isLoading}>
            {transactionDetail?.dsAuthenticated}
          </DetailField>
          <DetailField
            label="*3DS Authenticated Response"
            isLoading={isLoading}
          >
            {transactionDetail?.dsAuthenticatedResponse}
          </DetailField>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default Transaction3DSDetail;
