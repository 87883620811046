import { AccountHolderRatePlan } from "../../services/accountService";
import { RatePlan, RatePlanType } from "./RatePlans";
import RatePlanList from "./RatePlanList";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  useInterchangeRate: boolean;
  ratePlanType: RatePlanType;
  isSurchargeEditable: boolean;
  isLoading: boolean;
};

const ratePlans: RatePlan[] = [
  { id: "visa", label: "Visa" },
  { id: "mc", label: "Mastercard" },
  { id: "amex", label: "AMEX" },
  { id: "discover", label: "Discover" },
  { id: "ach", label: "ACH" },
  { id: "applepay", label: "Apple Pay" },
  { id: "paypal", label: "Paypal" },
];

function FlatRatePlans({
  accountHolderCode,
  accountHolderId,
  accountHolderRatePlans,
  useInterchangeRate,
  ratePlanType,
  isSurchargeEditable,
  isLoading,
}: Props): JSX.Element {
  return (
    <div className="flex flex-shrink-0 flex-col flex-wrap">
      <div className="mb-3">
        <RatePlanList
          accountHolderCode={accountHolderCode}
          accountHolderId={accountHolderId}
          ratePlans={ratePlans}
          accountHolderRatePlans={accountHolderRatePlans}
          ratePlanType={ratePlanType}
          isSurchargeEditable={isSurchargeEditable}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default FlatRatePlans;
