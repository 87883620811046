import appInsights from "./appInsights";

export function logReportInteraction(
  reportName: string,
  userName: string | undefined
) {
  const message = `${reportName} Report viewed: ${userName}`;
  appInsights.trackEvent({ name: message });
}

export function logCSVInteraction(
  reportName: string,
  userName: string | undefined
) {
  const message = `${reportName} CSV downloaded: ${userName}`;
  appInsights.trackEvent({ name: message });
}

export function logPageVisit(pageName: string, userName: string | undefined) {
  const message = `${pageName} Page visited: ${userName}`;
  appInsights.trackEvent({ name: message });
}
