import { useMutation } from "@tanstack/react-query";
import {
  createUser,
  CreateUserBody,
  SignUpFormData,
} from "../../../services/userService";

export default function useCreateUserMutation() {
  return useMutation<CreateUserBody, string, SignUpFormData>((body) =>
    createUser(body)
  );
}
