import { useMemo, useState } from "react";
import Table from "../../components/Table";
import useUserDetailsQuery from "../../hooks/data/queries/useUserDetailsQuery";
import {
  AccountHolder,
  AccountHolderUser,
} from "../../services/accountService";
import { getEmptyUser } from "../../utils/users";
import { getUserDetailsTableColumns } from "./userDetailsTableColumns";
import Error from "../../components/Error";
import Button from "../../components/Button";
import HasAccess, { csrAdminRoles } from "../../components/HasAccess";
import Modal from "../../components/Modal";
import SignUpForm from "../onboarding/SignUpForm";
import { useSnackbar } from "../../components/Snackbar";
import useCreateUserMutation from "../../hooks/data/mutations/useCreateUserMutation";
import { useQueryClient } from "@tanstack/react-query";
import { SignUpFormData } from "../../services/userService";
import { PlusIcon } from "@heroicons/react/24/solid";
import Panel from "./Panel";

type Props = {
  accountHolderCode: string;
  accountConfigData?: AccountHolder;
};

function MerchantUsers({
  accountHolderCode,
  accountConfigData,
}: Props): JSX.Element {
  const {
    data: userDetailsData,
    isLoading,
    error,
  } = useUserDetailsQuery(accountHolderCode);

  const memoUsers = useMemo(() => {
    if (isLoading && !userDetailsData) {
      return Array.from<AccountHolderUser>({ length: 5 }).fill(getEmptyUser());
    }
    return userDetailsData?.at(0)?.userInfo ?? [];
  }, [userDetailsData, isLoading]);

  const [showSignUpModal, setShowSignupModal] = useState(false);

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  const { mutate, status } = useCreateUserMutation();
  const queryClient = useQueryClient();

  async function handleCreateUserFormSubmit(signupFormData: SignUpFormData) {
    mutate(signupFormData, {
      onSuccess: ({ firstName, lastName }) => {
        openSnackbar(`User ${firstName} ${lastName} was succesfully added.`, {
          type: "success",
        });
        queryClient.invalidateQueries(["userDetails", accountHolderCode]);
      },
      onError: (error) => {
        openSnackbar(error, { type: "error" });
      },
      onSettled: () => {
        setShowSignupModal(false);
      },
    });
  }

  return (
    <>
      <Panel className="col-span-12">
        <Panel.Header title="Merchant Users">
          <HasAccess allowedRoles={[...csrAdminRoles]}>
            <Button
              custom-id="addMerchantUserButton"
              size="sm"
              onClick={() => setShowSignupModal(true)}
              disabled={isLoading}
              icon={<PlusIcon />}
            >
              Add User
            </Button>
          </HasAccess>
        </Panel.Header>
        <Panel.Content>
          <div>
            {error ? (
              <Error message={error} />
            ) : (
              <Table
                id="merchantUsers"
                data={memoUsers}
                columns={getUserDetailsTableColumns()}
                isLoading={isLoading}
                showPaginator={false}
                noRowsText="There are no merchant users to display."
              />
            )}
          </div>
        </Panel.Content>
      </Panel>
      <Modal
        isOpen={showSignUpModal}
        title="Sign Up"
        onClose={() => setShowSignupModal(false)}
      >
        <SignUpForm
          accountHolderInfo={{
            edition: accountConfigData?.edition ?? "",
            jackrabbitId: accountConfigData?.jrId ?? "",
            companyName: accountConfigData?.legalBusinessName ?? "",
          }}
          invitedUserEmail=""
          createUserStatus={status}
          onSubmit={handleCreateUserFormSubmit}
          onCancel={() => setShowSignupModal(false)}
        />
      </Modal>
    </>
  );
}

export default MerchantUsers;
