import {
  getWebhookConfiguration,
  GetWebhookConfigurationResponse,
} from "../../../services/accountService";
import { useQuery } from "@tanstack/react-query";

export default function useWebhookConfigQuery() {
  return useQuery<GetWebhookConfigurationResponse, string>(
    ["webhookConfiguration"],
    () => getWebhookConfiguration()
  );
}
