import {
  getTimeZoneByIana,
  GetTimeZoneByIanaResponse,
} from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useTimezoneByIanaQuery(
  ianaCode: string,
  queryOptions?: UseQueryOptions<GetTimeZoneByIanaResponse, string>
) {
  return useQuery<GetTimeZoneByIanaResponse, string>(
    ["timezoneByIana", ianaCode],
    () => getTimeZoneByIana(ianaCode),
    queryOptions
  );
}
