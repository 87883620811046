import { useMsal } from "@azure/msal-react";
import JackrabbitPayLogo from "../assets/logo-jackrabbit-pay-2023-white-final.svg";
import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon";
import Button from "./Button";
import useCurrentUserAccount from "../hooks/data/queries/useCurrentUserAccount";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import * as Logos from "../utils/logos";

type Props = {
  toggleMenu?: () => void;
  showMenuIcon?: boolean;
  goToHelpCenter?: () => void;
  goToSubmitIdea?: () => void;
};

function Header({
  toggleMenu = () => {},
  showMenuIcon = true,
  goToHelpCenter = () => {
    window.open("https://help.jackrabbitpay.com/help", "_blank");
  },
  goToSubmitIdea = () => {
    window.open(
      "https://ideas33812jackrabbittechnologies.ideas.aha.io/ideas/new",
      "_blank"
    );
  },
}: Props): JSX.Element {
  const { instance } = useMsal();
  const { data: currentUserAccount } = useCurrentUserAccount();

  return (
    <div className="sticky top-0 z-40 mb-4 h-14 w-full bg-primary-700 p-2 backdrop-blur-sm backdrop-filter">
      <div className="flex h-full items-center justify-between p-2">
        <div className="flex items-center gap-x-2">
          {showMenuIcon && (
            <Bars3Icon
              onClick={toggleMenu}
              className="h-9 w-9 flex-none cursor-pointer select-none rounded-md p-1 text-white hover:bg-primary-600"
            />
          )}

          <img
            src={JackrabbitPayLogo}
            alt="Jackrabbit Pay Logo"
            className="mt-1 h-6"
          />
        </div>
        <div className="flex h-full items-center justify-between pt-1">
          <Popover className="relative pr-3">
            <Tippy className="mt-2" content="Help">
              <Popover.Button onClick={goToHelpCenter}>
                <Logos.JRPayHelpLogo className="h-8 w-10 flex-none text-white" />
              </Popover.Button>
            </Tippy>
          </Popover>
          <Popover className="relative">
            <Tippy className="mt-2" content="Submit Idea">
              <Popover.Button onClick={goToSubmitIdea}>
                <Logos.JRPayIdeaLogo className="h-8 w-10 flex-none text-white" />
              </Popover.Button>
            </Tippy>
          </Popover>
          <Popover className="relative">
            <Popover.Button>
              <Tippy className="mt-2" content="Account">
                <Logos.JRPayUserLogo className="h-8 w-10 flex-none text-white" />
              </Tippy>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -right-4 top-12 z-10 mt-8 w-auto px-4">
                <div className="flex w-48 flex-col items-center gap-y-2 rounded-lg bg-white p-2 shadow-xl ring-1 ring-black ring-opacity-5">
                  <div
                    className="w-full truncate text-center font-semibold"
                    title={currentUserAccount?.name}
                  >
                    {currentUserAccount?.name}
                  </div>
                  <div className="capitalize text-slate-500">
                    {currentUserAccount?.role?.toLowerCase()}
                  </div>
                  <Button
                    size="sm"
                    className="w-full border border-white"
                    onClick={() => instance.logoutRedirect()}
                  >
                    Sign out
                  </Button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default Header;
