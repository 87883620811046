import { useMutation } from "@tanstack/react-query";
import {
  createAccount,
  createAccountBalance,
  CreateAccountBalanceBody,
  CreateAccountBody,
  CreateAccountResponse,
} from "../../../services/accountService";
import useCreateSweepMutation from "./useCreateSweepMutationt";

export default function useCreateAccountMutation(inBalancePlatform: boolean) {
  const { mutateAsync: createSweep, error } = useCreateSweepMutation();

  return useMutation<
    CreateAccountResponse,
    string,
    CreateAccountBody | CreateAccountBalanceBody
  >(
    (body) => {
      if (inBalancePlatform) {
        return createAccountBalance(body);
      } else {
        return createAccount(body);
      }
    },
    {
      async onSuccess(data, variables) {
        if (inBalancePlatform) {
          const balanceVariables = variables as CreateAccountBalanceBody;
          await createSweep({
            balanceAccountId: data.accountCode,
            counterParty: {
              transferInstrumentId: balanceVariables.bankAccountUuid,
            },
            triggerAmount: {
              currency: balanceVariables.defaultCurrencyCode,
              value: 0,
            },
            currency: balanceVariables.defaultCurrencyCode,
            priorities: [2, 3], // Fast, Instant
            category: 1, // Bank
            schedule: {
              type: 1, // Daily
            },
            type: 2, // Push
            status: 1, // Active
          });

          if (error) {
            throw new Error(error);
          }
        }
      },
    }
  );
}
