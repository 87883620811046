import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  UpdateAccountHolderMonthlyFeeBody,
  updateAccountHolderMonthlyFee,
} from "../../../services/accountService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";
import { formatAmount } from "../../../utils/currency";

type UpdateAccountHolderMonthlyFeeBodyWithAccountHolderCode =
  UpdateAccountHolderMonthlyFeeBody & { accountHolderCode: string };

export default function useUpdateAccountHolderMonthlyFeeMutation() {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<
    string,
    string,
    UpdateAccountHolderMonthlyFeeBodyWithAccountHolderCode
  >((body) => updateAccountHolderMonthlyFee(body), {
    async onSuccess(_, body) {
      const accountConfigData = queryClient.getQueryData<AccountHolder>([
        "accountConfig",
        body.accountHolderCode,
      ]);

      queryClient.setQueryData(["accountConfig", body.accountHolderCode], {
        ...accountConfigData,
        hasMonthlyFee: body.hasMonthlyFee,
        monthlyFee: body.monthlyFee,
      });

      await createNote({
        accountHolderCode: body.accountHolderCode,
        noteContent: [
          {
            fieldName: "monthlyFee",
            fromValue: accountConfigData?.hasMonthlyFee
              ? `yes (${formatAmount(accountConfigData.monthlyFee)})`
              : "no",
            toValue: body.hasMonthlyFee
              ? `yes (${formatAmount(body.monthlyFee)})`
              : "no",
          },
        ],
      });

      queryClient.invalidateQueries(["accountConfig", body.accountHolderCode]);
    },
  });
}
