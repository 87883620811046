import { SilentRequest } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";
import msalInstance from "../msal-instance";
import { UserRole, UserType } from "../types";

export type UserAccount = {
  username: string;
  name?: string;
  accountHolderCode?: string;
  role?: UserRole;
  type?: UserType;
};

export async function getCurrentUserAccount() {
  const [currentAccount] = msalInstance.getAllAccounts();
  return currentAccount;
}

export async function getCurrentUserAccountWithExtraFields(): Promise<UserAccount> {
  try {
    const { username, name, idTokenClaims } = await getCurrentUserAccount();
    const accountHolderCode =
      idTokenClaims && (idTokenClaims["extension_AccountHolderCode"] as string);
    const role =
      idTokenClaims &&
      ((
        idTokenClaims["extension_JackrabbitPayRole"] as string
      ).toUpperCase() as UserRole);

    return {
      username,
      name,
      accountHolderCode,
      role,
      type:
        role === "ADMIN" || role === "REPRESENTATIVE" || role === "STAKEHOLDER"
          ? "CSR"
          : "Customer",
    };
  } catch (e) {
    return Promise.reject("An error has occured. Please try again.");
  }
}

export async function getCurrentUserAccountToken() {
  const account = await getCurrentUserAccount();
  const request: SilentRequest = {
    account,
    ...apiRequest,
  };

  const { idToken, accessToken } = await msalInstance.acquireTokenSilent(
    request
  );

  if (accessToken) return accessToken;
  return idToken;
}
