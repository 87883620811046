import { useEffect, useState } from "react";
import Input from "../../components/Input";
import Switch from "../../components/Switch";
import EditControls from "./EditControls";
import useUpdateAccountHolderMonthlyFeeMutation from "../../hooks/data/mutations/useUpdateAccountHolderMonthlyFeeMutation";
import { formatAmount } from "../../utils/currency";
import { useSnackbar } from "../../components/Snackbar";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  hasMonthlyFee: boolean;
  monthlyFee: string;
};

function MonthlyFee({
  accountHolderCode,
  accountHolderId,
  hasMonthlyFee,
  monthlyFee,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [currentHasMonthlyFee, setCurrentHasMonthlyFee] =
    useState(hasMonthlyFee);
  const [currentMonthlyFee, setCurrentMonthlyFee] = useState(monthlyFee);

  useEffect(() => {
    setCurrentHasMonthlyFee(hasMonthlyFee);
  }, [hasMonthlyFee]);

  useEffect(() => {
    setCurrentMonthlyFee(monthlyFee);
  }, [monthlyFee]);

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });
  const { mutate, isLoading } = useUpdateAccountHolderMonthlyFeeMutation();

  return (
    <div className="mt-7 grid grid-cols-[2.2fr_1.2fr_1fr] items-center justify-center gap-x-1">
      <Switch
        checked={isEditing ? currentHasMonthlyFee : hasMonthlyFee}
        disabled={!isEditing}
        onChange={() => setCurrentHasMonthlyFee((p) => !p)}
        label="Monthly Fee"
        labelProps={{
          className: "font-medium",
        }}
      />
      <Input
        id="monthlyFeeAmount"
        type="number"
        label="Amount"
        value={isEditing ? currentMonthlyFee : formatAmount(monthlyFee)}
        onChange={(e) => setCurrentMonthlyFee(e.target.value)}
        disabled={!isEditing}
        className="w-28"
      />
      <div className="flex items-center justify-center gap-x-2">
        <EditControls
          isEditing={isEditing}
          isSaving={isLoading}
          editButtonProps={{
            variant: "secondary",
            size: "sm",
            className: "w-full",
            onClick: () => setIsEditing(true),
          }}
          saveButtonProps={{
            disabled:
              hasMonthlyFee === currentHasMonthlyFee &&
              monthlyFee === currentMonthlyFee,
            onClick: () => {
              mutate(
                {
                  accountHolderCode,
                  accountHolderId,
                  hasMonthlyFee: currentHasMonthlyFee,
                  monthlyFee: currentMonthlyFee,
                },
                {
                  onSuccess() {
                    openSnackbar("Monthly Fee was successfully updated.", {
                      type: "success",
                      duration: 5000,
                    });
                    setIsEditing(false);
                  },
                  onError(err) {
                    openSnackbar(err, {
                      type: "error",
                      duration: 5000,
                    });
                  },
                }
              );
            },
          }}
          cancelButtonProps={{
            onClick: () => {
              setIsEditing(false);
              setCurrentHasMonthlyFee(hasMonthlyFee);
              setCurrentMonthlyFee(monthlyFee);
            },
          }}
        />
      </div>
    </div>
  );
}

export default MonthlyFee;
