import { getTaxForm, GetTaxFormBody } from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useTaxFormQuery(
  body: GetTaxFormBody,
  queryOptions?: UseQueryOptions<string, string>
) {
  return useQuery<string, string>(
    ["taxForm", body.accountHolderCode],
    () => getTaxForm(body),
    queryOptions
  );
}
