import { useMutation } from "@tanstack/react-query";
import {
  MigrateAccountHolderResponse,
  migrateAccountHolder,
} from "../../../services/accountService";

export default function useMigrateAccountHolderMutation() {
  return useMutation<MigrateAccountHolderResponse, Error, string>(
    (accountHolderCode: string) => migrateAccountHolder(accountHolderCode)
  );
}
