import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  refundTransaction,
  RefundTransactionBody,
  RefundTransactionResponse,
} from "../../../services/transactionsService";

export default function useRefundTransactionMutation(pspReference: string) {
  const queryClient = useQueryClient();

  return useMutation<RefundTransactionResponse, Error, RefundTransactionBody>(
    ({ pspReference, amount, currency, merchantAccount, reference = "" }) =>
      refundTransaction({
        pspReference,
        amount,
        currency,
        merchantAccount,
        reference,
      }),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(["transaction", pspReference]);
      },
    }
  );
}
