import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import JackrabbitPayLogo from "../../assets/logo-jackrabbit-pay-2023-color-final.svg";

function DisabledUser(): JSX.Element {
  const { instance } = useMsal();
  const [remainingTime, setRemainingTime] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime === 1) {
        logout();
      } else {
        setRemainingTime((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  function logout() {
    instance.logoutRedirect();
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="mx-4 flex flex-col items-center gap-y-8 rounded-lg border border-gray-200 bg-white p-6 shadow-xl sm:mx-0 md:p-12">
        <img
          src={JackrabbitPayLogo}
          alt="Jackrabbit Pay Logo"
          className="w-72 self-center"
        />
        <p className="text-xl">
          Sub Merchant Account is on Closed state. Please contact Jackrabbit
          customer service.
        </p>
        <p className="text-xl">
          You'll redirected to the{" "}
          <button onClick={() => logout()} className="text-primary underline">
            login page
          </button>{" "}
          in {remainingTime} seconds.
        </p>
      </div>
    </div>
  );
}

export default DisabledUser;
