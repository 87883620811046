import { useState } from "react";
import JackrabbitPayLogo from "../../assets/logo-jackrabbit-pay-2023-color-final.svg";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import useOnboardingUrlQuery from "../../hooks/data/queries/useOnboardingUrlQuery";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import Header from "../../components/Header";
import useAccountConfigQuery from "../../hooks/data/queries/useAccountConfigQuery";
import useOnboardingUrlBalanceQuery from "../../hooks/data/queries/useOnboardingUrlBalanceQuery";
import useThemeIdByMerchantAccountId from "../../hooks/data/queries/useThemeIdByMerchantAccountId";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../../components/Info";

function Welcome(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: currentUserAccount } = useCurrentUserAccount();
  const [onboardingUrlQueryEnabled, setOnboardingUrlQueryEnabled] =
    useState<boolean>(false);

  const {
    data: accountConfigData,
    isLoading: accountConfigIsLoading,
    error: accountConfigError,
  } = useAccountConfigQuery(currentUserAccount?.accountHolderCode);

  const {
    data: themeIdData,
    isLoading: themeIdIsLoading,
    error: themeIdError,
  } = useThemeIdByMerchantAccountId(
    accountConfigData?.merchantAccountId ?? "",
    {
      enabled: Boolean(accountConfigData?.inBalancePlatform),
    }
  );

  const {
    isSuccess: onboardingUrlIsSuccess,
    isFetching: onboardingUrlIsFetching,
    isError: onboardingUrlIsError,
  } = useOnboardingUrlQuery(
    {
      accountHolderCode: currentUserAccount?.accountHolderCode || "",
      returnUrl: process.env.REACT_APP_BASE_URI || window.location.origin,
    },
    {
      enabled:
        onboardingUrlQueryEnabled && !accountConfigData?.inBalancePlatform,
      onSuccess({ resultCode, redirectUrl }) {
        if (resultCode === "Success") {
          window.location.href = redirectUrl;
        }
      },
      onError() {
        setOnboardingUrlQueryEnabled(false);
      },
    }
  );

  const {
    isSuccess: onboardingUrlBalanceIsSuccess,
    isFetching: onboardingUrlBalanceIsFetching,
    isError: onboardingUrlBalanceIsError,
  } = useOnboardingUrlBalanceQuery(
    {
      returnUrl: process.env.REACT_APP_BASE_URI || window.location.origin,
      legalEntityId: accountConfigData?.legalEntityId ?? "",
      themeId: themeIdData || "",
    },
    {
      enabled: Boolean(
        onboardingUrlQueryEnabled && accountConfigData?.inBalancePlatform
      ),
      onSuccess({ redirectUrl }) {
        window.location.href = redirectUrl;
      },
      onError() {
        setOnboardingUrlQueryEnabled(false);
      },
    }
  );

  const currentUserIsCSROrOwner =
    currentUserAccount?.type === "CSR" || currentUserAccount?.role === "OWNER";

  return (
    <>
      <Header showMenuIcon={false} />
      <div className="flex h-[90vh] w-screen items-center justify-center">
        <div className="flex w-11/12 flex-col gap-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-xl md:w-[700px] md:p-12">
          <img
            src={JackrabbitPayLogo}
            alt="Jackrabbit Pay Logo"
            className="w-72 self-center"
          />
          <p className="text-2xl">
            Hi <span className="font-semibold">{currentUserAccount?.name}</span>
            ,
          </p>
          <p className="text-xl">
            An Owner update of your account information (KYC) is required.
          </p>
          {!currentUserIsCSROrOwner && (
            <Info message="Only Owner can update the account information." />
          )}
          <div
            className={`flex ${
              currentUserIsCSROrOwner ? "justify-between" : "justify-center"
            }`}
          >
            {currentUserIsCSROrOwner && (
              <Button
                onClick={() => setOnboardingUrlQueryEnabled(true)}
                className="mt-2 gap-x-1 self-center text-xl"
                disabled={
                  onboardingUrlIsFetching ||
                  onboardingUrlBalanceIsFetching ||
                  onboardingUrlIsSuccess ||
                  onboardingUrlBalanceIsSuccess ||
                  accountConfigIsLoading ||
                  (accountConfigData?.inBalancePlatform && themeIdIsLoading)
                }
                icon={
                  (onboardingUrlIsFetching ||
                    onboardingUrlBalanceIsFetching) && (
                    <Spinner className="mt-0.5 mr-1 text-gray-400" />
                  )
                }
              >
                Update KYC
              </Button>
            )}
            <Button
              onClick={() =>
                navigate("/", {
                  state: {
                    previousLocationPathName: location.pathname,
                  },
                })
              }
              className="mt-2 w-64 gap-x-1 self-center text-xl"
            >
              Return to Home Page
            </Button>
          </div>
          {(accountConfigError ||
            themeIdError ||
            onboardingUrlIsError ||
            onboardingUrlBalanceIsError) && (
            <Error message="An error has occured. Please try again." />
          )}
        </div>
      </div>
    </>
  );
}

export default Welcome;
