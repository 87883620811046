import msalInstance from "../msal-instance";
import { getCurrentUserAccountToken } from "./userAccounts";

type HTTPCallOptions<TData, TBody, TMappedData> = {
  url: string;
  errorMessage: string;
  responseType?: "json" | "text";
  body?: TBody | null;
  mapFn?: (data: TData) => TMappedData;
  mapErrorMessageFn?: (error: string) => string;
  headers?: {
    [key: string]: string;
  };
};

type ErrorResponse = {
  Message: string;
  StatusCode: number;
};

export async function httpCall<
  TData,
  TBody = unknown,
  TMappedData extends TData = TData
>(
  method: "GET" | "POST",
  {
    url,
    errorMessage,
    responseType = "json",
    body = null,
    mapFn = (x) => x as TMappedData,
    mapErrorMessageFn,
    headers,
  }: HTTPCallOptions<TData, TBody, TMappedData>
) {
  try {
    const userToken = await getCurrentUserAccountToken();
    const response = await fetch(url, {
      method,
      headers: headers || {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if (response.ok) {
      const data =
        responseType === "json" && response.status !== 204
          ? await response.json()
          : await response.text();
      return mapFn(data) as TMappedData;
    } else {
      if (response.status === 401) {
        await msalInstance.logoutRedirect();
      }
      if (response.status === 500) {
        const errorData = (await response.json()) as ErrorResponse;
        return Promise.reject(errorData?.Message ?? errorMessage);
      }
      if (mapErrorMessageFn) {
        const newErrorMessage = mapErrorMessageFn(await response.text());
        return Promise.reject(newErrorMessage);
      } else {
        return Promise.reject(errorMessage);
      }
    }
  } catch (e) {
    return Promise.reject(errorMessage);
  }
}
