import {
  AdyenPlatformExperience,
  PayoutsOverview,
  PayoutDetails,
} from "@adyen/adyen-platform-experience-web";
import "@adyen/adyen-platform-experience-web/adyen-platform-experience-web.css";
import { useEffect, useRef } from "react";
import { getAdyenComponentSessionBody } from "../services/transactionsService";
import { useParams } from "react-router";

type PayoutsParams = { accountHolderCode: string };

async function getAdyenPlatformExperience(accountHolderCode: string) {
  return await AdyenPlatformExperience({
    locale: "en-US",
    async onSessionCreate() {
      const sessionData = await getAdyenComponentSessionBody({
        allowOrigin: process.env.REACT_APP_BASE_URI?.slice(0, -1) ?? "",
        accountHolderId: accountHolderCode,
        roles: ["Payouts Overview Component: View"],
      });

      return sessionData;
    },
  });
}

function Payout(): JSX.Element {
  const { accountHolderCode } = useParams<PayoutsParams>();
  const payoutsOverviewRef = useRef<HTMLDivElement | null>(null);
  const payoutsDetailsRef = useRef<HTMLDivElement | null>(null);

  async function mountPayoutsOverviewComponent(node: HTMLElement) {
    const payoutsOverview = new PayoutsOverview({
      core: await getAdyenPlatformExperience(accountHolderCode ?? ""),
    });
    payoutsOverview.mount(node);
  }

  async function mountPayoutsDetailsComponent(node: HTMLElement) {
    const payoutsDetail = new PayoutDetails({
      core: await getAdyenPlatformExperience(accountHolderCode ?? ""),
      id: "",
      date: "",
    });
    payoutsDetail.mount(node);
  }

  useEffect(() => {
    mountPayoutsOverviewComponent(payoutsOverviewRef.current as HTMLElement);
    mountPayoutsDetailsComponent(payoutsDetailsRef.current as HTMLElement);
  }, []);

  return (
    <>
      <div className="payouts-overview" ref={payoutsOverviewRef} />
      <div className="payouts-details hidden" ref={payoutsDetailsRef} />
    </>
  );
}

export default Payout;
