import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  UpdateAccountHolderInterhchangeFeeBody,
  updateAccountHolderInterchangeFee,
} from "../../../services/accountService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";

export default function useUpdateInterchangeFee() {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, string, UpdateAccountHolderInterhchangeFeeBody>(
    (body) => updateAccountHolderInterchangeFee(body),
    {
      async onSuccess(_, body) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          body.accountHolderCode,
        ]);

        queryClient.setQueryData(["accountConfig", body.accountHolderCode], {
          ...accountConfigData,
          useInterchangeRate: body.useInterchangeRate,
        });

        await createNote({
          accountHolderCode: body.accountHolderCode,
          noteContent: [
            {
              fieldName: "useInterchangeRate",
              fromValue: accountConfigData?.useInterchangeRate ? "yes" : "no",
              toValue: body.useInterchangeRate ? "yes" : "no",
            },
          ],
        });

        queryClient.invalidateQueries([
          "accountConfig",
          body.accountHolderCode,
        ]);
      },
    }
  );
}
