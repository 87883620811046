import { useState } from "react";
import AdminReport from "./AdminReport";
import BasicReport from "./BasicReport";
import Tab from "../../components/Tab";
import HasAccess, { csrAdminRoles } from "../../components/HasAccess";

function Reports(): JSX.Element {
  const [tabIndex, setTabIndex] = useState(-1);

  return (
    <div className="flex flex-col px-6">
      <h1 className="mb-6 text-3xl">Reports</h1>
      <div className="w-96">
        <HasAccess
          allowedRoles={[...csrAdminRoles]}
          notAllowedComponent={<BasicReport />}
        >
          <Tab.Group
            selectedIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <Tab.List>
              <Tab index={0}>Report</Tab>
              <Tab index={1}>Admin Report</Tab>
            </Tab.List>
            <Tab.Panels className="mt-8">
              <BasicReport />
              <AdminReport />
            </Tab.Panels>
          </Tab.Group>
        </HasAccess>
      </div>
    </div>
  );
}

export default Reports;
