import { DateTime } from "luxon";
import Button from "../../components/Button";
import AmountFilter from "./filters/AmountFilter";
import SimpleValueFilter from "./filters/SimpleValueFilter";
import DateFilter from "./filters/DateFilter";
import {
  DateFilterType,
  TransactionsFilter,
} from "../../services/transactionsService";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import StatusFilter from "./filters/StatusFilter";
import transactionTypes from "../../data/transactionTypes.json";
import transactionStatus from "../../data/transactionsStatus.json";
import TypeFilter from "./filters/TypeFilter";
import HasAccess, { csrRoles } from "../../components/HasAccess";
import { useEffect, useRef, useState } from "react";
import Autocomplete from "../../components/Autocomplete";
import useAccountHoldersQuery from "../../hooks/data/queries/useAccountHoldersQuery";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";

type Props = {
  filter: TransactionsFilter;
  onFilterChange: (filter: TransactionsFilter) => void;
  onFilterReset: () => void;
};

function TransactionsTableFilter({
  filter,
  onFilterChange,
  onFilterReset,
}: Props): JSX.Element {
  const [accountHolderCodeQuery, setAccountHolderCodeQuery] = useState("");
  const accountHolderRef = useRef<HTMLInputElement | null>(null);
  const { data: currentUserAccount } = useCurrentUserAccount();

  const { data: accountHoldersData } = useAccountHoldersQuery(
    {
      accountHolderCode: "",
      status: "all",
      pageNumber: 1,
      pageSize: 100_000,
      sort: [],
    },
    {
      enabled: currentUserAccount?.type === "CSR",
      select(data) {
        const completed = data.pageElements.filter(
          (x) => x.adyenStatus === "Active" && x.jrPayStatus === "True"
        );
        const kycNeeded = data.pageElements.filter(
          (x) => x.jrPayStatus === "False" && x.migrated
        );

        return {
          ...data,
          pageElements: [...completed, ...kycNeeded],
        };
      },
    }
  );

  const filteredAccountHolders =
    accountHolderCodeQuery === ""
      ? []
      : accountHoldersData?.pageElements
          .filter((x) =>
            x.adyenAccountHolderCode
              .toLowerCase()
              .includes(accountHolderCodeQuery.toLowerCase())
          )
          .slice(0, 10)
          .map((x) => ({
            value: x.adyenAccountHolderCode,
            label: x.adyenAccountHolderCode,
          })) ?? [];

  useEffect(() => {
    accountHolderRef.current?.focus();
  }, []);

  function handleAccountHolderSelected(accountHolderCode: string | null) {
    onFilterChange({
      ...filter,
      accountHolderCode: accountHolderCode || "",
    });

    if (!accountHolderCode) {
      accountHolderRef.current?.focus();
    }
  }

  return (
    <div className="mt-3">
      <div className="flex flex-col gap-y-4">
        <h3 className="pb-2 text-xl">Filters</h3>
        <HasAccess allowedRoles={[...csrRoles]}>
          <div className="flex gap-x-2">
            <div>
              <Autocomplete
                items={filteredAccountHolders}
                value={filter.accountHolderCode || ""}
                onChange={handleAccountHolderSelected}
                inputValue={accountHolderCodeQuery}
                onInputValueChange={setAccountHolderCodeQuery}
                ref={accountHolderRef}
                inputClassname="w-64"
              />
            </div>
          </div>
        </HasAccess>
        <div className="flex flex-wrap gap-2">
          <TypeFilter
            options={transactionTypes}
            value={filter.transactionType}
            onApply={(transactionType) => {
              onFilterChange({
                ...filter,
                transactionType,
              });
            }}
          />
          <StatusFilter
            value={filter.status}
            statusList={transactionStatus}
            onApply={(status) => {
              onFilterChange({
                ...filter,
                status,
              });
            }}
          />
          <DateFilter
            value={filter.date}
            defaultType="Last1Hour"
            onApply={(from: DateTime, to: DateTime, type: DateFilterType) => {
              onFilterChange({
                ...filter,
                date: {
                  from,
                  to,
                  type,
                },
              });
            }}
          />
          <AmountFilter
            from={filter.amount.from}
            to={filter.amount.to}
            onApply={(from: number | null, to: number | null) =>
              onFilterChange({
                ...filter,
                amount: { from, to },
              })
            }
          />
          <SimpleValueFilter
            value={filter.pspReference}
            onApply={(pspReference: string) =>
              onFilterChange({ ...filter, pspReference })
            }
            title="PSP Reference"
          />
          <SimpleValueFilter
            value={filter.merchantReference}
            onApply={(merchantReference: string) =>
              onFilterChange({
                ...filter,
                merchantReference,
              })
            }
            title="Merchant Reference"
          />
          <SimpleValueFilter
            value={filter.cardOrAccountNumber}
            onApply={(cardOrAccountNumber: string) =>
              onFilterChange({
                ...filter,
                cardOrAccountNumber,
              })
            }
            title="Card/Account Number"
          />
          <Button
            variant="secondary"
            size="sm"
            onClick={onFilterReset}
            className="border-gray-500 text-gray-500 sm:ml-4"
            icon={<ArrowPathIcon />}
          >
            Reset all
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TransactionsTableFilter;
