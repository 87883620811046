import DetailField from "../../components/DetailField";
import {
  Transaction,
  TransactionDetail,
} from "../../services/transactionsService";
import Panel from "../customerSettings/Panel";

export type Props = {
  transaction?: Transaction | null;
  transactionDetail?: TransactionDetail | null;
  isLoading?: boolean;
};

function TransactionCardDetail({
  transaction,
  transactionDetail,
  isLoading = false,
}: Props): JSX.Element {
  return (
    <Panel className="my-2 w-full rounded-lg border border-gray-200 shadow-sm md:w-[49%]">
      <Panel.Header title="Payment Processing" className="rounded-b-none" />
      <Panel.Content>
        <div className="flex flex-col gap-x-4 gap-y-4 p-4 md:p-2">
          <DetailField label="Type" isLoading={isLoading} variant="start">
            Business
          </DetailField>
          <DetailField
            label="Target Account"
            isLoading={isLoading}
            variant="start"
          >
            {transaction?.accountCode}
          </DetailField>
          <DetailField label="Amount" isLoading={isLoading} variant="start">
            {transaction?.amountCurrency} {transactionDetail?.splitMarketPlace}
          </DetailField>
          <hr />
          <DetailField label="Type" isLoading={isLoading} variant="start">
            Processing Fee
          </DetailField>
          <DetailField label="Amount" isLoading={isLoading} variant="start">
            {transaction?.amountCurrency} {transactionDetail?.commissionAmount}
          </DetailField>
          <hr />
          {!transactionDetail?.useInterchangeRate && (
            <>
              <DetailField label="Type" isLoading={isLoading} variant="start">
                Technology Fee
              </DetailField>
              <DetailField label="Amount" isLoading={isLoading} variant="start">
                {transaction?.amountCurrency} {transactionDetail?.technologyFee}
              </DetailField>
              <hr />
            </>
          )}
          <DetailField label="Total" isLoading={isLoading} variant="start">
            {transaction?.amountCurrency} {transaction?.amountValue}
          </DetailField>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionCardDetail;
