import { Popover } from "@headlessui/react";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { capitalize } from "../../../utils/string";
import FilterButton from "./FilterButton";
import FilterOverlay from "./FilterOverlay";

export type Props = {
  value: string;
  onApply: (value: string) => void;
  title: string;
};

function SimpleValueFilter({ value, onApply, title }: Props): JSX.Element {
  const [internalValue, setInternalValue] = useState<string>("");
  const isFilterApplied = value !== "";

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  function handleClear() {
    onApply("");
    setInternalValue("");
  }

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button as="div">
            <FilterButton
              isFilterApplied={isFilterApplied}
              onClose={handleClear}
            >
              {isFilterApplied ? `${title}: ${value}` : title}
            </FilterButton>
          </Popover.Button>
          <FilterOverlay>
            <div className="flex flex-col gap-y-5 p-4">
              <h3 className="font-medium">{title}</h3>
              <div className="flex w-full">
                <Input
                  id={capitalize(title)}
                  value={internalValue}
                  onChange={(e) => setInternalValue(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onApply(internalValue);
                      close();
                    }
                  }}
                />
              </div>
              <div className="flex justify-between">
                <Button
                  variant="secondary"
                  size="sm"
                  disabled={value === ""}
                  onClick={() => {
                    close();
                    handleClear();
                  }}
                  className="py-1 px-2 text-sm"
                >
                  Clear
                </Button>
                <Button
                  disabled={internalValue === "" || value === internalValue}
                  size="sm"
                  onClick={() => {
                    onApply(internalValue);
                    close();
                  }}
                  className="py-1 px-2 text-sm"
                >
                  Apply
                </Button>
              </div>
            </div>
          </FilterOverlay>
        </>
      )}
    </Popover>
  );
}

export default SimpleValueFilter;
