import CountryFlagIcon from "../../components/CountryFlagIcon";
import DetailField from "../../components/DetailField";
import PaymentIcon from "../../components/PaymentIcon";
import { TransactionDetail } from "../../services/transactionsService";
import Panel from "../customerSettings/Panel";

export type Props = {
  transactionDetail?: TransactionDetail | null;
  isLoading?: boolean;
};

function TransactionACHDetail({
  transactionDetail,
  isLoading = false,
}: Props): JSX.Element {
  return (
    <Panel className="my-3 w-full rounded-lg border border-gray-200 shadow-sm md:w-[49%]">
      <Panel.Header title="ACH Details" className="rounded-b-none" />
      <Panel.Content>
        <div className="flex w-full flex-col gap-y-4 p-4 md:p-2">
          <DetailField label="Card Type" isLoading={isLoading} variant="start">
            <span className="flex items-center gap-x-2">
              {transactionDetail?.cardType && (
                <PaymentIcon type={transactionDetail.cardType} />
              )}
              {transactionDetail?.cardType?.toUpperCase()}
            </span>
          </DetailField>
          <DetailField
            label="Bank Account Number"
            isLoading={isLoading}
            variant="start"
          >
            {transactionDetail?.bankAccountNumber}
          </DetailField>
          <DetailField
            label="Consumer Name"
            isLoading={isLoading}
            variant="start"
          >
            {transactionDetail?.ownerName}
          </DetailField>
          <DetailField
            label="Issuer Country"
            isLoading={isLoading}
            variant="start"
          >
            <span className="flex items-center gap-x-2">
              {transactionDetail?.issuerCountry && (
                <CountryFlagIcon country={transactionDetail.issuerCountry} />
              )}
              {transactionDetail?.issuerCountry}
            </span>
          </DetailField>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionACHDetail;
