import { useState } from "react";
import useOnboardingUrlQuery from "../hooks/data/queries/useOnboardingUrlQuery";
import { useSnackbar } from "../components/Snackbar";

type Props = {
  accountHolder: string;
  children: React.ReactNode;
};

function ResumeHOP({ accountHolder, children }: Props): JSX.Element {
  const [onboardingUrlQueryEnabled, setOnboardingUrlQueryEnabled] =
    useState<boolean>(false);

  const prevLocation = window.location.href;

  const { openSnackbar } = useSnackbar({
    duration: 5000,
    position: "top",
    classes: "text-xl",
  });

  const { isSuccess, isError } = useOnboardingUrlQuery(
    {
      accountHolderCode: accountHolder,
      returnUrl: prevLocation,
    },
    {
      enabled: onboardingUrlQueryEnabled,
      onSuccess({ resultCode, redirectUrl }) {
        if (resultCode === "Success") {
          window.location.href = redirectUrl;
        }
      },
      onError(err) {
        setOnboardingUrlQueryEnabled(false);
        openSnackbar(err, {
          type: "error",
        });
      },
    }
  );

  return (
    <button onClick={() => setOnboardingUrlQueryEnabled(true)}>
      {children}
    </button>
  );
}

export default ResumeHOP;
