import { twJoin, twMerge } from "tailwind-merge";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

type Props = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  showCloseButton?: boolean;
  panelClasses?: string;
  titleClasses?: string;
  children: React.ReactNode;
};

function Modal({
  isOpen,
  title,
  onClose,
  showCloseButton = true,
  panelClasses = "",
  titleClasses = "",
  children,
}: Props): JSX.Element {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <div className="fixed inset-0 w-full overflow-y-auto bg-black/50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={twJoin(
                  "flex w-full transform flex-col overflow-hidden rounded-xl border bg-white p-6 text-left align-middle shadow-xl sm:w-auto",
                  panelClasses
                )}
              >
                <Dialog.Title
                  as="h3"
                  className={twMerge(
                    "relative text-center text-2xl font-medium leading-6 text-gray-900",
                    titleClasses
                  )}
                >
                  {title}
                  {showCloseButton && (
                    <button
                      className="absolute top-0 right-0 cursor-pointer"
                      onClick={onClose}
                    >
                      <XMarkIcon className="h-6 w-6" data-testid="x-icon" />
                    </button>
                  )}
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
