import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((message) => {
  if (
    message.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
    message.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE
  ) {
    msalInstance.logoutRedirect();
  }
});

export default msalInstance;
