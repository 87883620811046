import { XMarkIcon } from "@heroicons/react/24/solid";
import { twJoin } from "tailwind-merge";
import React from "react";
import Button from "../../../components/Button";

type Props = {
  isFilterApplied: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

function FilterButton({
  isFilterApplied,
  onClose,
  children,
}: Props): JSX.Element {
  return (
    <Button
      variant="secondary"
      size="sm"
      title={children ? children.toString() : ""}
      className={twJoin(
        "flex max-w-[350px] items-center gap-x-2 transition-colors",
        isFilterApplied && "bg-primary-800 text-white hover:bg-primary-500"
      )}
      icon={
        isFilterApplied && (
          <XMarkIcon
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="h-5 w-5 min-w-[1.25rem] rounded-full text-white hover:bg-white hover:text-primary-500"
          />
        )
      }
      iconPosition="right"
    >
      <span className="truncate">{children}</span>
    </Button>
  );
}

export default FilterButton;
