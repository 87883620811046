import { createColumnHelper } from "@tanstack/react-table";
import TableHeader from "../../../components/TableHeader";
import { TransactionNote } from "../../../services/transactionsService";
import { TransactionNotesTableMeta } from "./TransactionNotes";
import AddNoteCell from "./AddNoteCell";

const columnHelper = createColumnHelper<TransactionNote>();
export const getTransactionNotesTableColumns = () => [
  columnHelper.accessor("dateCreated", {
    header: () => <TableHeader text="Date" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 200,
    minSize: 100,
  }),
  columnHelper.accessor("createdBy", {
    header: () => <TableHeader text="User" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 300,
    minSize: 100,
  }),
  columnHelper.accessor("noteText", {
    header: () => <TableHeader text="Note" />,
    cell: (info) => {
      const date = info.row.getValue("dateCreated");
      const user = info.row.getValue("createdBy");
      const text = info.getValue();
      const { onNoteSaved, onNoteCanceled, isSavingNote } = info.table.options
        .meta as TransactionNotesTableMeta;

      if (!date && !user && !text) {
        return (
          <AddNoteCell
            onNoteSaved={onNoteSaved}
            onNoteCanceled={onNoteCanceled}
            isSavingNote={isSavingNote}
          />
        );
      }

      return <div className="whitespace-pre-wrap">{text}</div>;
    },
    size: 700,
    minSize: 200,
  }),
];
