import Button from "../../components/Button";
import SuccessIcon from "../../components/SuccessIcon";

type Props = {
  userFullName: string;
  userEmail: string;
  onClose: () => void;
};

function SignUpSuccess({
  userFullName,
  userEmail,
  onClose,
}: Props): JSX.Element {
  return (
    <div className="flex flex-1 flex-col items-center gap-2">
      <SuccessIcon className="mt-4 h-14 w-14 fill-success" />
      <div className="w-[450px] pt-2 text-center text-lg">
        <p>
          To complete the onboarding process an email has been sent to{" "}
          {userFullName} ({userEmail})
        </p>
      </div>
      <div>
        <Button size="sm" className="mt-12 w-full" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
}

export default SignUpSuccess;
