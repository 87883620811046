import useCurrentUserAccount from "../hooks/data/queries/useCurrentUserAccount";
import { UserRole } from "../types";

export const csrRoles: UserRole[] = ["ADMIN", "REPRESENTATIVE", "STAKEHOLDER"];
export const csrAdminRoles: UserRole[] = ["ADMIN", "REPRESENTATIVE"];
export const ownerUserRoles: UserRole[] = ["OWNER", "USER"];
export const customerRoles: UserRole[] = [
  "OWNER",
  "CONTROLLER",
  "SIGNATORY",
  "USER",
];
export const kycRoles: UserRole[] = [...csrAdminRoles, "OWNER"];
export const allRoles = [...csrRoles, ...customerRoles];

type Props = {
  allowedRoles: UserRole[];
  notAllowedComponent?: React.ReactNode;
  children: React.ReactNode;
};

function HasAccess({
  allowedRoles,
  notAllowedComponent,
  children,
}: Props): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();

  if (
    currentUserAccount?.role &&
    allowedRoles.includes(currentUserAccount.role)
  ) {
    return <>{children}</>;
  }

  if (notAllowedComponent) {
    return <>{notAllowedComponent}</>;
  }

  return <></>;
}

export default HasAccess;
