import {
  AccountHolder,
  MerchantAccount,
  OnboardingStatus,
} from "../services/accountService";

export function getEmptyAccountHolder(): AccountHolder {
  return {
    edition: "",
    jrId: "",
    adyenAccountHolderCode: "",
    legalEntityType: "",
    companyName: "",
    companyEmail: "",
    contactFirstName: "",
    contactLastName: "",
    country: "",
    adyenStatus: "",
    jrPayStatus: "",
    legalBusinessName: "",
    id: "",
    contactFullName: "",
    onboardingStatus: "In Progress",
    ratePlans: [],
    paymentProcessingState: false,
    payoutState: false,
    adyenMerchantAccount: "",
    adyenPayoutSchedule: {},
    accounts: [],
    shopperStatement: "",
    convFee: "",
    convFeeRate: "",
    techFeeRate: "",
    allowedPaymentMethods: [],
    merchantStatus: "",
    adyenCaptureDelay: "",
    accountHolderNotes: [],
    dailySubscription: null,
    monthlySubscription: null,
    useInterchangeRate: null,
    timeZoneCode: "",
    timeZone: {
      code: "",
      country: "",
      name: "",
    },
    useTransAmountLimit: false,
    transAmountLimit: "",
    useDailyTransAmountLimit: false,
    dailyTransAmountLimit: "",
    useTechFee: false,
    useConvFee: false,
    legalEntityId: "",
    merchantAccountId: "",
    inBalancePlatform: false,
    balancePlatformAccountHolderId: "",
    migrated: false,
    migrationDate: "",
    hasMonthlyFee: false,
    monthlyFee: "",
    stores: [],
    verificationErrors: "",
  };
}

export function getEmptyMerchantAccount(): MerchantAccount {
  return {
    merchantAccountId: "",
    merchantAccountName: "",
    merchantAccountType: "",
    merchantCurrency: "",
    processingFeeAccount: "",
    techFeeAccount: "",
    balancePlatformId: "",
    balancePlatformProcFeeAccount: "",
    balancePlatformTechFeeAcount: "",
    themeId: "",
  };
}

export function getOnboardingStatus(
  adyenStatus: string,
  jrPayStatus: string,
  migrated: boolean
): OnboardingStatus {
  if (adyenStatus === "Active" && jrPayStatus === "True") {
    return "Complete";
  } else if (adyenStatus === "Active" && jrPayStatus !== "True") {
    return migrated ? "KYC Needed" : "In Progress";
  }
  return "In Progress";
}

export function getOnboardingPageDefaultUrl() {
  return "onboarding#accountHolderCode=&status=complete&pageNumber=1&pageSize=100&sort=";
}
