type ReportTableColumn = {
  label: string;
  className?: string;
};

type Props = {
  columns: ReportTableColumn[];
  data: any[][];
  totalData?: any[];
  tableClasses?: string;
};

function ReportTable({ columns, data, totalData, tableClasses = "" }: Props) {
  return (
    <table className={`w-full border-collapse ${tableClasses}`}>
      <thead>
        <tr className="border border-gray-300 bg-blue-800 text-white print:text-black">
          {columns.map((column) => (
            <th
              key={column.label}
              className={"border border-gray-300 py-1 text-center font-medium"}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr key={i} className="border border-gray-300">
            {row.map((value, i) => (
              <td
                key={`${value}${i}`}
                className={`border border-gray-300 py-1 text-center ${
                  columns.at(i)?.className
                }`}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {totalData && totalData.length > 0 && (
        <tfoot>
          <tr className="border border-gray-300 bg-blue-800 text-white print:text-black">
            {totalData?.map((column, i) => (
              <th
                key={`${column}${i}`}
                className={`border border-gray-300 py-1 text-center font-medium ${
                  columns.at(i)?.className
                }`}
              >
                {column}
              </th>
            ))}
          </tr>
        </tfoot>
      )}
    </table>
  );
}

export default ReportTable;
