import InformationCircleIcon from "@heroicons/react/24/solid/InformationCircleIcon";

type Props = {
  message: string;
};

function Info({ message }: Props): JSX.Element {
  return (
    <div className="flex items-center gap-x-4 rounded-md border border-sky-600 bg-sky-100 p-2">
      <InformationCircleIcon
        className="h-10 w-10 text-sky-600"
        data-testid="info-circle-icon"
      />
      <span className="text-lg" role="alert">
        {message}
      </span>
    </div>
  );
}

export default Info;
