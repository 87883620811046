import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import PaymentIcon from "../../components/PaymentIcon";
import TableHeader from "../../components/TableHeader";
import { TransactionLedgerItem } from "../../services/transactionsService";
import { twJoin } from "tailwind-merge";

const columnHelper = createColumnHelper<TransactionLedgerItem>();
export const getTransactionsTableColumns = () => [
  columnHelper.accessor("pspReference", {
    header: () => <TableHeader text="PSP Reference" />,
    cell: (info) => {
      const originalReference = info.row.original.originalReference;
      const eventType = info.row.original.eventType;

      if (eventType === "Credit") {
        return <div>{info.getValue()}</div>;
      }
      return (
        <Link
          to={`/payments/${originalReference}`}
          className="truncate text-primary-700 hover:underline"
        >
          {info.getValue()}
        </Link>
      );
    },
    minSize: 50,
    size: 190,
  }),
  columnHelper.accessor("merchantReference", {
    header: () => <TableHeader text="Merchant Reference" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 180,
  }),
  columnHelper.accessor("eventType", {
    header: () => <TableHeader text="Transaction Type" />,
    cell: (info) => {
      const value = info.getValue().toLowerCase();
      return (
        <div className="flex w-[100px] capitalize">
          {value === "chargeback_reversed" ? "Chargeback" : value}
        </div>
      );
    },
    minSize: 50,
    size: 150,
  }),
  columnHelper.accessor("paymentLifeCycleStatus", {
    header: () => <TableHeader text="Status" />,
    cell: (info) => {
      const value = info.getValue().toLowerCase();
      return (
        <div
          className={twJoin(
            "flex w-[150px] items-center justify-center rounded-md border px-2 text-sm font-medium capitalize",
            (value === "authorised" ||
              value === "sentforsettle" ||
              value === "settled" ||
              value === "booked" ||
              value === "captured" ||
              value === "received") &&
              "border-green-200 bg-green-100 text-green-800",
            (value === "refund" ||
              value === "chargeback" ||
              value === "chargeback_reversed") &&
              "border-blue-200 bg-blue-100 text-blue-500",
            value === "cancellation" &&
              "border-gray-200 bg-gray-100 text-gray-700",
            (value === "failed" || value === "returned") &&
              "border-red-200 bg-red-50 text-red-700"
          )}
        >
          {value === "chargeback_reversed" ? "Chargeback Reversed" : value}
        </div>
      );
    },
    minSize: 50,
    size: 170,
  }),
  columnHelper.accessor("amountValue", {
    header: () => <TableHeader text="Amount" />,
    cell: (info) => {
      const currency = info.row.original.amountCurrency;

      return (
        <div className="truncate text-right">
          <span className="pl-1 font-bold">{info.getValue()}</span>
          <span> {currency}</span>
        </div>
      );
    },
    minSize: 120,
    size: 120,
  }),
  columnHelper.accessor("paymentMethod", {
    header: () => <TableHeader text="Payment Type" />,
    cell: (info) => {
      return (
        <div>
          <PaymentIcon type={info.getValue()} />
        </div>
      );
    },
    minSize: 50,
    size: 80,
  }),
  columnHelper.accessor("accountHolderCode", {
    header: () => <TableHeader text="Account Holder Code" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 150,
  }),
  columnHelper.accessor("transactionCreatedDate", {
    header: () => <TableHeader text="Transaction Date" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 180,
  }),
  columnHelper.accessor("timeZoneCode", {
    header: () => <TableHeader text="Timezone" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 180,
  }),
  columnHelper.accessor("location", {
    header: () => <TableHeader text="Location" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 80,
  }),
  columnHelper.accessor("merchantAccount", {
    header: () => <TableHeader text="Merchant Account" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 100,
  }),
  columnHelper.accessor("transactionOwnerName", {
    header: () => <TableHeader text="Card Holder/Account Holder Name" />,
    cell: (info) => {
      return <div className="truncate pl-2">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 160,
  }),
  columnHelper.accessor("transactionOwnerNumber", {
    header: () => <TableHeader text="Card/Account Number" />,
    cell: (info) => {
      return <div className="truncate pl-2">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 160,
  }),
];
