import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  getMonthlyFeesDue,
  GetMonthlyFeesDueBody,
  GetMonthlyFeesDueResponse,
} from "../../../services/transactionsService";

export default function useMonthyFeesDueQuery(
  body: GetMonthlyFeesDueBody,
  queryOptions?: UseQueryOptions<GetMonthlyFeesDueResponse, string>
) {
  return useQuery<GetMonthlyFeesDueResponse, string>(
    ["monthlyFeesDue", body],
    () => getMonthlyFeesDue(body),
    {
      ...queryOptions,
    }
  );
}
