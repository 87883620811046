import { useMutation } from "@tanstack/react-query";
import {
  createSweep,
  CreateSweepBody,
  CreateSweepResponde,
} from "../../../services/accountService";

export default function useCreateSweepMutation() {
  return useMutation<CreateSweepResponde, string, CreateSweepBody>((body) =>
    createSweep(body)
  );
}
