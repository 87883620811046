import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { useEffect } from "react";
import { PaginatorProps } from "../types";

function getAllPageSizeOptions(currentOptions: number[], otherOption: number) {
  const allPageSizeOptions = currentOptions.includes(otherOption)
    ? currentOptions
    : [...currentOptions, otherOption];
  return allPageSizeOptions.sort((a, b) => a - b);
}

const paginationButtonClasses = "h-6 w-6 cursor-pointer";
const paginationDisabledButtonClasses = "cursor-not-allowed text-gray-300";

function TablePaginator({
  currentPage,
  pageSize,
  totalPages,
  onPageChange,
  onPageSizeChange,
  activeNextPage,
  activePrevPage,
  pageSizeOptions = [20, 50, 100],
  id,
}: PaginatorProps & { id: string }): JSX.Element {
  useEffect(() => {
    if (!pageSizeOptions.includes(pageSize)) {
      console.warn(
        `pageSize prop should be one of the pageSizeOptions: ${pageSizeOptions} but it's current value is ${pageSize}.`
      );
    }
  }, [pageSizeOptions, pageSize]);

  const disabled = totalPages === 0;

  return (
    <div
      id={`${id}Paginator`}
      data-testid={`${id}Paginator`}
      className="flex h-auto flex-wrap items-center justify-end gap-x-1 rounded-b-lg bg-gray-50 py-1 pr-4 sm:gap-x-4"
    >
      <div className="flex items-center">
        <label htmlFor={`${id}PaginatorRowsPerPage`} className="pr-2">
          Rows per page:
        </label>
        <select
          id={`${id}PaginatorRowsPerPage`}
          className={twMerge(
            "rounded-md border border-gray-200 py-1 pl-2 pr-7 focus:border-primary-700/50 focus:ring-1 focus:ring-primary-700",
            disabled && "cursor-not-allowed"
          )}
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          disabled={disabled}
        >
          {getAllPageSizeOptions(pageSizeOptions, pageSize).map((op) => (
            <option key={op} value={op}>
              {op}
            </option>
          ))}
        </select>
      </div>
      <button
        disabled={!activePrevPage}
        onClick={() => onPageChange("first", totalPages)}
        title="First page"
      >
        <ChevronDoubleLeftIcon
          className={twMerge(
            paginationButtonClasses,
            !activePrevPage && paginationDisabledButtonClasses
          )}
        />
      </button>
      <button
        disabled={!activePrevPage}
        onClick={() => onPageChange("prev", totalPages)}
        title="Previous page"
      >
        <ChevronLeftIcon
          className={twMerge(
            paginationButtonClasses,
            !activePrevPage && paginationDisabledButtonClasses
          )}
        />
      </button>
      <div className="truncate">{`Page ${currentPage} of ${
        totalPages || 1
      }`}</div>
      <button
        disabled={!activeNextPage}
        onClick={() => onPageChange("next", totalPages)}
        title="Next page"
      >
        <ChevronRightIcon
          className={twMerge(
            paginationButtonClasses,
            !activeNextPage && paginationDisabledButtonClasses
          )}
        />
      </button>
      <button
        disabled={!activeNextPage}
        onClick={() => onPageChange("last", totalPages)}
        title="Last page"
      >
        <ChevronDoubleRightIcon
          className={twMerge(
            paginationButtonClasses,
            !activeNextPage && paginationDisabledButtonClasses
          )}
        />
      </button>
    </div>
  );
}

export default TablePaginator;
