import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateAccountHolderBalance,
  CreateAccountHolderBalanceResponse,
  createAccountHolderBalance,
} from "../../../services/accountService";

export default function useCreateAccountHolderBalanceMutation() {
  const queryClient = useQueryClient();

  return useMutation<
    CreateAccountHolderBalanceResponse,
    string,
    CreateAccountHolderBalance
  >((accountHolderData) => createAccountHolderBalance(accountHolderData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["accountHolders"]);
    },
  });
}
