import {
  AccountHolder,
  getBankAccountInfo,
  getBankAccountsByLegalEntity,
} from "../../../services/accountService";
import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

export type BankAccountItem = {
  id: string;
  label: string;
  last4: string;
};

export default function useNormalizedBankAccountsQuery(
  accountHolderCode: string,
  queryOptions?: UseQueryOptions<BankAccountItem[], string>
) {
  const queryClient = useQueryClient();
  return useQuery<BankAccountItem[], string>(
    ["bankAccounts", accountHolderCode],
    async () => {
      const accountConfigData = await queryClient.fetchQuery<AccountHolder>([
        "accountConfig",
        accountHolderCode,
      ]);

      if (accountConfigData?.inBalancePlatform) {
        const bankAccounts = await getBankAccountsByLegalEntity(
          accountConfigData.legalEntityId
        );
        return bankAccounts.map((x) => ({
          id: x.id,
          label: `Bank Account-${x.accountIdentifier.slice(-4)}`,
          last4: x.accountIdentifier.slice(-4),
        }));
      } else {
        const bankAccountInfo = await getBankAccountInfo(accountHolderCode);
        return bankAccountInfo.bankAccountDetails.map((x) => ({
          id: x.bankAccountUUID,
          label: x.accountType
            ? `${x.accountType} - ${x.accountNumber}`
            : x.accountNumber.toString(),
          last4: x.accountNumber.toString(),
        }));
      }
    },
    {
      ...queryOptions,
    }
  );
}
