import { createColumnHelper } from "@tanstack/react-table";
import TableHeader from "../../components/TableHeader";
import { MerchantAccount } from "../../services/accountService";

const columnHelper = createColumnHelper<MerchantAccount>();
export const getMerchantAccountTableColumns = () => [
  columnHelper.accessor("merchantAccountType", {
    header: () => <TableHeader text="Merchant Account Type" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 200,
  }),
  columnHelper.accessor("merchantAccountName", {
    header: () => <TableHeader text="Merchant Account Name" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 100,
    size: 300,
  }),
  columnHelper.accessor("merchantCurrency", {
    header: () => <TableHeader text="Currency" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 100,
    size: 100,
  }),
  columnHelper.accessor("merchantAccountId", {
    header: () => <TableHeader text="Merchant Account Id" />,
    cell: (info) => {
      return <div className="truncate pl-2">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 200,
  }),
  columnHelper.accessor("processingFeeAccount", {
    header: () => <TableHeader text="Processing Fee Account" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 200,
  }),
  columnHelper.accessor("techFeeAccount", {
    header: () => <TableHeader text="Tech Fee Account" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 50,
    size: 200,
  }),
];
