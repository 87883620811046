import { twJoin } from "tailwind-merge";
import { useState } from "react";
import HasAccess, { csrRoles } from "../../../components/HasAccess";
import Popover from "../../../components/Popover";
import useCurrentUserAccount from "../../../hooks/data/queries/useCurrentUserAccount";
import { TransactionLifecycle as TransLifecycle } from "../../../services/transactionsService";

function MainAmountCell({
  lifecycle,
}: {
  lifecycle: TransLifecycle;
}): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const [amountEl, setAmountEl] = useState<HTMLElement | null>(null);
  const [showDetail, setShowDetail] = useState<boolean>(false);

  return (
    <>
      <span
        ref={setAmountEl}
        onMouseEnter={() => setShowDetail(true)}
        onMouseLeave={() => setShowDetail(false)}
        className={twJoin(
          "inline-block truncate",
          currentUserAccount?.type === "CSR" &&
            "cursor-pointer text-primary underline"
        )}
      >
        {lifecycle.journalType !== "Settled" &&
          `${lifecycle.mainCurrency} ${lifecycle.mainAmount}`}
      </span>
      <HasAccess allowedRoles={[...csrRoles]}>
        <Popover open={showDetail} placement="bottom" referenceEl={amountEl}>
          <div className="grid w-auto grid-cols-[2fr_1fr] grid-rows-6 gap-x-10 gap-y-2 rounded-lg bg-white p-5 shadow-lg">
            <span className="font-semibold">Payable Amount:</span>
            <span className="flex w-full justify-center">
              {lifecycle.payableAmount
                ? `${lifecycle.settlementCurrency} ${lifecycle.payableAmount}`
                : ""}
            </span>
            <span className="font-semibold">Commission Amount:</span>
            <span className="flex w-full justify-center">
              {lifecycle.commissionAmount
                ? `${lifecycle.settlementCurrency} ${lifecycle.commissionAmount}`
                : ""}
            </span>
            <span className="font-semibold">MarkUp:</span>
            <span className="flex w-full justify-center">
              {lifecycle.markupAmount
                ? `${lifecycle.settlementCurrency} ${lifecycle.markupAmount}`
                : ""}
            </span>
            <span className="font-semibold">Scheme Fees:</span>
            <span className="flex w-full justify-center">
              {lifecycle.schemeFees
                ? `${lifecycle.settlementCurrency} ${lifecycle.schemeFees}`
                : ""}
            </span>
            <span className="font-semibold">Interchange Fees:</span>
            <span className="flex w-full justify-center">
              {lifecycle.interchangeFee
                ? `${lifecycle.settlementCurrency} ${lifecycle.interchangeFee}`
                : ""}
            </span>
            <span className="font-semibold">Processing Fees:</span>
            <span className="flex w-full justify-center">
              {lifecycle.processingFee
                ? `${lifecycle.processingFeeCurrency} ${lifecycle.processingFee}`
                : ""}
            </span>
          </div>
        </Popover>
      </HasAccess>
    </>
  );
}

export default MainAmountCell;
