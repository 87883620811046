import { createColumnHelper } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import Skeleton from "../../components/Skeleton";
import Table from "../../components/Table";
import TableHeader from "../../components/TableHeader";
import { TransactionEvent } from "../../services/transactionsService";
import { longDateFormat } from "../../utils/dateTime";
import { sortDateTimes, sortNumbers } from "../../utils/sorting";
import Panel from "../customerSettings/Panel";

const columnHelper = createColumnHelper<TransactionEvent>();
const columns = [
  columnHelper.accessor("eventType", {
    header: () => <TableHeader text="Type" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 200,
    minSize: 100,
  }),
  columnHelper.accessor("reason", {
    header: () => <TableHeader text="Reason" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 400,
    minSize: 200,
  }),
  columnHelper.accessor("pspReference", {
    header: () => <TableHeader text="PSP Reference" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 180,
    minSize: 100,
  }),
  columnHelper.accessor("amountValue", {
    header: () => <TableHeader text="Amount" />,
    cell: (info) => {
      const currency = info.row.original.amountCurrency;
      return <div className="truncate">{`${currency} ${info.getValue()}`}</div>;
    },
    size: 120,
    minSize: 100,
    sortingFn: (rowA, rowB, columnId) => {
      const rowAAmount = rowA.original.amountValueRaw;
      const rowBAmount = rowB.original.amountValueRaw;
      return sortNumbers(rowAAmount, rowBAmount);
    },
  }),
  columnHelper.accessor("merchantReference", {
    header: () => <TableHeader text="Merchant Reference" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 200,
    minSize: 150,
  }),
  columnHelper.accessor("transactionCreatedDate", {
    header: () => <TableHeader text="Creation Date" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    size: 200,
    minSize: 100,
    sortingFn: (rowA, rowB, columnId) => {
      const rowADate = DateTime.fromFormat(
        rowA.getValue(columnId),
        longDateFormat
      );
      const rowBDate = DateTime.fromFormat(
        rowB.getValue(columnId),
        longDateFormat
      );

      return sortDateTimes(rowADate, rowBDate);
    },
  }),
];

type Props = {
  transactionEvents: TransactionEvent[];
  timeZoneCode: string;
  isLoading?: boolean;
};

function TransactionsEvents({
  transactionEvents,
  timeZoneCode,
  isLoading = false,
}: Props): JSX.Element {
  function getContent(): ReactNode {
    if (isLoading) {
      return (
        <div className="flex w-full gap-x-20">
          <Skeleton className="w-32" />
          <Skeleton className="w-32" />
          <Skeleton className="w-32" />
          <Skeleton className="w-96" />
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-y-2">
        <div>All events are in {timeZoneCode} Time zone.</div>
        <Table
          id="events"
          columns={columns}
          data={transactionEvents}
          isLoading={isLoading}
          sorting={[
            {
              id: "transactionCreatedDate",
              desc: true,
            },
          ]}
          showPaginator={false}
          noRowsText="There are no events associated with this payment."
          className="overflow-y-auto"
        />
      </div>
    );
  }

  return (
    <Panel className="my-2 w-full rounded-lg border border-gray-200 shadow-sm">
      <Panel.Header title="Payment Events" className="rounded-b-none" />
      <Panel.Content>
        <div className="flex flex-col gap-x-4 gap-y-4">{getContent()}</div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionsEvents;
