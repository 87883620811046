import { useState, useEffect } from "react";
import Switch from "../../components/Switch";
import useUpdateEmailSubscriptions from "../../hooks/data/mutations/useUpdateEmailSubscriptions";
import { useSnackbar } from "../../components/Snackbar";
import Skeleton from "../../components/Skeleton";
import Panel from "./Panel";
import EditControls from "./EditControls";

type Props = {
  accountHolderCode: string;
  dailySubscription: boolean;
  monthlySubscription: boolean;
  isLoading: boolean;
};

function EmailSubscription({
  accountHolderCode,
  dailySubscription,
  monthlySubscription,
  isLoading,
}: Props): JSX.Element {
  const {
    mutate: updateEmailSubscriptions,
    isLoading: isUpdatingEmailSubscriptions,
  } = useUpdateEmailSubscriptions();
  const [isEditing, setIsEditing] = useState(false);
  const [dailyEnabled, setDailyEnabled] = useState(dailySubscription);
  const [monthlyEnabled, setMonthlyEnabled] = useState(monthlySubscription);

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  useEffect(() => {
    setDailyEnabled(dailySubscription);
  }, [dailySubscription]);

  useEffect(() => {
    setMonthlyEnabled(monthlySubscription);
  }, [monthlySubscription]);

  function handleUpdateEmailSubscriptions() {
    updateEmailSubscriptions(
      {
        accountHolderCode,
        daily: dailyEnabled,
        monthly: monthlyEnabled,
      },
      {
        onSuccess() {
          openSnackbar("Email subscriptions were successfully updated", {
            type: "success",
          });
          setIsEditing(false);
        },
        onError() {
          openSnackbar(
            "Failed to update email subscriptions. Please try again.",
            {
              type: "error",
            }
          );
        },
      }
    );
  }

  return (
    <Panel>
      <Panel.Header title="Email Subscriptions">
        <EditControls
          customId="editEmailSubscriptionsButton"
          isEditing={isEditing}
          isSaving={isUpdatingEmailSubscriptions}
          editButtonProps={{
            disabled: isLoading,
            onClick: () => {
              setIsEditing(true);
            },
          }}
          saveButtonProps={{
            onClick: () => handleUpdateEmailSubscriptions(),
          }}
          cancelButtonProps={{
            onClick: () => {
              setIsEditing(false);
              setDailyEnabled(dailySubscription);
              setMonthlyEnabled(monthlySubscription);
            },
          }}
        />
      </Panel.Header>
      <Panel.Content>
        <div className="flex flex-col gap-y-4">
          {isLoading ? (
            <>
              <Skeleton className="w-32" />
              <Skeleton className="w-32" />
            </>
          ) : (
            <>
              <Switch
                checked={dailyEnabled}
                onChange={() => setDailyEnabled((p) => !p)}
                disabled={!isEditing}
                label="Daily"
                labelProps={{
                  className: "font-medium w-10 mr-20",
                }}
              />
              <Switch
                checked={monthlyEnabled}
                onChange={() => setMonthlyEnabled((p) => !p)}
                disabled={!isEditing}
                label="Monthly"
                labelProps={{
                  className: "font-medium w-10 mr-20",
                }}
              />
            </>
          )}
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default EmailSubscription;
