import DetailField from "../../components/DetailField";
import { TransactionDetail } from "../../services/transactionsService";
import Panel from "../customerSettings/Panel";

export type Props = {
  transactionDetail?: TransactionDetail | null;
  isLoading?: boolean;
};

function TransactionShopperInfo({
  transactionDetail,
  isLoading = false,
}: Props): JSX.Element {
  function getFormattedAddress(): string {
    if (transactionDetail) {
      const addressParts = [
        transactionDetail.billingAddressHouseNumberOrName,
        transactionDetail.billingAddressStreet,
        transactionDetail.billingAddressCity,
        transactionDetail.billingAddressStateOrProvince,
        transactionDetail.billingAddressPostalCode,
        transactionDetail.billingAddressCountry,
      ];
      return addressParts.join(", ");
    }
    return "";
  }

  return (
    <Panel className="my-3 w-full rounded-lg border border-gray-200 shadow-sm md:w-[49%]">
      <Panel.Header title="Shopper Information" className="rounded-b-none" />
      <Panel.Content>
        <div className="flex flex-col gap-x-4 gap-y-4 p-4 md:p-2">
          <DetailField
            label="Shopper Reference"
            isLoading={isLoading}
            variant="start"
          >
            {transactionDetail?.shopperReference}
          </DetailField>
          <DetailField label="Shopper IP" isLoading={isLoading} variant="start">
            {transactionDetail?.shopperIp}
          </DetailField>
          <DetailField
            label="Shopper Country"
            isLoading={isLoading}
            variant="start"
          >
            {transactionDetail?.shopperCountry}
          </DetailField>
          <DetailField
            label="Shopper Address"
            isLoading={isLoading}
            variant="start"
          >
            {getFormattedAddress()}
          </DetailField>
          <DetailField
            label="Shopper Email"
            isLoading={isLoading}
            variant="start"
          >
            {transactionDetail?.shopperEmail}
          </DetailField>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionShopperInfo;
