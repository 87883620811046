import { useQuery } from "@tanstack/react-query";
import {
  GetReportBody,
  getMonthlyReport,
  MonthlyReportNoContentResponse,
} from "../../../services/transactionsService";

export default function useMonthlyReportQuery(
  monthlyReportBody: GetReportBody
) {
  return useQuery<MonthlyReportNoContentResponse, string>(
    ["monthlyReport", monthlyReportBody],
    () => getMonthlyReport(monthlyReportBody)
  );
}
