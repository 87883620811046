import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
  GetDepositSummaryReportResponse,
  GetReportBody,
  getDepositSummaryReport,
} from "../../../services/transactionsService";

export default function useDepositSummaryReportQuery(
  body: GetReportBody,
  queryOptions?: UseQueryOptions<GetDepositSummaryReportResponse, string>
) {
  return useQuery<GetDepositSummaryReportResponse, string>(
    ["depositSummaryReport", body],
    () => getDepositSummaryReport(body),
    queryOptions
  );
}
