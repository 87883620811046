// @ts-ignore
import { Parser } from "@json2csv/plainjs";

export function generateCsvData<T extends {}>(
  data: T[],
  fields: { label: string; value: string }[]
) {
  const parser = new Parser({ fields });
  return parser.parse(data);
}

export function downloadCsvFile(data: string, fileName: string) {
  const blob = new Blob([data], { type: "application/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.download = fileName;
  a.href = url;

  document.body.appendChild(a);

  a.click();
  a.remove();

  URL.revokeObjectURL(url);
}
