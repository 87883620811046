import { createContext, useContext, useState, useRef } from "react";
import { Transition } from "@headlessui/react";
import { twJoin } from "tailwind-merge";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";

type SnackbarContextValue = {
  openSnackbar: (text: string, options: SnackbarOptions) => void;
  closeSnackbar: (index: number) => void;
  clearSnackbars: () => void;
};

export const SnackbarContext = createContext<SnackbarContextValue>({
  openSnackbar: () => {},
  closeSnackbar: () => {},
  clearSnackbars: () => {},
});

type SnackbarType = "success" | "error";
type SnackbarPosition = "top" | "bottom";

type Props = {
  children: React.ReactNode;
};

type SnackbarOptions = {
  type?: SnackbarType;
  position?: SnackbarPosition;
  duration?: number;
  classes?: string;
};

type SnackbarInstance = {
  id: number;
  isOpen: boolean;
  text: string;
  options: SnackbarOptions;
  timer?: NodeJS.Timeout;
};

export function Snackbar({ children }: Props): JSX.Element {
  const [instances, setInstances] = useState<SnackbarInstance[]>([]);
  const idRef = useRef(0);

  function openSnackbar(text: string, options: SnackbarOptions) {
    const id = idRef.current;

    const newInstance: SnackbarInstance = {
      id,
      isOpen: true,
      text,
      options,
      timer: setTimeout(() => closeSnackbar(id), options.duration),
    };
    setInstances((prevInstances) => [newInstance, ...prevInstances]);
    idRef.current++;
  }

  function closeSnackbar(id: number) {
    const instance = instances.find((i) => i.id === id);

    if (instance) {
      clearTimeout(instance.timer);
    }

    setInstances((prevInstances) => {
      return prevInstances.map((instance) =>
        id === instance.id ? { ...instance, isOpen: false } : instance
      );
    });
  }

  function clearSnackbars() {
    setInstances([]);
  }

  return (
    <SnackbarContext.Provider
      value={{ openSnackbar, closeSnackbar, clearSnackbars }}
    >
      <>
        {children}
        <div className="pointer-events-none fixed top-0 z-[9999] flex h-full w-full flex-col items-center gap-y-2 p-4 pt-14">
          {instances.map((instance, i) => (
            <Transition
              show={instance.isOpen}
              key={i}
              enter="transition-all duration-250"
              enterFrom="opacity-0 -translate-y-6 scale-75"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="transition-all duration-250"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 -translate-y-6 scale-75"
            >
              <span
                key={i}
                className={twJoin(
                  "pointer-events-auto flex items-center gap-x-2 rounded-md p-2 text-white shadow-lg transition-opacity duration-500",
                  instance.options.type === "success" && "bg-success",
                  instance.options.type === "error" && "bg-error",
                  instance.options.type === undefined && "bg-slate-700",
                  instance.options?.classes
                )}
              >
                <span className="flex items-center justify-between gap-x-3">
                  {instance.options.type === "success" ? (
                    <CheckCircleIcon className="h-5 w-5 flex-none" />
                  ) : (
                    <XCircleIcon className="h-5 w-5 flex-none" />
                  )}
                  {instance.text}
                  <XMarkIcon
                    onClick={() => closeSnackbar(instance.id)}
                    className="h-5 w-5 flex-none cursor-pointer"
                  />
                </span>
              </span>
            </Transition>
          ))}
        </div>
      </>
    </SnackbarContext.Provider>
  );
}

export function useSnackbar(options?: SnackbarOptions) {
  const {
    openSnackbar: originalOpenSnackbar,
    closeSnackbar,
    clearSnackbars,
  } = useContext(SnackbarContext);

  function openSnackbar(text: string, newOptions: SnackbarOptions) {
    originalOpenSnackbar(text, {
      ...options,
      ...newOptions,
    });
  }

  return {
    openSnackbar,
    closeSnackbar,
    clearSnackbars,
  };
}

export default Snackbar;
