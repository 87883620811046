import {
  getAccountConfig,
  AccountHolder,
} from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useAccountConfigQuery(
  accountHolderCode: string = "",
  queryOptions?: UseQueryOptions<AccountHolder, string>
) {
  return useQuery<AccountHolder, string>(
    ["accountConfig", accountHolderCode],
    () => getAccountConfig(accountHolderCode),
    {
      ...queryOptions,
    }
  );
}
