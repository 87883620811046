import { useParams } from "react-router-dom";
import useTaxFormQuery from "../../hooks/data/queries/useTaxFormQuery";
import Error from "../../components/Error";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";

type ReportParams = {
  accountHolderCode: string;
  reportDate: string;
};

function TenNinetyNineKReportDetail(): JSX.Element {
  const { accountHolderCode, reportDate } = useParams<ReportParams>();
  const { data, isLoading, isError, error } = useTaxFormQuery({
    accountHolderCode: accountHolderCode || "",
    reportYear: Number(reportDate),
  });

  if (isError) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Error message={error} />
        <Button onClick={() => window.close()}>Close this window</Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Spinner className="h-12 w-12 text-primary-700" />
        <div className="text-xl">Loading 1099-K report...</div>
      </div>
    );
  }

  return (
    <div className="h-screen w-full">
      <iframe
        src={`data:application/pdf;base64,${data.replaceAll('"', "")}`}
        className="h-full w-full"
        title="1099-K"
      />
    </div>
  );
}

export default TenNinetyNineKReportDetail;
