import { Listbox, Popover } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import { TransactionStatusType } from "../../../services/transactionsService";
import { formatEventType } from "../../../utils/transaction";
import FilterButton from "./FilterButton";
import FilterOverlay from "./FilterOverlay";

type StatusItem = { value: TransactionStatusType; label: string };

export type Props = {
  value: TransactionStatusType[];
  statusList: { value: string; label: string }[];
  onApply: (value: TransactionStatusType[]) => void;
};

function StatusFilter({ value, statusList, onApply }: Props): JSX.Element {
  const [selectedStatus, setSelectedStatus] = useState<StatusItem[]>([]);
  const isFilterApplied = value.length > 0;
  const selectedStatusValue = selectedStatus.map((s) => s.value);
  const selectedStatusLabel = value.sort().map(formatEventType).join(", ");

  useEffect(() => {
    setSelectedStatus(
      value.map((x) => ({
        value: x,
        label: formatEventType(x),
      }))
    );
  }, [value]);

  function handleClear() {
    onApply([]);
    setSelectedStatus([]);
  }

  function isStatusSelected(status: StatusItem) {
    return selectedStatus.find((s) => s.value === status.value);
  }

  function compareStatus(a: StatusItem, b: StatusItem) {
    return a.value === b.value;
  }

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button as="div">
            <FilterButton
              isFilterApplied={isFilterApplied}
              onClose={handleClear}
            >
              {isFilterApplied ? `Status: ${selectedStatusLabel}` : "Status"}
            </FilterButton>
          </Popover.Button>
          <FilterOverlay>
            <div className="flex flex-col gap-y-5 p-4">
              <h3 className="font-medium">Status</h3>
              <div className="flex w-full">
                <Listbox
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  by={compareStatus}
                  multiple
                >
                  <div className="relative">
                    <Listbox.Button className="relative h-8 w-64 cursor-default rounded-md border border-gray-300 bg-white py-1 pl-2 pr-10 text-left shadow-sm focus:border-primary-700/50 focus:ring-1 focus:ring-primary-700 focus-visible:outline-none">
                      <span className="block truncate">
                        {selectedStatus.map((p) => p.label).join(", ")}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ChevronDownIcon
                          className="h-4 w-4 text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {(statusList as StatusItem[]).map((status, statusIdx) => (
                        <Listbox.Option
                          key={statusIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${
                              active && "bg-gray-100"
                            }`
                          }
                          value={status}
                        >
                          <>
                            <span
                              className={`block truncate ${
                                isStatusSelected(status)
                                  ? "font-medium"
                                  : "font-normal"
                              }`}
                            >
                              {status.label}
                            </span>
                            {isStatusSelected(status) && (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <CheckIcon
                                  className="h-5 w-5 text-primary-700"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </Listbox>
              </div>
              <div className="flex justify-between">
                <Button
                  variant="secondary"
                  size="sm"
                  disabled={value.length === 0}
                  onClick={() => {
                    close();
                    handleClear();
                  }}
                  className="py-1 px-2 text-sm"
                >
                  Clear
                </Button>
                <Button
                  size="sm"
                  disabled={value.toString() === selectedStatusValue.toString()}
                  onClick={() => {
                    onApply(selectedStatusValue);
                    close();
                  }}
                  className="py-1 px-2 text-sm"
                >
                  Apply
                </Button>
              </div>
            </div>
          </FilterOverlay>
        </>
      )}
    </Popover>
  );
}

export default StatusFilter;
