import { twJoin } from "tailwind-merge";

type Props = {
  label: string;
  isLoading?: boolean;
  variant?: "between" | "start";
  children: React.ReactNode;
};

function DetailField({
  label,
  isLoading = false,
  variant = "between",
  children,
}: Props): JSX.Element {
  return (
    <div
      className={twJoin(
        "flex w-full items-center justify-between gap-x-2",
        variant === "start" && "md:justify-start md:gap-x-20"
      )}
    >
      <span
        className={twJoin("font-medium", variant === "start" && "md:w-1/4")}
      >
        {label}
      </span>
      <span
        className={twJoin(
          "text-right",
          variant === "start" && "md:flex-1 md:text-left"
        )}
      >
        {isLoading ? (
          <div
            className="h-5 w-24 animate-pulse rounded-md bg-gray-200"
            data-testid="skeleton"
          />
        ) : (
          children
        )}
      </span>
    </div>
  );
}

export default DetailField;
