import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  AccountHoldersFilters,
  FilterStatusType,
} from "../../services/accountService";
import onboardingStatus from "../../data/onboardingStatus.json";

type Props = {
  filter: AccountHoldersFilters;
  onFilterChange: (filter: AccountHoldersFilters) => void;
};

function AccountHoldersTableFilter({
  filter,
  onFilterChange,
}: Props): JSX.Element {
  const [accountHolderCode, setAccountHolderCode] = useState("");
  const [status, setStatus] = useState("inprogress");
  const businessRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    businessRef.current?.focus();
  }, []);

  useEffect(() => {
    setAccountHolderCode(filter.accountHolderCode);
    setStatus(filter.status);
  }, [filter]);

  function handleSearch() {
    onFilterChange({
      accountHolderCode,
      status: status as FilterStatusType,
    });
  }

  return (
    <span className="flex flex-wrap gap-2 gap-y-8">
      <Input
        id="searchForAccountHolderCode"
        label="Search"
        type="text"
        value={accountHolderCode}
        onChange={(e) => setAccountHolderCode(e.target?.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        ref={businessRef}
        containerProps={{
          className: "w-[220px]",
        }}
      />
      <Select
        id="searchForStatus"
        label="Onboarding Status"
        required
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        options={onboardingStatus}
        containerProps={{
          className: "w-[170px]",
        }}
      />
      <Button
        onClick={() => handleSearch()}
        size="sm"
        icon={<MagnifyingGlassIcon />}
      >
        Search
      </Button>
    </span>
  );
}

export default AccountHoldersTableFilter;
