import React, { MutableRefObject, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  id?: string;
  label: string;
  errorMessage?: string;
  containerProps?: React.HTMLAttributes<HTMLElement>;
  errorMessageContainerProps?: React.HTMLAttributes<HTMLElement>;
};

const Input = React.forwardRef<
  HTMLTextAreaElement,
  Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>
>(
  (
    {
      id = "",
      label = "",
      errorMessage = "",
      containerProps = {},
      errorMessageContainerProps = {},
      ...inputProps
    },
    ref
  ): JSX.Element => {
    const { className: containerClassName = "", ...otherContainerProps } =
      containerProps;
    return (
      <span
        {...otherContainerProps}
        className={twMerge("relative flex flex-col", containerClassName)}
      >
        <textarea
          ref={ref}
          id={id}
          placeholder={label}
          {...inputProps}
          className={twMerge(
            "peer rounded-md border border-gray-300 px-2 py-1 placeholder-transparent shadow-sm focus:border-primary-700/50 focus:ring-1 focus:ring-primary-700 focus-visible:outline-none",
            errorMessage && "border-1 border-error-300",
            inputProps.disabled &&
              "cursor-not-allowed bg-gray-50 text-gray-400",
            inputProps.className
          )}
        />
        {label && (
          <label
            onClick={(e) => {
              if (ref) {
                (ref as MutableRefObject<HTMLTextAreaElement>).current?.focus();
                return;
              }
            }}
            htmlFor={id}
            className={twMerge(
              "absolute -top-6 left-2 cursor-text text-sm font-medium text-gray-800 transition-all peer-placeholder-shown:top-1.5 peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-500 peer-focus:-top-6 peer-focus:font-medium peer-focus:text-gray-800 peer-disabled:-top-6 peer-disabled:font-medium peer-disabled:text-gray-800"
            )}
          >
            {label}
          </label>
        )}
        <span
          data-testid={`error-${id}`}
          {...errorMessageContainerProps}
          className={twMerge(
            "absolute top-9 left-2 mb-3 inline-block text-sm font-medium text-error-300",
            errorMessageContainerProps.className
          )}
        >
          {errorMessage}
        </span>
      </span>
    );
  }
);

export default Input;
