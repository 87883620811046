import { createColumnHelper } from "@tanstack/react-table";
import TableHeader from "../../components/TableHeader";
import { WebhookConfiguration } from "../../services/accountService";

const columnHelper = createColumnHelper<WebhookConfiguration>();
export const getWebhooksConfigurationsTableColumns = () => [
  columnHelper.accessor("edition", {
    header: () => <TableHeader text="Edition" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 100,
    size: 200,
  }),
  columnHelper.accessor("environment", {
    header: () => <TableHeader text="Environment" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 100,
    size: 200,
  }),
  columnHelper.accessor("url", {
    header: () => <TableHeader text="URL" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 200,
    size: 600,
  }),
];
