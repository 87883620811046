import { useEffect, useRef } from "react";

export default function useClickOutside(callback: () => void) {
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      if (elRef.current && !elRef.current.contains(e.target as Node)) {
        callback();
      }
    }

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [elRef, callback]);

  return elRef;
}
