import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateAccountHolderNoteBodyWithoutUserAndEmail,
  createAccountHolderNotes,
} from "../../../services/accountService";

export default function useCreateAccountHolderNotesMutation() {
  const queryClient = useQueryClient();

  return useMutation<
    string,
    Error,
    CreateAccountHolderNoteBodyWithoutUserAndEmail
  >((body) => createAccountHolderNotes(body), {
    onSuccess() {
      queryClient.invalidateQueries(["accountHolderNotes"]);
    },
  });
}
