import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useRef, useEffect } from "react";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";

type Props = {
  onNoteSaved: (text: string) => void;
  onNoteCanceled: () => void;
  isSavingNote: boolean;
};

function AddNoteCell({
  onNoteSaved,
  onNoteCanceled,
  isSavingNote,
}: Props): JSX.Element {
  const [text, setText] = useState<string>("");
  const textRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    textRef.current?.focus();
  }, []);

  return (
    <div className="flex gap-x-1">
      <textarea
        ref={textRef}
        className="w-full rounded-md border border-gray-300 shadow-sm focus:border-primary-700/50 focus:ring-1 focus:ring-primary-700 focus-visible:outline-none"
        rows={1}
        maxLength={500}
        onChange={(e) => setText(e.target.value)}
        disabled={isSavingNote}
      />
      <div className="flex items-center gap-x-1">
        <Button
          size="sm"
          variant="secondary"
          className="border-success"
          disabled={text === ""}
          onClick={() => onNoteSaved(text)}
          icon={
            isSavingNote ? (
              <Spinner className="text-gray-400" />
            ) : (
              <CheckIcon className="stroke-[3] text-success" />
            )
          }
        />
        <Button
          size="sm"
          variant="secondary"
          onClick={onNoteCanceled}
          disabled={isSavingNote}
          className="border-error"
          icon={<XMarkIcon className="stroke-[3] text-error" />}
        />
      </div>
    </div>
  );
}

export default AddNoteCell;
