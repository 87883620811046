import { twMerge } from "tailwind-merge";

type Props = {
  label: string;
};

function RadioButton({
  label = "",
  ...inputProps
}: Props & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element {
  return (
    <div className="flex items-center gap-x-2">
      <input
        {...inputProps}
        type="radio"
        id={inputProps.id}
        name={inputProps.name}
        value={inputProps.value}
        checked={inputProps.checked}
        disabled={inputProps.disabled}
        className={twMerge(
          "text-primary-700 focus:ring-primary-700",
          inputProps.disabled && "cursor-not-allowed",
          inputProps.className
        )}
      />
      <label htmlFor={inputProps.id}>{label}</label>
    </div>
  );
}

export default RadioButton;
