import useAccountConfigQuery from "../../hooks/data/queries/useAccountConfigQuery";
import AdyenConfigurations from "./AdyenConfigurations";
import CompanyContact from "./CompanyContact";
import Locations from "./Locations";
import MerchantConfigurations from "./MerchantConfigurations";
import MerchantUsers from "./MerchantUsers";
import UserNotes from "./UserNotes";
import RatePlans from "./RatePlans";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import { createContext, useState } from "react";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import HasAccess, { csrRoles, kycRoles } from "../../components/HasAccess";
import { useParams } from "react-router-dom";
import useOnboardingUrlQuery from "../../hooks/data/queries/useOnboardingUrlQuery";
import EmailSubscription from "./EmailSubscription";
import useCreateAccountHolderNotesMutation from "../../hooks/data/mutations/useCreateAccountHolderNotesMutation";
import useOnboardingUrlBalanceQuery from "../../hooks/data/queries/useOnboardingUrlBalanceQuery";
import useThemeIdByMerchantAccountId from "../../hooks/data/queries/useThemeIdByMerchantAccountId";
import AccountCreditModal from "./AccountCreditModal";

type CustomerSettingsRouteParams = {
  accountHolderCode: string;
};

type CustomerSettingsContextValue = {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

export const CustomerSettingsContext =
  createContext<CustomerSettingsContextValue>({
    isEditing: false,
    setIsEditing: () => {},
  });

function CustomerSettings(): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const { accountHolderCode: accountHolderCodeRouteParam } =
    useParams<CustomerSettingsRouteParams>();

  const { data: currentUserAccount } = useCurrentUserAccount();

  const accountHolderCode = accountHolderCodeRouteParam
    ? accountHolderCodeRouteParam
    : currentUserAccount?.accountHolderCode ?? "";

  const {
    data: accountConfigData,
    isLoading: accountConfigIsLoading,
    isSuccess: accountConfigDataSuccess,
    error: accountConfigDataError,
  } = useAccountConfigQuery(accountHolderCode, {
    enabled: Boolean(accountHolderCode),
  });

  const inBalance = Boolean(accountConfigData?.inBalancePlatform ?? false);
  const isMigrated = Boolean(accountConfigData?.migrated ?? false);

  // Read Feature Flag (REACT_APP_FF_DISPLAY_ACCOUNT_CREDIT)
  const displayAccountCredit: boolean =
    (process.env.REACT_APP_FF_DISPLAY_ACCOUNT_CREDIT?.toLowerCase() ??
      "true") === "true";

  const { data: themeIdData } = useThemeIdByMerchantAccountId(
    accountConfigData?.merchantAccountId ?? "",
    {
      enabled: inBalance,
    }
  );

  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  const [onboardingUrlQueryEnabled, setOnboardingUrlQueryEnabled] =
    useState<boolean>(false);

  const {
    isFetching: onboardingUrlClassicIsFetching,
    isSuccess: onboardingUrlClassicIsSuccess,
    isError: onboardingUrlClassicIsError,
  } = useOnboardingUrlQuery(
    {
      accountHolderCode: accountHolderCode,
      returnUrl:
        (process.env.REACT_APP_BASE_URI || window.location.origin) +
        "customer-settings/" +
        accountHolderCode,
    },
    {
      enabled: onboardingUrlQueryEnabled && !inBalance && !isMigrated,
      async onSuccess({ resultCode, redirectUrl }) {
        if (resultCode === "Success") {
          await createNote({
            accountHolderCode: accountHolderCode,
            freeText: "Client KYC information accessed",
          });

          window.location.href = redirectUrl;
        }
      },
      onError() {
        setOnboardingUrlQueryEnabled(false);
      },
    }
  );

  const {
    isFetching: onboardingUrlBalanceIsFetching,
    isSuccess: onboardingUrlBalanceIsSuccess,
    isError: onboardingUrlBalanceIsError,
  } = useOnboardingUrlBalanceQuery(
    {
      returnUrl:
        (process.env.REACT_APP_BASE_URI || window.location.origin) +
        "customer-settings/" +
        accountHolderCode,
      legalEntityId: accountConfigData?.legalEntityId ?? "",
      themeId: themeIdData || "",
    },
    {
      enabled: onboardingUrlQueryEnabled && (inBalance || isMigrated),
      async onSuccess({ redirectUrl }) {
        await createNote({
          accountHolderCode: accountHolderCode,
          freeText: "Client KYC information accessed",
        });

        window.location.href = redirectUrl;
      },
      onError() {
        setOnboardingUrlQueryEnabled(false);
      },
    }
  );

  const [accountCreditModalIsOpen, setAccountCreditModalIsOpen] =
    useState(false);

  if (!accountConfigDataSuccess && !accountConfigIsLoading) {
    return <Error message={accountConfigDataError || ""} />;
  }

  return (
    <CustomerSettingsContext.Provider value={{ isEditing, setIsEditing }}>
      <div className="flex flex-col px-6">
        <div>
          <h1 className="mb-1 text-3xl">Client Settings</h1>
        </div>
        <div className="flex items-center justify-between">
          <h2 className="mb-4 text-2xl text-slate-500">
            {accountConfigData?.companyName}
          </h2>
          {accountConfigData?.onboardingStatus !== "In Progress" && (
            <>
              <div className="flex gap-x-4">
                {inBalance && displayAccountCredit && (
                  <HasAccess allowedRoles={[...csrRoles]}>
                    <Button
                      onClick={() => setAccountCreditModalIsOpen(true)}
                      size="sm"
                    >
                      Account Credit
                    </Button>
                  </HasAccess>
                )}
                <HasAccess allowedRoles={[...kycRoles]}>
                  <Button
                    custom-id="updateKycButton"
                    onClick={() => setOnboardingUrlQueryEnabled(true)}
                    size="sm"
                    className="flex items-center justify-center gap-x-1 self-center"
                    disabled={
                      accountConfigIsLoading ||
                      onboardingUrlClassicIsSuccess ||
                      onboardingUrlBalanceIsSuccess
                    }
                    icon={
                      accountConfigIsLoading ||
                      onboardingUrlClassicIsFetching ||
                      (onboardingUrlBalanceIsFetching && (
                        <Spinner className="mt-0.5 mr-1 text-gray-400" />
                      ))
                    }
                  >
                    Update KYC
                  </Button>
                </HasAccess>
              </div>
              {(onboardingUrlClassicIsError || onboardingUrlBalanceIsError) && (
                <HasAccess allowedRoles={[...kycRoles]}>
                  <Error message="An error has occured. Please try again." />
                </HasAccess>
              )}
            </>
          )}
        </div>
        <div>
          <div className="flex flex-wrap gap-4 lg:grid lg:grid-cols-12 lg:grid-rows-[auto_auto]">
            <AdyenConfigurations
              accountConfigData={accountConfigData}
              isLoading={accountConfigIsLoading}
            />
            <div className="col-span-5">
              <CompanyContact
                accountHolderCode={accountHolderCode}
                firstName={accountConfigData?.contactFirstName ?? ""}
                lastName={accountConfigData?.contactLastName ?? ""}
                email={accountConfigData?.companyEmail ?? ""}
                isLoading={accountConfigIsLoading}
              />
              <EmailSubscription
                accountHolderCode={accountHolderCode}
                dailySubscription={
                  accountConfigData?.dailySubscription ?? false
                }
                monthlySubscription={
                  accountConfigData?.monthlySubscription ?? false
                }
                isLoading={accountConfigIsLoading}
              />
            </div>
            <Locations
              renderMe={accountConfigData?.onboardingStatus !== "In Progress"}
              accountHolderCode={accountHolderCode}
              accountHolderId={accountConfigData?.id ?? ""}
              locations={accountConfigData?.accounts ?? []}
              migrated={accountConfigData?.migrated ?? false}
              isLoading={accountConfigIsLoading}
            />
            <MerchantConfigurations
              renderMe={accountConfigData?.onboardingStatus !== "In Progress"}
              accountHolderCode={accountHolderCode}
              accountHolderId={accountConfigData?.id ?? ""}
              country={accountConfigData?.country ?? ""}
              merchantConfigData={
                accountConfigData
                  ? {
                      merchantStatus: accountConfigData.merchantStatus,
                      adyenCaptureDelay: accountConfigData.adyenCaptureDelay,
                      shopperStatement:
                        accountConfigData.stores.at(0)?.shopperStatement ?? "",
                      techFeeRate: accountConfigData.techFeeRate,
                      convFee: accountConfigData.convFee,
                      convFeeRate: accountConfigData.convFeeRate,
                      timeZoneCode: accountConfigData.timeZoneCode,
                      useTransAmountLimit:
                        accountConfigData.useTransAmountLimit,
                      transAmountLimit: accountConfigData.transAmountLimit,
                      useDailyTransAmountLimit:
                        accountConfigData.useDailyTransAmountLimit,
                      dailyTransAmountLimit:
                        accountConfigData.dailyTransAmountLimit,
                      useTechFee: accountConfigData.useTechFee,
                      useConvFee: accountConfigData.useConvFee,
                      inBalancePlatform: accountConfigData.inBalancePlatform,
                      migrated: accountConfigData.migrated,
                      stores: accountConfigData.stores,
                    }
                  : undefined
              }
              isLoading={accountConfigIsLoading}
            />
            <RatePlans
              accountHolderCode={accountHolderCode}
              accountHolderId={accountConfigData?.id ?? ""}
              accountHolderRatePlans={accountConfigData?.ratePlans ?? []}
              useInterchangeRate={
                accountConfigData?.useInterchangeRate ?? false
              }
              hasMonthlyFee={accountConfigData?.hasMonthlyFee ?? false}
              monthlyFee={accountConfigData?.monthlyFee ?? "0"}
              isSurchargeEditable={accountConfigData?.useConvFee ?? false}
              inBalancePlatform={accountConfigData?.inBalancePlatform ?? false}
              isLoading={accountConfigIsLoading}
            />
            <MerchantUsers
              accountHolderCode={accountHolderCode}
              accountConfigData={accountConfigData}
            />
            <HasAccess allowedRoles={[...csrRoles]}>
              <UserNotes
                accountHolderCode={accountHolderCode}
                timeZoneCode={accountConfigData?.timeZoneCode ?? ""}
              />
            </HasAccess>
          </div>
        </div>
      </div>
      {inBalance && (
        <HasAccess allowedRoles={[...csrRoles]}>
          <AccountCreditModal
            isOpen={accountCreditModalIsOpen}
            onClose={() => setAccountCreditModalIsOpen(false)}
            accountHolderCode={accountHolderCode}
            merchantAccountId={accountConfigData?.merchantAccountId ?? ""}
            primaryAccountAccountId={
              accountConfigData?.accounts.find((x) => x.primaryAccount)
                ?.balancePlatformAccountId ?? ""
            }
          />
        </HasAccess>
      )}
    </CustomerSettingsContext.Provider>
  );
}

export default CustomerSettings;
