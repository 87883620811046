import { useMutation } from "@tanstack/react-query";
import {
  toggleRatePlanSurcharge,
  ToggleRateplanSurchargeBody,
} from "../../../services/accountService";

export default function useToggleRatePlanSurchargeMutation() {
  return useMutation<string, Error, ToggleRateplanSurchargeBody>((body) =>
    toggleRatePlanSurcharge(body)
  );
}
