import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import { Status } from "../../types";
import { BankAccountItem } from "../../hooks/data/queries/useNormalizedBankAccountsQuery";

type Props = {
  onSubmit: (data: AddNewLocationFormData) => void;
  onCancel: () => void;
  accountHolderCode: string;
  bankAccounts: BankAccountItem[];
  isLoadingBankAccountDetails: boolean;
  createAccountStatus: Status;
};

export type AddNewLocationFormData = {
  accountHolder: string;
  locationCode: string;
  payoutBankAccount: string;
  bankAccountLast4: string;
};

function AddNewLocationForm({
  onSubmit,
  onCancel,
  accountHolderCode,
  bankAccounts,
  isLoadingBankAccountDetails,
  createAccountStatus,
}: Props): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddNewLocationFormData>({
    mode: "onBlur",
  });
  const onFormSubmit = handleSubmit(onSubmit);

  const locationCodeRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...locationCodeProps } = register("locationCode", {
    required: {
      value: true,
      message: "This field is required",
    },
  });

  useEffect(() => {
    locationCodeRef.current?.focus();
  }, [accountHolderCode]);

  return (
    <form onSubmit={onFormSubmit}>
      <div className="flex flex-col gap-y-11 pt-10">
        <Input
          id="accountHolder"
          label="Account Holder"
          value={accountHolderCode}
          disabled
          {...register("accountHolder")}
        />
        <Input
          id="locationCode"
          label="Location Code"
          errorMessage={errors?.locationCode?.message}
          ref={(e) => {
            ref(e);
            locationCodeRef.current = e;
          }}
          {...locationCodeProps}
        />
        <Select
          id="payoutBankAccount"
          label="Payout Bank Account"
          options={bankAccounts.map((x) => ({ value: x.id, label: x.label }))}
          disabled={isLoadingBankAccountDetails}
          loading={isLoadingBankAccountDetails}
          errorMessage={errors?.payoutBankAccount?.message}
          required
          {...register("payoutBankAccount", {
            required: {
              value: true,
              message: "This field is required",
            },
            onChange(e: React.ChangeEvent<HTMLSelectElement>) {
              const selectedBankAccount = bankAccounts?.at(
                e.target.selectedIndex - 1
              );
              if (selectedBankAccount) {
                setValue("bankAccountLast4", selectedBankAccount?.last4);
              }
            },
          })}
        />
        <div className="flex justify-end gap-4">
          <Button
            variant="secondary"
            size="sm"
            onClick={onCancel}
            type="button"
          >
            Cancel
          </Button>
          <Button
            size="sm"
            type="submit"
            disabled={!isValid || createAccountStatus === "loading"}
            className="flex"
          >
            {createAccountStatus === "loading" && (
              <Spinner className="mt-0.5 mr-1 text-white" />
            )}
            <span>
              {createAccountStatus === "loading" ? "Adding..." : "Add"}
            </span>
          </Button>
        </div>
      </div>
    </form>
  );
}

export default AddNewLocationForm;
