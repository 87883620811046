import React, { ReactElement } from "react";
import { ReactNode } from "react";

type PanelProps = {
  className?: string;
  children: ReactNode;
};

function Panel({ className = "", children }: PanelProps): JSX.Element {
  function getHeader() {
    return React.Children.toArray(children).find((child) => {
      if (
        React.isValidElement(child) &&
        (child as ReactElement).type === PanelHeader
      ) {
        return child;
      }
      return null;
    });
  }

  function getContent() {
    return React.Children.toArray(children).filter((child) => {
      if (React.isValidElement(child) && child.type !== PanelHeader)
        return true;
      return false;
    });
  }

  return (
    <section className={`flex w-full flex-col flex-wrap ${className}`}>
      {getHeader()}
      <div className={"w-full p-4"}>{getContent()}</div>
    </section>
  );
}

type PanelHeaderProps = {
  title: string;
  className?: string;
  children?: ReactNode;
};

function PanelHeader({
  title,
  className,
  children,
}: PanelHeaderProps): JSX.Element {
  return (
    <div
      className={`flex h-12 items-center justify-between rounded-lg bg-slate-100 px-4 py-2 ${className}`}
    >
      <h3 className="text-xl font-semibold">{title}</h3>
      {children}
    </div>
  );
}

type PanelContentProps = {
  children?: ReactNode;
};

function PanelContent({ children }: PanelContentProps): JSX.Element {
  return <>{children}</>;
}
Panel.Header = PanelHeader;
Panel.Content = PanelContent;

export default Panel;
