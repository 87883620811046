function NotFound(): JSX.Element {
  return (
    <div className="mt-48 flex flex-col items-center justify-center gap-y-16">
      <div className="text-6xl">404</div>
      <div className="text-3xl text-gray-400">
        Sorry, this page isn't available.
      </div>
    </div>
  );
}

export default NotFound;
