import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
    isStorageUseDisabled: true,
    maxBatchInterval: 0,
  },
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((env) => {
  env.tags = env.tags || [];
  env.tags["ai.cloud.role"] = "Report_Interactivity_Tracker";
});

export default appInsights;
