import { useState } from "react";
import useCreateUserMutation from "../../hooks/data/mutations/useCreateUserMutation";
import SignUpForm from "./SignUpForm";
import SignUpSuccess from "./SignUpSuccess";
import { default as ErrorComponent } from "../../components/Error";
import { SignUpFormData } from "../../services/userService";

export type AccountHolderInfo = {
  edition: string;
  jackrabbitId: string;
  companyName: string;
  firstName?: string;
  lastName?: string;
};

type Props = {
  accountHolderInfo: AccountHolderInfo | null;
  invitedUserEmail: string;
  onSignUpCancel: () => void;
};

function SignUpStep({
  accountHolderInfo,
  invitedUserEmail,
  onSignUpCancel,
}: Props): JSX.Element {
  const { mutate, status, isSuccess, isError, isLoading, isIdle, error } =
    useCreateUserMutation();

  const [createdUserFullName, setCreatedUserFullName] = useState<string>("");
  const [createdUserEmail, setCreatedUserEmail] = useState<string>("");

  async function handleCreateUserFormSubmit(signupFormData: SignUpFormData) {
    mutate(signupFormData, {
      onSuccess: ({ firstName, lastName, emailAddress }) => {
        setCreatedUserFullName(`${firstName} ${lastName}`);
        setCreatedUserEmail(emailAddress);
      },
    });
  }

  function getContent(): React.ReactNode {
    if (isIdle || isLoading) {
      return (
        <SignUpForm
          accountHolderInfo={accountHolderInfo}
          invitedUserEmail={invitedUserEmail}
          createUserStatus={status}
          onSubmit={handleCreateUserFormSubmit}
          onCancel={onSignUpCancel}
        />
      );
    }

    if (isSuccess) {
      return (
        <SignUpSuccess
          userFullName={createdUserFullName}
          userEmail={createdUserEmail}
          onClose={onSignUpCancel}
        />
      );
    }

    if (isError) {
      return (
        <div className="mt-6">
          <ErrorComponent message={error} />
        </div>
      );
    }
  }

  return <div>{getContent()}</div>;
}

export default SignUpStep;
