import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  cancelTransaction,
  CancelTransactionBody,
  CancelTransactionResponse,
} from "../../../services/transactionsService";

export default function useCancelTransactionMutation() {
  const queryClient = useQueryClient();

  return useMutation<CancelTransactionResponse, Error, CancelTransactionBody>(
    ({ pspReference, merchantAccount, reference = "" }) =>
      cancelTransaction({ pspReference, merchantAccount, reference }),
    {
      onSuccess(data) {
        queryClient.invalidateQueries([
          "transaction",
          data.paymentPspReference,
        ]);
      },
    }
  );
}
