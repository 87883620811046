import { useMutation } from "@tanstack/react-query";
import {
  createTransferFunds,
  CreateTransferFundsBody,
} from "../../../services/transactionsService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";
import { convertToAdyenNumber } from "../../../utils/number";

type CreateTransferFundsBodyWithAccountHolderCode = CreateTransferFundsBody & {
  accountHolderCode: string;
};

export default function useCreateTransferFundsMutation() {
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<
    string,
    string,
    CreateTransferFundsBodyWithAccountHolderCode
  >(
    (body) =>
      createTransferFunds({
        ...body,
        amount: {
          value: convertToAdyenNumber(body.amount.value).toString(),
          currency: body.amount.currency,
        },
      }),
    {
      async onSuccess(_, body) {
        await createNote({
          accountHolderCode: body.accountHolderCode,
          freeText: `Account credit created with amount: ${body.amount.value} ${body.amount.currency} and description: ${body.notes}`,
        });
      },
    }
  );
}
