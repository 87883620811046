import React, { useContext } from "react";
import Button, { ButtonProps } from "../../components/Button";
import Spinner from "../../components/Spinner";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import HasAccess, { csrAdminRoles } from "../../components/HasAccess";
import { CustomerSettingsContext } from "./CustomerSettings";

type EditControlButtonProps = React.ComponentProps<"button"> &
  Omit<ButtonProps, "children">;

type Props = {
  isEditing: boolean;
  isSaving: boolean;
  editButtonProps?: EditControlButtonProps;
  saveButtonProps?: EditControlButtonProps;
  cancelButtonProps?: EditControlButtonProps;
  customId?: String;
};

function EditControls({
  isEditing,
  isSaving,
  editButtonProps,
  saveButtonProps,
  cancelButtonProps,
  customId,
}: Props): JSX.Element {
  const { isEditing: isGlobalEditing, setIsEditing: setIsGlobalEditing } =
    useContext(CustomerSettingsContext);
  return (
    <>
      {isEditing ? (
        <div className="flex items-center gap-x-2">
          <Button
            size="sm"
            variant="secondary"
            title="Save"
            className="border-success"
            icon={
              isSaving ? (
                <Spinner className="text-gray-400" />
              ) : (
                <CheckIcon className="stroke-[3] text-success" />
              )
            }
            {...saveButtonProps}
            onClick={(e) => {
              setIsGlobalEditing(false);
              if (saveButtonProps?.onClick) {
                saveButtonProps.onClick(e);
              }
            }}
          />
          <Button
            {...cancelButtonProps}
            onClick={(e) => {
              setIsGlobalEditing(false);
              if (cancelButtonProps?.onClick) {
                cancelButtonProps.onClick(e);
              }
            }}
            size="sm"
            variant="secondary"
            title="Cancel"
            className="border-error"
            icon={<XMarkIcon className="stroke-[3] text-error" />}
          />
        </div>
      ) : (
        <HasAccess allowedRoles={[...csrAdminRoles]}>
          <Button
            size="sm"
            title="Edit"
            custom-id={customId}
            {...editButtonProps}
            className="transition-colors"
            disabled={isGlobalEditing || isSaving || editButtonProps?.disabled}
            onClick={(e) => {
              setIsGlobalEditing(true);
              if (editButtonProps?.onClick) {
                editButtonProps?.onClick(e);
              }
            }}
          >
            Edit
          </Button>
        </HasAccess>
      )}
    </>
  );
}

export default EditControls;
