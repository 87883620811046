import ExclamationCircleIcon from "@heroicons/react/24/solid/ExclamationCircleIcon";

type Props = {
  message: string;
};

function Error({ message }: Props): JSX.Element {
  return (
    <div className="flex items-center gap-x-4 rounded-md border border-error-700 bg-error-100 p-2">
      <ExclamationCircleIcon
        className="h-10 w-10 text-error-700"
        data-testid="exclamation-circle-icon"
      />
      <span className="text-lg" role="alert">
        {message}
      </span>
    </div>
  );
}

export default Error;
