import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import roles from "../../data/roles.json";
import { SignUpFormData } from "../../services/userService";
import { Status } from "../../types";
import { AccountHolderInfo } from "./SignUpStep";

type Props = {
  accountHolderInfo: AccountHolderInfo | null;
  invitedUserEmail: string;
  createUserStatus: Status;
  onSubmit: (data: SignUpFormData) => void;
  onCancel: () => void;
};

function SignUpForm({
  accountHolderInfo,
  invitedUserEmail,
  createUserStatus,
  onSubmit,
  onCancel,
}: Props): JSX.Element {
  const emailAddressRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    emailAddressRef.current?.focus();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SignUpFormData>({
    mode: "onBlur",
  });

  const onFormSubmit = handleSubmit(onSubmit);

  const { ref: emailAddressFieldRef, ...rest } = register("emailAddress", {
    value: invitedUserEmail.trim() ?? "",
    required: {
      value: true,
      message: "This field is required",
    },
  });

  return (
    <div className="mt-10 w-96">
      <form onSubmit={onFormSubmit}>
        <div className="flex flex-col gap-y-10">
          <Input
            id="emailAddress"
            label="Email Address"
            autoFocus
            errorMessage={errors?.emailAddress?.message}
            {...rest}
            ref={(e) => {
              emailAddressFieldRef(e);
              emailAddressRef.current = e;
            }}
            onBlurCapture={(e) => {
              e.target.value = e.target.value.trim();
            }}
          />
          <Input
            id="firstName"
            label="First Name"
            errorMessage={errors?.firstName?.message}
            {...register("firstName", {
              value: accountHolderInfo?.firstName ?? "",
              required: {
                value: true,
                message: "This field is required",
              },
            })}
          />
          <Input
            id="lastName"
            label="Last Name"
            errorMessage={errors?.lastName?.message}
            {...register("lastName", {
              value: accountHolderInfo?.lastName ?? "",
              required: {
                value: true,
                message: "This field is required",
              },
            })}
          />
          <Input
            id="business"
            label="Business"
            disabled
            {...register("business", {
              value: accountHolderInfo?.companyName ?? "",
            })}
          />
          <Input
            id="classOrCareId"
            label="Class/Care Id"
            disabled
            {...register("classOrCareId", {
              value: accountHolderInfo
                ? `${accountHolderInfo.edition}-${accountHolderInfo.jackrabbitId}`
                : "",
            })}
          />
          <Select
            id="role"
            label="Role"
            options={roles}
            errorMessage={errors?.role?.message}
            required
            {...register("role", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
          />
          <div className="flex justify-between gap-x-4">
            <Button
              variant="secondary"
              size="sm"
              className="w-1/2"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              className="w-1/2"
              disabled={!isValid || createUserStatus === "loading"}
              icon={
                createUserStatus === "loading" && (
                  <Spinner className="mt-0.5 mr-1 text-white" />
                )
              }
            >
              {createUserStatus === "loading" ? "Inviting..." : "Invite"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignUpForm;
