import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  NoteContent,
  UpdateAccountHolderEmailSubscriptionsBody,
  updateAccountHolderEmailSubscriptions,
} from "../../../services/accountService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";

export default function useUpdateEmailSubscriptions() {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, string, UpdateAccountHolderEmailSubscriptionsBody>(
    (body) => updateAccountHolderEmailSubscriptions(body),
    {
      async onSuccess(_, body) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          body.accountHolderCode,
        ]);

        queryClient.setQueryData(["accountConfig", body.accountHolderCode], {
          ...accountConfigData,
          dailySubscription: body.daily,
          monthlySubscription: body.monthly,
        });

        const previousDailySubscription = accountConfigData?.dailySubscription;
        const previousMonthlySubscription =
          accountConfigData?.monthlySubscription;

        const notes: NoteContent[] = [];

        if (body.daily !== previousDailySubscription) {
          notes.push({
            fieldName: "dailySubscription",
            fromValue: previousDailySubscription
              ? "subscribed"
              : "non-subscribed",
            toValue: body.daily ? "subscribed" : "non-subscribed",
          });
        }

        if (body.monthly !== previousMonthlySubscription) {
          notes.push({
            fieldName: "monthlySubscription",
            fromValue: previousMonthlySubscription
              ? "subscribed"
              : "non-subscribed",
            toValue: body.monthly ? "subscribed" : "non-subscribed",
          });
        }

        if (notes.length > 0) {
          await createNote({
            accountHolderCode: body.accountHolderCode,
            noteContent: notes,
          });
        }

        queryClient.invalidateQueries([
          "accountConfig",
          body.accountHolderCode,
        ]);
      },
    }
  );
}
