import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  NoteContent,
  UpdateAccountHolderBusinessContactBody,
  updateAccountHolderBusinessContact,
} from "../../../services/accountService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";

export default function useUpdateAccountHolderBusinessContact() {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, string, UpdateAccountHolderBusinessContactBody>(
    (body) => updateAccountHolderBusinessContact(body),
    {
      async onSuccess(_, body) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          body.accountHolderCode,
        ]);

        queryClient.setQueryData(["accountConfig", body.accountHolderCode], {
          ...accountConfigData,
          contactFirstName: body.contactFirstName,
          contactLastName: body.contactLastName,
          companyEmail: body.companyEmail,
        });

        const previousContactFirstName = accountConfigData?.contactFirstName;
        const previousContactLastName = accountConfigData?.contactLastName;
        const previousCompanyEmail = accountConfigData?.companyEmail;

        const notes: NoteContent[] = [];

        if (previousContactFirstName !== body.contactFirstName)
          notes.push({
            fieldName: "contactFirstName",
            fromValue: previousContactFirstName ?? "",
            toValue: body.contactFirstName,
          });

        if (previousContactLastName !== body.contactLastName) {
          notes.push({
            fieldName: "contactLastName",
            fromValue: previousContactLastName ?? "",
            toValue: body.contactLastName,
          });
        }

        if (previousCompanyEmail !== body.companyEmail) {
          notes.push({
            fieldName: "companyEmail",
            fromValue: previousCompanyEmail ?? "",
            toValue: body.companyEmail,
          });
        }

        if (notes.length > 0) {
          await createNote({
            accountHolderCode: body.accountHolderCode,
            noteContent: notes,
          });
        }

        queryClient.invalidateQueries([
          "accountConfig",
          body.accountHolderCode,
        ]);
      },
    }
  );
}
