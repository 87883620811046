import { AccountHolderUser } from "../services/accountService";

export function getEmptyUser(): AccountHolderUser {
  return {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    status: null,
  };
}
