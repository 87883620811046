import { Fragment, useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import FilterButton from "./FilterButton";
import FilterOverlay from "./FilterOverlay";

export type Props = {
  from: number | null;
  to: number | null;
  onApply: (from: number | null, to: number | null) => void;
};

function AmountFilter({ from, to, onApply }: Props): JSX.Element {
  const [internalFrom, setInternalFrom] = useState<number | null>(null);
  const [internalTo, setInternalTo] = useState<number | null>(null);
  const [error, setError] = useState<string>("");
  const isFilterApplied = from !== null || to !== null;
  const isClearDisabled = internalFrom === null && internalTo === null;
  const isApplyDisabled =
    error !== "" ||
    (internalFrom === null && internalTo === null) ||
    (internalFrom === from && internalTo === to);

  useEffect(() => {
    setInternalFrom(from);
    setInternalTo(to);
  }, [from, to]);

  useEffect(() => {
    if (internalFrom && internalTo && internalFrom > internalTo) {
      setError("From amount cannot be greater than To amount.");
    } else {
      setError("");
    }
  }, [internalFrom, internalTo]);

  function handleApply(
    close: (
      focusableElement?:
        | HTMLElement
        | React.MutableRefObject<HTMLElement | null>
        | React.MouseEvent<HTMLElement, MouseEvent>
        | undefined
    ) => void
  ) {
    if (!isApplyDisabled) {
      onApply(internalFrom, internalTo);
      close();
    }
  }

  function handleClear(
    close: (
      focusableElement?:
        | HTMLElement
        | React.MutableRefObject<HTMLElement | null>
        | React.MouseEvent<HTMLElement, MouseEvent>
        | undefined
    ) => void
  ) {
    setInternalFrom(null);
    setInternalTo(null);
    onApply(null, null);
    close();
  }

  function getFilterButtonText() {
    if (from !== null && to !== null) {
      return `Amount: ${from} to ${to}`;
    } else if (from !== null && to === null) {
      return `Amount: ${from} to (not-set)`;
    } else if (from === null && to !== null) {
      return `Amount: (not-set) to ${to}`;
    }
    return "Amount";
  }

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button as="div">
            <FilterButton
              isFilterApplied={isFilterApplied}
              onClose={() => handleClear(close)}
            >
              {getFilterButtonText()}
            </FilterButton>
          </Popover.Button>
          <FilterOverlay>
            <div className="flex flex-col gap-y-5 p-4">
              <h3 className="font-bold">Amount</h3>
              <div className="flex justify-between gap-x-1">
                <Input
                  value={internalFrom === null ? "" : internalFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    setInternalFrom(value ? +value : null);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleApply(close);
                    }
                  }}
                  id="from"
                  label="From"
                  type="number"
                  containerProps={{ className: "w-[120px]" }}
                />
                <Input
                  value={internalTo === null ? "" : internalTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    setInternalTo(value ? +value : null);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleApply(close);
                    }
                  }}
                  id="to"
                  label="To"
                  type="number"
                  containerProps={{ className: "w-[120px]" }}
                />
              </div>
              <div role="alert" className="text-sm font-medium text-error-700">
                {error}
              </div>
              <div className="flex justify-between">
                <Button
                  variant="secondary"
                  size="sm"
                  disabled={isClearDisabled}
                  onClick={() => handleClear(close)}
                  className="py-1 px-2 text-sm"
                >
                  Clear
                </Button>
                <Button
                  size="sm"
                  disabled={isApplyDisabled}
                  onClick={() => handleApply(close)}
                  className="py-1 px-2 text-sm"
                >
                  Apply
                </Button>
              </div>
            </div>
          </FilterOverlay>
        </>
      )}
    </Popover>
  );
}

export default AmountFilter;
