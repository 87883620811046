import Button from "../../components/Button";
import Error from "../../components/Error";

type Props = {
  error: string;
  onClose: () => void;
};

function OnboardingError({ error, onClose }: Props): JSX.Element {
  return (
    <div className="mt-8 flex flex-col items-center">
      <Error message={error || "An error has occurred. Please try again."} />
      <Button onClick={onClose} className="mx-32 mt-6">
        Back
      </Button>
    </div>
  );
}

export default OnboardingError;
