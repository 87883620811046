import { useEffect, useRef, useState } from "react";
import Skeleton from "../../components/Skeleton";
import useUpdateRatePlanMutation from "../../hooks/data/mutations/useUpdateRatePlanMutation";
import { AccountHolderRatePlan } from "../../services/accountService";
import RatePlanRow from "./RatePlanRow";
import { useSnackbar } from "../../components/Snackbar";
import { RatePlan, RatePlanType } from "./RatePlans";
import { sortStrings } from "../../utils/sorting";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  ratePlans: RatePlan[];
  ratePlanType: RatePlanType;
  isSurchargeEditable?: boolean;
  isLoading: boolean;
};

function RatePlanList({
  accountHolderCode,
  accountHolderId,
  ratePlans,
  accountHolderRatePlans,
  ratePlanType,
  isSurchargeEditable,
  isLoading,
}: Props): JSX.Element {
  const { mutate: updateRatePlan, isLoading: isUpdatingRatePlan } =
    useUpdateRatePlanMutation(accountHolderCode);

  const [currentEditingRatePlan, setCurrentEditingRatePlan] =
    useState<RatePlan | null>(null);
  const [currentEditingSaveButtonEnabled, setCurrentEditingSaveButtonEnabled] =
    useState<boolean>(false);

  const allRatePlans = ratePlans.map((ratePlan) => {
    const accountHolderRatePlan = accountHolderRatePlans.find(
      (arp) => arp.paymentMethodType === ratePlan.id
    );

    if (accountHolderRatePlan) {
      ratePlan.data = {
        ...accountHolderRatePlan,
        surcharge:
          accountHolderRatePlan.surcharge === null
            ? false
            : accountHolderRatePlan.surcharge,
      };
    } else {
      ratePlan.data = {
        accountHolderId,
        paymentMethodType: ratePlan.id,
        fixedAmount: "0",
        percentageValue: "0",
        allowedMethod: false,
        surcharge: false,
      };
    }
    return ratePlan;
  });

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  const inputRefs = useRef<HTMLInputElement[]>([]);
  inputRefs.current = [];

  useEffect(() => {
    const currentEditingRatePlanIndex = allRatePlans.findIndex(
      (rp) => rp.id === currentEditingRatePlan?.id
    );

    if (currentEditingRatePlan) {
      const fixedAmountGreatherThanZero =
        Number(currentEditingRatePlan?.data?.fixedAmount) > 0;
      const fixedAmountIsModified =
        currentEditingRatePlan?.data?.fixedAmount !==
        allRatePlans[currentEditingRatePlanIndex].data?.fixedAmount;

      const percentageGreatherThanZero =
        Number(currentEditingRatePlan?.data?.percentageValue) > 0;
      const percentageIsModified =
        currentEditingRatePlan?.data?.percentageValue !==
        allRatePlans[currentEditingRatePlanIndex].data?.percentageValue;

      const allowedMethodIsModified =
        currentEditingRatePlan?.data?.allowedMethod !==
        allRatePlans[currentEditingRatePlanIndex].data?.allowedMethod;

      const surchargeIsModified =
        currentEditingRatePlan.data?.surcharge !==
        allRatePlans[currentEditingRatePlanIndex].data?.surcharge;

      setCurrentEditingSaveButtonEnabled(
        fixedAmountGreatherThanZero &&
          percentageGreatherThanZero &&
          (fixedAmountIsModified ||
            percentageIsModified ||
            allowedMethodIsModified ||
            surchargeIsModified)
      );
    }
  }, [allRatePlans, currentEditingRatePlan]);

  function addToInputRefs(el: HTMLInputElement) {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  }

  function isCurrentPlanBeingEdited(ratePlan: RatePlan) {
    return ratePlan.id === currentEditingRatePlan?.id;
  }

  function handleUpdateRatePlan() {
    if (currentEditingRatePlan) {
      updateRatePlan(currentEditingRatePlan.data!, {
        onSuccess() {
          openSnackbar(
            `Rate plan ${currentEditingRatePlan.label} was successfully updated.`,
            {
              type: "success",
            }
          );
          setCurrentEditingRatePlan(null);
        },
        onError() {
          openSnackbar("Failed to update rate plan. Please try again.", {
            type: "error",
          });
        },
      });
    }
  }

  function handleEditClick(ratePlan: RatePlan, index: number) {
    setCurrentEditingRatePlan(ratePlan);
    setTimeout(() => inputRefs.current.at(index)?.focus());
  }

  return (
    <div className="flex flex-col gap-y-4 py-4 pt-8">
      <div
        className={`${
          ratePlanType === "Flat Rate Plan" ? "grid-cols-6" : "grid-cols-5"
        } grid items-center gap-x-4 font-semibold max-sm:text-sm`}
      >
        <div></div>
        <div>Allowed</div>
        <div>{ratePlanType === "Flat Rate Plan" ? "Percentage" : "Markup"}</div>
        <div>Transaction</div>
        {ratePlanType === "Flat Rate Plan" && <div>Surcharge</div>}
      </div>
      {isLoading ? (
        <div className="flex flex-wrap gap-y-6">
          {Array.from({ length: 3 }).map((_, i) => (
            <div key={i} className="flex gap-x-6">
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          ))}
        </div>
      ) : (
        allRatePlans
          .sort((a, b) => sortStrings(a.label, b.label))
          .map((ratePlan, i) => (
            <div key={ratePlan.id}>
              <RatePlanRow
                ratePlan={ratePlan}
                ratePlanType={ratePlanType}
                index={i}
                isEditing={isCurrentPlanBeingEdited(ratePlan)}
                currentEditingRatePlan={currentEditingRatePlan}
                setCurrentEditingRatePlan={setCurrentEditingRatePlan}
                addToInputRefs={addToInputRefs}
                isUpdatingRatePlan={isUpdatingRatePlan}
                handleEditClick={handleEditClick}
                handleUpdateRatePlan={handleUpdateRatePlan}
                currentEditingSaveButtonEnabled={
                  currentEditingSaveButtonEnabled
                }
                isSurchargeEditable={isSurchargeEditable ?? false}
              />
            </div>
          ))
      )}
    </div>
  );
}

export default RatePlanList;
