import { useCallback, useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import Button from "../../../components/Button";
import Select from "../../../components/Select";
import Input from "../../../components/Input";
import FilterButton from "./FilterButton";
import FilterOverlay from "./FilterOverlay";
import {
  DateFilterOption,
  DateFilterType,
} from "../../../services/transactionsService";
import { getDateTimeFromDateFilterType } from "../../../utils/dateTime";

type Props = {
  value: {
    from: DateTime | null;
    to: DateTime | null;
    type: DateFilterType;
  };
  defaultType?: DateFilterType;
  onApply: (from: DateTime, to: DateTime, type: DateFilterType) => void;
};

function DateFilter({
  value,
  defaultType = "Last24Hours",
  onApply,
}: Props): JSX.Element {
  const [selectedDateOption, setSelectedDateOption] =
    useState<DateFilterType>(defaultType);
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);

  const [formattedStartDate, setFormattedStartDate] = useState<string>("");
  const [formattedStartTime, setFormattedStartTime] = useState<string>("");

  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [formattedEndTime, setFormattedEndTime] = useState<string>("");

  const [error, setError] = useState<string>("");

  useEffect(() => {
    setStartDate(value.from);
    setEndDate(value.to);
    setSelectedDateOption(value.type);
  }, [value]);

  useEffect(() => {
    setFormattedStartDate(startDate ? startDate.toFormat("yyyy-MM-dd") : "");
    setFormattedStartTime(startDate ? startDate.toFormat("HH:mm:ss") : "");
  }, [startDate]);

  useEffect(() => {
    setFormattedEndDate(endDate ? endDate.toFormat("yyyy-MM-dd") : "");
    setFormattedEndTime(endDate ? endDate.toFormat("HH:mm:ss") : "");
  }, [endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        setError("From date cannot be greater than To date.");
      } else if (startDate > DateTime.now()) {
        setError("From date cannot be greater than today's date.");
      } else if (endDate > DateTime.now()) {
        setError("To date cannot be greater than today's date.");
      } else {
        setError("");
      }
    }
  }, [startDate, endDate]);

  const getDateTimeFromDateFilterTypeCallback = useCallback(
    getDateTimeFromDateFilterType,
    []
  );

  useEffect(() => {
    if (selectedDateOption !== "Custom") {
      setStartDate(getDateTimeFromDateFilterTypeCallback(selectedDateOption));
      setEndDate(DateTime.now());
    }
  }, [selectedDateOption, getDateTimeFromDateFilterTypeCallback]);

  function handleCalendarDateChange(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    setStartDate(start ? DateTime.fromJSDate(start) : null);
    setEndDate(end ? DateTime.fromJSDate(end) : null);
    setSelectedDateOption("Custom");
  }

  function handleDateBlur(value: string, type: string) {
    let dateStr: string = "";

    switch (type) {
      case "startDate":
        dateStr = `${value}T${formattedStartTime}`;
        break;
      case "startTime":
        dateStr = `${formattedStartDate}T${value}`;
        break;
      case "endDate":
        dateStr = `${value}T${formattedEndTime}`;
        break;
      case "endTime":
        dateStr = `${formattedEndDate}T${value}`;
        break;
    }

    const date = DateTime.fromISO(dateStr);

    if (!date.isValid) {
      setError("Invalid date(s)");
      return;
    }

    if (type.includes("start")) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  }

  function getFilterButtonText() {
    if (value.type === "Custom" && value.from && value.to) {
      return `Date: ${value.from.toFormat("yyyy-MM-dd")} to ${value.to.toFormat(
        "yyyy-MM-dd"
      )}`;
    }
    return `Date: ${DateFilterOption[value.type]}`;
  }

  function handleClear() {
    setSelectedDateOption(defaultType);

    onApply(
      getDateTimeFromDateFilterType(defaultType),
      DateTime.now(),
      defaultType
    );
  }

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button as="div">
            <FilterButton isFilterApplied={true} onClose={handleClear}>
              {getFilterButtonText()}
            </FilterButton>
          </Popover.Button>
          <FilterOverlay>
            <div className="flex">
              <div className="flex flex-col border-r border-gray-200 p-4">
                <h3 className="mb-2 font-medium">Date</h3>
                <div className="flex-1">
                  <Select
                    id="date"
                    value={selectedDateOption}
                    onChange={(e) =>
                      setSelectedDateOption(e.target.value as DateFilterType)
                    }
                    options={[
                      { value: "Last1Hour", label: DateFilterOption.Last1Hour },
                      {
                        value: "Last24Hours",
                        label: DateFilterOption.Last24Hours,
                      },
                      { value: "Last7Days", label: DateFilterOption.Last7Days },
                      {
                        value: "Last30Days",
                        label: DateFilterOption.Last30Days,
                      },
                      { value: "Custom", label: DateFilterOption.Custom },
                    ]}
                    className="mb-4 w-full"
                  />
                  <h4 className="mb-6 font-medium">Select dates</h4>
                  <div className="mb-7 flex gap-x-1">
                    <Input
                      id="from"
                      label="From"
                      value={formattedStartDate}
                      onChange={(e) => {
                        setFormattedStartDate(e.target.value);
                        if (selectedDateOption !== "Custom") {
                          setSelectedDateOption("Custom");
                        }
                      }}
                      onBlur={(e) =>
                        handleDateBlur(e.target.value, "startDate")
                      }
                      containerProps={{
                        className: "w-28",
                      }}
                    />
                    <Input
                      id="fromTime"
                      value={formattedStartTime}
                      onChange={(e) => {
                        setFormattedStartTime(e.target.value);
                        if (selectedDateOption !== "Custom") {
                          setSelectedDateOption("Custom");
                        }
                      }}
                      onBlur={(e) =>
                        handleDateBlur(e.target.value, "startTime")
                      }
                      containerProps={{
                        className: "w-28",
                      }}
                    />
                  </div>
                  <div className="mb-4 flex gap-x-1">
                    <Input
                      id="to"
                      label="To"
                      value={formattedEndDate}
                      onChange={(e) => {
                        setFormattedEndDate(e.target.value);
                        if (selectedDateOption !== "Custom") {
                          setSelectedDateOption("Custom");
                        }
                      }}
                      onBlur={(e) => handleDateBlur(e.target.value, "endDate")}
                      containerProps={{
                        className: "w-28",
                      }}
                    />
                    <Input
                      id="toTime"
                      value={formattedEndTime}
                      onChange={(e) => {
                        setFormattedEndTime(e.target.value);
                        if (selectedDateOption !== "Custom") {
                          setSelectedDateOption("Custom");
                        }
                      }}
                      onBlur={(e) => handleDateBlur(e.target.value, "endTime")}
                      containerProps={{
                        className: "w-28",
                      }}
                    />
                  </div>
                  <p
                    role="alert"
                    className="mb-4 text-sm font-medium text-error-700"
                  >
                    {error}
                  </p>
                </div>
                <div className="flex justify-between">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      close();
                      handleClear();
                    }}
                    className="py-1 px-2 text-sm"
                  >
                    Clear
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => {
                      if (startDate && endDate) {
                        onApply(startDate, endDate, selectedDateOption);
                        close();
                      }
                    }}
                    disabled={
                      (value.from === startDate && value.to === endDate) ||
                      Boolean(error)
                    }
                    className="py-1 px-2 text-sm"
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <div className="bg-gray-200">
                <DatePicker
                  selected={startDate?.toJSDate()}
                  startDate={startDate?.toJSDate()}
                  endDate={endDate?.toJSDate()}
                  inline
                  selectsRange
                  maxDate={new Date()}
                  onChange={handleCalendarDateChange}
                />
              </div>
            </div>
          </FilterOverlay>
        </>
      )}
    </Popover>
  );
}

export default DateFilter;
