import Skeleton from "../../components/Skeleton";
import { useEffect, useRef, useState } from "react";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import useUpdateAccountHolderBusinessContact from "../../hooks/data/mutations/useUpdateAccountHolderBusinessContact";
import { useSnackbar } from "../../components/Snackbar";
import Panel from "./Panel";
import EditControls from "./EditControls";
import { emailRegexp } from "../../utils/regexp";

type Props = {
  accountHolderCode: string;
  firstName: string;
  lastName: string;
  email: string;
  isLoading: boolean;
};

type UpdateBussinessContactFormData = {
  businessContactFirstName: string;
  businessContactLastName: string;
  businessContactEmail: string;
};

function CompanyContact({
  accountHolderCode,
  firstName,
  lastName,
  email,
  isLoading,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: updateBusinessContact, isLoading: isUpdatinBusinessContact } =
    useUpdateAccountHolderBusinessContact();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<UpdateBussinessContactFormData>({
    mode: "onChange",
  });
  const onFormSubmit = handleSubmit(handleUpdateBusinessContactInfo);

  const nameRef = useRef<HTMLInputElement | null>(null);

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  useEffect(() => {
    if (isEditing) {
      nameRef.current?.focus();
    }
  }, [isEditing]);

  function handleUpdateBusinessContactInfo({
    businessContactFirstName,
    businessContactLastName,
    businessContactEmail,
  }: UpdateBussinessContactFormData) {
    updateBusinessContact(
      {
        accountHolderCode,
        contactFirstName: businessContactFirstName,
        contactLastName: businessContactLastName,
        companyEmail: businessContactEmail,
      },
      {
        onSuccess() {
          openSnackbar("Business contact info was successfully updated.", {
            type: "success",
          });
          setIsEditing(false);
        },
        onError() {
          openSnackbar(
            "Failed to update business contact info. Please try again.",
            { type: "error" }
          );
        },
      }
    );
  }

  const { ref, ...businessContactNameProps } = register(
    "businessContactFirstName",
    {
      value: firstName,
    }
  );

  return (
    <form onSubmit={onFormSubmit}>
      <Panel>
        <Panel.Header title="Business Contact">
          <EditControls
            customId="editBusinessContactButton"
            isEditing={isEditing}
            isSaving={isUpdatinBusinessContact}
            editButtonProps={{
              disabled: isLoading,
              onClick: () => {
                setIsEditing(true);
                setValue("businessContactFirstName", firstName);
                setValue("businessContactLastName", lastName);
              },
            }}
            saveButtonProps={{
              type: "submit",
              disabled: !isDirty || !isValid,
            }}
            cancelButtonProps={{
              onClick: () => {
                setIsEditing(false);
                setValue("businessContactFirstName", firstName);
                setValue("businessContactLastName", lastName);
                setValue("businessContactEmail", email);
              },
            }}
          />
        </Panel.Header>
        <Panel.Content>
          <div className="grid grid-cols-4 grid-rows-2 gap-x-2 gap-y-2">
            <div className="h-8 font-medium">First Name</div>
            {isLoading ? (
              <Skeleton />
            ) : isEditing ? (
              <Input
                id="businessContactFirstName"
                type="text"
                ref={(e) => {
                  ref(e);
                  nameRef.current = e;
                }}
                {...businessContactNameProps}
              />
            ) : (
              <div className="truncate" title={firstName}>
                {firstName}
              </div>
            )}
            <div className="h-8 font-medium">Last Name</div>
            {isLoading ? (
              <Skeleton />
            ) : isEditing ? (
              <Input
                id="businessContactLastName"
                type="text"
                {...register("businessContactLastName", {
                  value: lastName,
                })}
              />
            ) : (
              <div className="truncate" title={lastName}>
                {lastName}
              </div>
            )}

            <div className="h-8 font-medium">Email</div>
            {isLoading ? (
              <Skeleton />
            ) : isEditing ? (
              <div className="col-span-3">
                <Input
                  id="businessContactEmail"
                  errorMessage={errors?.businessContactEmail?.message}
                  type="text"
                  {...register("businessContactEmail", {
                    value: email,
                    pattern: {
                      value: emailRegexp,
                      message: "Please enter a valid email.",
                    },
                  })}
                />
              </div>
            ) : (
              <div>{email}</div>
            )}
          </div>
        </Panel.Content>
      </Panel>
    </form>
  );
}

export default CompanyContact;
