import { useState } from "react";
import { DateTime } from "luxon";
import useMerchantAccountsQuery from "../../hooks/data/queries/useMerchantAccountsQuery";
import Select from "../../components/Select";
import { MerchantAccount } from "../../services/accountService";
import Button from "../../components/Button";
import Input from "../../components/Input";
import DatePicker from "react-datepicker";
import useMonthyFeesDueQuery from "../../hooks/data/queries/useMonthlyFeesDueQuery";
import Spinner from "../../components/Spinner";
import ArrowDownTrayIcon from "@heroicons/react/24/outline/ArrowDownTrayIcon";
import { useSnackbar } from "../../components/Snackbar";
import { downloadMonthlyFeesDueCSV } from "../../utils/reports";
import {
  formatISODateToLocal,
  reportNameDateFormat,
} from "../../utils/dateTime";

const adminReportTypes = ["Monthly Fees Due"] as const;
type AdminReportType = typeof adminReportTypes[number];

function AdminReport(): JSX.Element {
  const [date, setDate] = useState<DateTime>(DateTime.now().startOf("month"));
  const [merchantAccount, setMerchantAccount] =
    useState<MerchantAccount | null>(null);
  const [reportType, setReportType] =
    useState<AdminReportType>("Monthly Fees Due");
  const { data: merchantAccountsData } =
    useMerchantAccountsQuery("JackRabbitTech");
  const merchantAccounts = merchantAccountsData || [];
  const [isMonthlyFeesDueQueryEnabled, setIsMonthlyFeesDueQueryEnabled] =
    useState(false);
  const { openSnackbar } = useSnackbar({
    duration: 5000,
    position: "top",
  });
  const reportName = `jackrabbit_pay_monthly_fees_${formatISODateToLocal(
    date?.toString() || DateTime.now().toString(),
    reportNameDateFormat
  )}.csv`;
  const { isFetching: isFetchingmonthlyFeesDue } = useMonthyFeesDueQuery(
    {
      merchantAccountId: merchantAccount?.merchantAccountId ?? "",
      reportDate: date?.toFormat("yyyy-MM-dd") ?? "",
    },
    {
      enabled: isMonthlyFeesDueQueryEnabled,
      onSuccess: (data) => {
        if (data.length === 0) {
          openSnackbar(
            `There are no records for merchant account ${
              merchantAccount?.merchantAccountName
            } and month ${date?.toFormat("MM/yyyy")}`,
            {
              type: "error",
            }
          );
        } else {
          const monthlyFeesDueItems = data.map((x) => ({
            ...x,
            amount: x.amount.replaceAll(",", ""),
          }));
          downloadMonthlyFeesDueCSV(monthlyFeesDueItems, reportName);
        }
      },
      onError: (err) => {
        openSnackbar(
          "An error has occured while generating the monthly fees due csv file. Please try again.",
          {
            type: "error",
          }
        );
      },
      onSettled() {
        setIsMonthlyFeesDueQueryEnabled(false);
      },
    }
  );

  return (
    <div className="flex w-48 flex-col gap-y-8">
      <Select
        id="reportType"
        label="Report Type"
        options={adminReportTypes.map((x) => ({ value: x, label: x }))}
        value={reportType}
        onChange={(e) => {
          const newReportType = adminReportTypes[e.target.selectedIndex - 1];
          setReportType(newReportType);
        }}
      />
      <Select
        id="merchantAccount"
        label="Merchant Account"
        options={merchantAccounts?.map((x) => ({
          value: x.merchantAccountId,
          label: x.balancePlatformId
            ? `${x.merchantAccountName} / ${x.balancePlatformId}`
            : x.merchantAccountName,
        }))}
        value={merchantAccount?.merchantAccountId}
        onChange={(e) => {
          const newMerchantAccount =
            merchantAccounts[e.target.selectedIndex - 1];
          setMerchantAccount(newMerchantAccount);
        }}
      />
      <DatePicker
        selected={date?.toJSDate()}
        customInput={<Input label="Date" />}
        dateFormat={"MM/yyyy"}
        showMonthYearPicker
        maxDate={DateTime.now().endOf("month").toJSDate()}
        onChange={(newDate) => {
          if (newDate) {
            setDate(DateTime.fromJSDate(newDate));
          }
        }}
      />
      <Button
        onClick={() => setIsMonthlyFeesDueQueryEnabled(true)}
        disabled={isFetchingmonthlyFeesDue || merchantAccount === null}
        icon={isFetchingmonthlyFeesDue ? <Spinner /> : <ArrowDownTrayIcon />}
      >
        <span className="truncate">Download CSV</span>
      </Button>
    </div>
  );
}

export default AdminReport;
