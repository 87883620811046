import Visa from "../assets/visa.svg";
import Diners from "../assets/diners.svg";
import Discover from "../assets/discover.svg";
import Amex from "../assets/amex.svg";
import MasterCard from "../assets/mastercard.svg";
import GooglePay from "../assets/googlepay.svg";
import ACH from "../assets/ach.png";
import ApplePay from "../assets/applepay.svg";

type Props = {
  type: string;
};

function getSvg(type: string) {
  if (!type) return "";

  const lowercaseType = type.toLowerCase();
  if (lowercaseType.includes("applepay")) return ApplePay;
  if (lowercaseType.includes("google pay")) return GooglePay;
  if (lowercaseType === "visa") return Visa;
  if (lowercaseType === "diners") return Diners;
  if (lowercaseType === "mastercard" || lowercaseType === "mc")
    return MasterCard;
  if (lowercaseType === "discover") return Discover;
  if (lowercaseType.includes("american") || lowercaseType === "amex")
    return Amex;
  if (lowercaseType.includes("ach")) return ACH;
  return "";
}

function PaymentIcon({ type }: Props): JSX.Element {
  const svg = getSvg(type);

  if (svg) {
    let classList = svg === ApplePay ? "h-4" : "h-4 rounded";
    return (
      <img src={getSvg(type)} alt={type} title={type} className={classList} />
    );
  }
  return <div className="h-4 w-6 rounded bg-slate-200" title={type} />;
}

export default PaymentIcon;
