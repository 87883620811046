type Props = {
  text: string;
};

function TableHeader({ text }: Props): JSX.Element {
  return (
    <div className="truncate" title={text}>
      {text}
    </div>
  );
}

export default TableHeader;
