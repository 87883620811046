import {
  TransactionLedgerItem,
  TransactionsFilter,
} from "../../services/transactionsService";
import Table from "../../components/Table";
import Error from "../../components/Error";
import {
  downloadTransactionLedgerCsv,
  getDefaultTransactionsFilter,
  getEmptyTransactionLedgerItem,
} from "../../utils/transaction";
import { useMemo, useState } from "react";
import { getTransactionsTableColumns } from "./transactionsTableColumns";
import Button from "../../components/Button";
import ArrowDownTrayIcon from "@heroicons/react/24/outline/ArrowDownTrayIcon";
import Spinner from "../../components/Spinner";
import { useSnackbar } from "../../components/Snackbar";
import { ChangePageEvent } from "../../types";
import { getPaginatorPage } from "../../utils/pagination";
import TransactionsTableFilter from "./TransactionsTableFilter";
import { OnChangeFn, SortingState } from "@tanstack/react-table";
import { useAtom } from "jotai";
import { transactionsFilterAtom } from "../../atoms";
import useTransactionLedgerQuery from "../../hooks/data/queries/useTransactionLedgerQuery";
import Switch from "../../components/Switch";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import { DateTime } from "luxon";
import useAccountConfigQuery from "../../hooks/data/queries/useAccountConfigQuery";
import useTimezoneByIanaQuery from "../../hooks/data/queries/useTimezoneByIanaQuery";
import Skeleton from "../../components/Skeleton";
import {
  formatISODateToLocal,
  reportNameDateFormat,
} from "../../utils/dateTime";

function Transactions(): JSX.Element {
  const [filter, setFilter] = useAtom(transactionsFilterAtom);
  const [totalPages, setTotalPages] = useState(1);
  const [isAllTransactionsQueryEnabled, setAllTransactionsQueryEnabled] =
    useState(false);

  const { openSnackbar } = useSnackbar({
    duration: 5000,
    position: "top",
  });

  const [transactionsQuery, allTransactionsQuery] = useTransactionLedgerQuery(
    {
      amount: filter.amount,
      cardOrAccountNumber: filter.cardOrAccountNumber,
      pspReference: filter.pspReference,
      merchantReference: filter.merchantReference,
      date: filter.date,
      status: filter.status,
      transactionType: filter.transactionType,
      accountHolderCode: filter.accountHolderCode,
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      sort: filter.sort,
      hideZeroUsdTransactions: filter.hideZeroUsdTransactions,
    },
    {
      enabled: Boolean(filter.date.from && filter.date.to),
      onSuccess: (data) => setTotalPages(data.totalPages),
    },
    {
      enabled: isAllTransactionsQueryEnabled,
      onSuccess: (data) => {
        const transactions = data.pageElements.map((t) => ({
          ...t,
          transactionCreatedDate: t.transactionCreatedDate.replaceAll(",", ""),
          amountValue: t.amountValue.replaceAll(",", ""),
          transactionOwnerNumber: `*** ${t.transactionOwnerNumber}`,
        }));
        const reportName = `jackrabbit_pay_transactions_${formatISODateToLocal(
          DateTime.now().toString(),
          reportNameDateFormat
        )}.csv`;
        downloadTransactionLedgerCsv(transactions, reportName);
      },
      onError: () => {
        openSnackbar(
          "An error has occured while generating the transactions csv file. Please try again.",
          {
            type: "error",
          }
        );
      },
      onSettled: () => {
        setAllTransactionsQueryEnabled((previous) => !previous);
      },
    }
  );

  const { data: transactionsData, isLoading, error } = transactionsQuery;
  const { isFetching: isFetchingAllTransactionsData } = allTransactionsQuery;

  const memoTransactions = useMemo(() => {
    if (isLoading && !transactionsData) {
      return Array.from<TransactionLedgerItem>({
        length: filter.pageSize,
      }).fill(getEmptyTransactionLedgerItem());
    }
    return transactionsData?.pageElements ?? [];
  }, [transactionsData, isLoading, filter.pageSize]);

  const { data: currentUserAccount } = useCurrentUserAccount();

  const { data: accountConfigData, isFetching: accountConfigIsFetching } =
    useAccountConfigQuery(
      filter.accountHolderCode || currentUserAccount?.accountHolderCode,
      {
        enabled: Boolean(
          filter.accountHolderCode || currentUserAccount?.accountHolderCode
        ),
      }
    );

  const { data: localTimezone } = useTimezoneByIanaQuery(
    DateTime.now().toFormat("z"),
    {
      enabled: !accountConfigData?.timeZoneCode,
    }
  );

  const timezone = accountConfigData?.timeZoneCode ?? localTimezone?.code;

  const [columnVisibility] = useState({
    accountHolderCode: currentUserAccount?.type === "CSR",
    merchantAccount: currentUserAccount?.type === "CSR",
  });

  const preventSortingColumns = ["timeZoneCode"];

  function handleFilterChange(newFilter: TransactionsFilter) {
    const {
      amount,
      cardOrAccountNumber,
      pspReference,
      merchantReference,
      date,
      status,
      transactionType,
      accountHolderCode,
    } = newFilter;

    setFilter({
      ...filter,
      amount,
      cardOrAccountNumber,
      pspReference,
      merchantReference,
      date,
      status,
      transactionType,
      accountHolderCode,
      pageNumber: 1,
    });
  }

  function handleFilterReset() {
    setFilter(getDefaultTransactionsFilter("Last1Hour"));
  }

  function handlePageChange(event: ChangePageEvent, totalPages: number = 1) {
    setFilter({
      ...filter,
      pageNumber: getPaginatorPage(filter.pageNumber, totalPages, event),
    });
  }

  function handlePageSizeChange(pageSize: number) {
    setFilter({
      ...filter,
      pageNumber: 1,
      pageSize,
    });
  }

  function handleSortChange(sort: SortingState) {
    // @ts-ignore
    const [currentHeader] = sort();
    if (!preventSortingColumns.includes(currentHeader.id)) {
      setFilter({
        ...filter,
        sort,
      });
    }
  }

  function handleHideZeroUsdTransactionsToggle() {
    setFilter({
      ...filter,
      hideZeroUsdTransactions: !filter.hideZeroUsdTransactions,
    });
  }

  function handleExportCsvClick() {
    setAllTransactionsQueryEnabled(true);
  }

  return (
    <div className="flex flex-col px-6">
      <h1 className="mb-4 text-3xl">Transactions</h1>
      <div className="mb-3 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-[9fr_1fr] sm:grid-rows-1">
        <TransactionsTableFilter
          filter={{
            amount: filter.amount,
            cardOrAccountNumber: filter.cardOrAccountNumber,
            pspReference: filter.pspReference,
            merchantReference: filter.merchantReference,
            date: filter.date,
            status: filter.status,
            transactionType: filter.transactionType,
            accountHolderCode: filter.accountHolderCode,
            hideZeroUsdTransactions: filter.hideZeroUsdTransactions,
          }}
          onFilterChange={handleFilterChange}
          onFilterReset={handleFilterReset}
        />
        <div className="self-end">
          <Button
            onClick={handleExportCsvClick}
            size="sm"
            variant="secondary"
            disabled={isFetchingAllTransactionsData}
            icon={
              isFetchingAllTransactionsData ? (
                <Spinner />
              ) : (
                <ArrowDownTrayIcon />
              )
            }
          >
            Export to CSV
          </Button>
        </div>
      </div>
      {error ? (
        <Error message={error} />
      ) : (
        <div className="my-2 flex flex-col gap-y-2">
          <div className="flex justify-between">
            {isLoading || accountConfigIsFetching ? (
              <Skeleton className="w-72" />
            ) : (
              <span>All transactions are in {timezone} Time zone.</span>
            )}
            <Switch
              checked={filter.hideZeroUsdTransactions}
              onChange={handleHideZeroUsdTransactionsToggle}
              label="Hide 0 amount transactions"
              containerProps={{
                className: "self-end",
              }}
              labelProps={{
                className: "text-sm text-gray-700",
              }}
              showLegend={false}
            />
          </div>
          <Table
            id="transactions"
            data={memoTransactions}
            columns={getTransactionsTableColumns()}
            isLoading={isLoading}
            noRowsText="Your search returned no results. Please modify the criteria and try again."
            sorting={filter.sort}
            setSorting={handleSortChange as OnChangeFn<SortingState>}
            preventSortingColumns={["timeZoneCode"]}
            columnVisibility={columnVisibility}
            paginatorOptions={{
              currentPage: filter.pageNumber,
              pageSize: filter.pageSize,
              totalPages,
              activeNextPage: transactionsData?.activeNextPage ?? false,
              activePrevPage: transactionsData?.activePrevPage ?? false,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Transactions;
