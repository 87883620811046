import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  AccountHolderRatePlan,
  updateRatePlan,
  UpdateRatePlanBody,
} from "../../../services/accountService";
import { formatAmount } from "../../../utils/currency";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";

const ratePlanToString = (rp: AccountHolderRatePlan) =>
  `${rp.paymentMethodType.toUpperCase()}: fixed: ${formatAmount(
    +rp.fixedAmount
  )}, percentage: ${formatAmount(+rp.percentageValue)}, allowed: ${
    rp.allowedMethod ? "yes" : "no"
  }`;

export default function useUpdateRatePlanMutation(accountHolderCode: string) {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, Error, UpdateRatePlanBody>(
    (body) => updateRatePlan(body),
    {
      async onSuccess(_, updatedRatePlan) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          accountHolderCode,
        ]);

        const updatedRatePlans = accountConfigData?.ratePlans.some(
          (ratePlan) =>
            ratePlan.paymentMethodType === updatedRatePlan.paymentMethodType
        )
          ? accountConfigData?.ratePlans.map((ratePlan) => {
              return ratePlan.paymentMethodType ===
                updatedRatePlan.paymentMethodType
                ? updatedRatePlan
                : ratePlan;
            })
          : [...(accountConfigData?.ratePlans || []), updatedRatePlan];

        queryClient.setQueryData(["accountConfig", accountHolderCode], {
          ...accountConfigData,
          ratePlans: updatedRatePlans,
        });

        const previousUpdatedRatePlan = accountConfigData?.ratePlans?.find(
          (rp) => rp.paymentMethodType === updatedRatePlan.paymentMethodType
        );

        if (updatedRatePlan) {
          await createNote({
            accountHolderCode,
            noteContent: [
              {
                fieldName: "ratePlans",
                fromValue: ratePlanToString(
                  previousUpdatedRatePlan || {
                    accountHolderId: updatedRatePlan.accountHolderId,
                    paymentMethodType: updatedRatePlan.paymentMethodType,
                    allowedMethod: false,
                    fixedAmount: "0",
                    percentageValue: "0",
                    surcharge: false,
                  }
                ),
                toValue: ratePlanToString(updatedRatePlan),
              },
            ],
          });
        }

        queryClient.invalidateQueries(["accountConfig", accountHolderCode]);
      },
    }
  );
}
