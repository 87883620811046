import {
  getUserDetails,
  GetUserDetailsResponse,
} from "../../../services/accountService";
import { useQuery } from "@tanstack/react-query";

export default function useUserDetailsQuery(accountHolderCode: string = "") {
  return useQuery<GetUserDetailsResponse, string>(
    ["userDetails", accountHolderCode],
    () => getUserDetails(accountHolderCode)
  );
}
