import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

function SuccessIcon({ className }: Props): JSX.Element {
  return (
    <div
      className={twMerge(
        "success-icon-svg flex items-center justify-center rounded-full bg-success",
        className
      )}
      data-testid="success-icon"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4/5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
          className="success-icon-path text-white"
        />
      </svg>
    </div>
  );
}

export default SuccessIcon;
