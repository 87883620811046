import { useState } from "react";
import useOnboardingUrlBalanceQuery from "../hooks/data/queries/useOnboardingUrlBalanceQuery";
import { useSnackbar } from "./Snackbar";
import useAccountConfigQuery from "../hooks/data/queries/useAccountConfigQuery";
import useThemeIdByMerchantAccountId from "../hooks/data/queries/useThemeIdByMerchantAccountId";

type Props = {
  accountHolder: string;
  children: React.ReactNode;
};

function ResumeBalanceHOP({ accountHolder, children }: Props): JSX.Element {
  const [
    onboardingBalanceUrlQueryEnabled,
    setOnboardingBalanceUrlQueryEnabled,
  ] = useState<boolean>(false);

  const prevLocation = window.location.href;

  const { openSnackbar } = useSnackbar({
    duration: 5000,
    position: "top",
    classes: "text-xl",
  });

  const {
    data: accountConfigData,
    isLoading,
    isSuccess: accountConfigDataSuccess,
    error: accountConfigDataError,
  } = useAccountConfigQuery(accountHolder, {
    enabled: Boolean(accountHolder),
  });

  const {
    data: themeIdData,
    isLoading: themeIdIsLoading,
    error: themeIdError,
  } = useThemeIdByMerchantAccountId(accountConfigData?.merchantAccountId ?? "");

  const {
    isFetching: onboardingUrlBalanceIsFetching,
    isSuccess: onboardingUrlBalanceIsSuccess,
    isError: onboardingUrlBalanceIsError,
  } = useOnboardingUrlBalanceQuery(
    {
      returnUrl: prevLocation,
      legalEntityId: accountConfigData?.legalEntityId ?? "",
      themeId: themeIdData || "",
    },
    {
      enabled: onboardingBalanceUrlQueryEnabled,
      async onSuccess({ redirectUrl }) {
        window.location.href = redirectUrl;
      },
      onError() {
        setOnboardingBalanceUrlQueryEnabled(false);
      },
    }
  );

  return (
    <button onClick={() => setOnboardingBalanceUrlQueryEnabled(true)}>
      {children}
    </button>
  );
}

export default ResumeBalanceHOP;
