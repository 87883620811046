import {
  getOnboardingUrl,
  GetOnboardingUrlBody,
  GetOnboardingUrlResponse,
} from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useOnboardingUrlQuery(
  body: GetOnboardingUrlBody,
  queryOptions?: UseQueryOptions<GetOnboardingUrlResponse, string>
) {
  return useQuery<GetOnboardingUrlResponse, string>(
    ["onboardingUrl", body],
    () => getOnboardingUrl(body),
    queryOptions
  );
}
