import Spinner from "./Spinner";
import JackrabbitPayLogo from "../assets/logo-jackrabbit-pay-2023-color-final.svg";

function LoadingFullScreen(): JSX.Element {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex w-72 flex-col items-center gap-y-6">
        <img
          src={JackrabbitPayLogo}
          alt="Jackrabbit Pay Logo"
          className="w-72"
        />
        <Spinner className="h-12 w-12 text-primary-700" />
      </div>
    </div>
  );
}

export default LoadingFullScreen;
