import {
  getOnboardingUrlBalance,
  GetOnboardingUrlBalanceBody,
  GetOnboardingUrlBalanceResponse,
} from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useOnboardingUrlBalanceQuery(
  body: GetOnboardingUrlBalanceBody,
  queryOptions?: UseQueryOptions<GetOnboardingUrlBalanceResponse, string>
) {
  return useQuery<GetOnboardingUrlBalanceResponse, string>(
    ["onboardingUrlBalance", body],
    () => getOnboardingUrlBalance(body),
    queryOptions
  );
}
