import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { twJoin, twMerge } from "tailwind-merge";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "../components/Button";
import React from "react";

type AutocompleteItem = {
  value: string;
  label: string;
};

type Props = {
  items: AutocompleteItem[];
  value: string;
  onChange: (value: string | null) => void;
  inputValue: string;
  onInputValueChange: (value: string) => void;
  inputClassname?: string;
};

const Autocomplete = React.forwardRef<HTMLInputElement, Props>(
  (
    { items, value, onChange, inputValue, onInputValueChange, inputClassname },
    ref
  ): JSX.Element => {
    return (
      <div className="relative">
        <Combobox value={value} onChange={onChange} disabled={Boolean(value)}>
          <Combobox.Input
            ref={ref}
            value={inputValue}
            onChange={(event) => onInputValueChange(event.target.value)}
            placeholder="Search for an account holder"
            autoComplete="off"
            className={twMerge(
              "rounded-md border border-gray-300 px-2 py-1 shadow-sm",
              inputClassname
            )}
          />
          {value && (
            <Button
              variant="secondary"
              size="sm"
              title={value}
              className={
                "absolute top-1 left-1 flex h-[1.6rem] max-w-[350px] items-center gap-x-2 bg-primary-800 text-white transition-colors hover:bg-primary-500"
              }
              icon={
                <XMarkIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null);
                  }}
                  className="h-5 w-5 min-w-[1.25rem] rounded-full text-white hover:bg-white hover:text-primary-500"
                />
              }
              iconPosition="right"
            >
              <span className="truncate">{value}</span>
            </Button>
          )}
          <Combobox.Options className="absolute z-10 w-64 bg-white shadow-lg">
            {items.map((item) => (
              <Combobox.Option
                key={item.value}
                value={item.value}
                className="px-2 py-1"
              >
                {({ active }) => (
                  <div
                    className={twJoin(
                      "rounded p-1",
                      active && "cursor-pointer bg-gray-100"
                    )}
                  >
                    {item.label}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox>
      </div>
    );
  }
);

export default Autocomplete;
