import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import SuccessIcon from "../../components/SuccessIcon";

type Props = {
  showSuccessMessage: boolean;
  onInviteClick: (userEmail: string) => void;
};

function InviteUser({ showSuccessMessage, onInviteClick }: Props): JSX.Element {
  const [userEmail, setUserEmail] = useState<string>("");
  const [confirmedUserEmail, setConfirmedUserEmail] = useState<string>("");
  const inviteButtonEnabled =
    userEmail && confirmedUserEmail && userEmail === confirmedUserEmail;
  const userEmailRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    userEmailRef.current?.focus();
  }, []);

  return (
    <div className="mt-4 flex flex-1 flex-col items-center gap-2">
      {showSuccessMessage && (
        <>
          <SuccessIcon className="mt-4 h-14 w-14 fill-success" />
          <div className="text-xl">
            Account holder has been created successfully.
          </div>
        </>
      )}
      <div className="text-xl">
        Please invite the user to complete the onboarding process.
      </div>
      <div className="my-10 w-7/12">
        <div className="relative">
          <Input
            ref={userEmailRef}
            id="userEmail"
            label="User email"
            type="text"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
        <div className="relative mt-8">
          <Input
            id="confirmUserEmail"
            label="Confirm user email"
            type="text"
            value={confirmedUserEmail}
            onChange={(e) => setConfirmedUserEmail(e.target.value)}
            errorMessage={
              inviteButtonEnabled ? "" : "Both email addresses should match."
            }
          />
        </div>
        <Button
          size="sm"
          className="mt-10 w-full"
          disabled={!inviteButtonEnabled}
          onClick={() => onInviteClick(userEmail)}
        >
          Invite
        </Button>
      </div>
    </div>
  );
}

export default InviteUser;
