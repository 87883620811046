import { useQuery } from "@tanstack/react-query";
import {
  getTransactionDetail,
  Transaction,
} from "../../../services/transactionsService";

export default function useTransactionDetailQuery(pspReference: string) {
  return useQuery<Transaction, string>(["transaction", pspReference], () =>
    getTransactionDetail(pspReference)
  );
}
