import { useQuery } from "@tanstack/react-query";
import {
  GetReportBody,
  DailyReportNoContentResponse,
  getDailyReport,
} from "../../../services/transactionsService";

export default function useDailyReportQuery(dailyReportBody: GetReportBody) {
  return useQuery<DailyReportNoContentResponse, string>(
    ["dailyReport", dailyReportBody],
    () => getDailyReport(dailyReportBody)
  );
}
