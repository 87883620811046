import { httpCall } from "../utils/http";

export type SignUpFormData = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  business: string;
  classOrCareId: string;
  role: string;
};

export type CreateUserBody = {
  accountEnabled: boolean;
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  accountHolderCode: string;
};

export async function createUser(signupFormData: SignUpFormData) {
  const temporalPassword = `${
    signupFormData.firstName
  }${signupFormData.lastName.charAt(0)}12345!`;
  const body = {
    accountEnabled: true,
    accountHolderCode: signupFormData.classOrCareId,
    emailAddress: signupFormData.emailAddress,
    firstName: signupFormData.firstName,
    lastName: signupFormData.lastName,
    password: temporalPassword,
    role: signupFormData.role,
  };
  return httpCall<CreateUserBody, CreateUserBody>("POST", {
    url: `${process.env.REACT_APP_USERS_API_URI}/CreateUser`,
    body,
    errorMessage: "Unable to create the user. Please try again.",
    mapErrorMessageFn: (error) => {
      if (error.includes("ObjectConflict"))
        return "The email address specified is in use by another user. Please try again with another email address.";
      return "Error";
    },
  });
}
