import {
  getAccountHoldersList,
  GetAccountHoldersListResponse,
} from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { SortingState } from "@tanstack/react-table";

type Params = {
  accountHolderCode: string;
  status: string;
  pageNumber: number;
  pageSize: number;
  sort: SortingState;
};

export default function useAccountHoldersQuery(
  params: Params,
  queryOptions?: UseQueryOptions<GetAccountHoldersListResponse, string>
) {
  return useQuery<GetAccountHoldersListResponse, string>(
    ["accountHolders", params],
    () => getAccountHoldersList(params),
    {
      ...queryOptions,
    }
  );
}
