import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { default as ErrorComponent } from "../../components/Error";
import AddNewLocationForm, {
  AddNewLocationFormData,
} from "./AddNewLocationForm";
import { useEffect, useState } from "react";
import SuccessIcon from "../../components/SuccessIcon";
import useAccountConfigQuery from "../../hooks/data/queries/useAccountConfigQuery";
import useMerchantAccountsSysConfigQuery from "../../hooks/data/queries/useMerchantAccountsSysConfigQuery";
import useNormalizedBankAccountsQuery from "../../hooks/data/queries/useNormalizedBankAccountsQuery";
import useCreateAccountMutation from "../../hooks/data/mutations/useCreateAccountMutation";

type Props = {
  isOpen: boolean;
  accountHolderCode: string;
  onClose: () => void;
  onLocationAdded: () => void;
};

function AddNewLocationModal({
  isOpen,
  accountHolderCode,
  onClose,
  onLocationAdded,
}: Props): JSX.Element {
  const companyId = "JackRabbitTech"; // TODO: Harcoded for now

  const { data: accountConfigData } = useAccountConfigQuery(accountHolderCode);

  const inBalancePlatform = accountConfigData?.inBalancePlatform ?? false;

  const { data: merchantAccounts } =
    useMerchantAccountsSysConfigQuery(companyId);

  const merchantAccount = merchantAccounts?.find(
    (x) => x.merchantAccountId === accountConfigData?.merchantAccountId
  );

  const { data: bankAccountsData, isLoading: isLoadingBankAccounts } =
    useNormalizedBankAccountsQuery(accountHolderCode);

  const { mutate, reset, status, isSuccess, isLoading, isError, isIdle } =
    useCreateAccountMutation(inBalancePlatform);

  const [createAccountError, setCreateAccountError] = useState<string>("");

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  async function handleNewLocationFormSubmit({
    locationCode,
    payoutBankAccount,
    bankAccountLast4,
  }: AddNewLocationFormData) {
    if (inBalancePlatform) {
      mutate(
        {
          accountHolderCode,
          locationCode,
          bankAccountUuid: payoutBankAccount,
          defaultCurrencyCode: merchantAccount?.merchantCurrency ?? "",
          description: locationCode,
          bankAccountLast4,
        },
        {
          onSuccess: () => {
            onLocationAdded();
          },
          onError: (error) => {
            setCreateAccountError(error);
          },
        }
      );
    } else {
      mutate(
        {
          accountHolderCode,
          locationCode,
          bankAccountUuid: payoutBankAccount,
          bankAccountLast4,
        },
        {
          onSuccess: () => {
            onLocationAdded();
          },
          onError: (error) => {
            setCreateAccountError(error);
          },
        }
      );
    }
  }

  function handleModalClose() {
    onClose();
  }

  function getModalContent(): React.ReactNode {
    if (isIdle || isLoading) {
      return (
        <AddNewLocationForm
          accountHolderCode={accountHolderCode}
          bankAccounts={bankAccountsData ?? []}
          isLoadingBankAccountDetails={isLoadingBankAccounts}
          createAccountStatus={status}
          onSubmit={handleNewLocationFormSubmit}
          onCancel={handleModalClose}
        />
      );
    }

    if (isSuccess) {
      return (
        <div className="mt-4 flex flex-1 flex-col items-center gap-y-2">
          <SuccessIcon className="mt-4 h-14 w-14 fill-success" />
          <div className="pt-2 text-center text-lg">
            <p>Client location has been added.</p>
          </div>
          <Button
            type="submit"
            size="sm"
            className="mx-32 mt-6"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="mt-4 flex flex-col items-center gap-y-4">
          <ErrorComponent
            message={
              createAccountError ?? "An error has occured. Please try again."
            }
          />
          <Button size="sm" onClick={reset}>
            Back
          </Button>
        </div>
      );
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title={"Add New Location"}
      titleClasses="text-left"
      onClose={handleModalClose}
      panelClasses="min-w-[400px]"
    >
      {getModalContent()}
    </Modal>
  );
}

export default AddNewLocationModal;
