import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import type { Status } from "../../types";
import countries from "../../data/countries.json";
import editions from "../../data/editions.json";
import { useEffect, useRef } from "react";
import { emailRegexp } from "../../utils/regexp";
import useTimezonesQuery from "../../hooks/data/queries/useTimezonesQuery";
import useMerchantAccountsQuery from "../../hooks/data/queries/useMerchantAccountsQuery";

type Props = {
  companyId: string;
  formData: AddNewClassicClientFormData | null;
  onSubmit: (data: AddNewClassicClientFormData) => void;
  onCancel: () => void;
  onboardingStatus: Status;
};

export type AddNewClassicClientFormData = {
  edition: string;
  jackrabbitId: string;
  legalEntityType: string;
  companyName: string;
  companyEmail: string;
  country: string;
  timezone: string;
  firstName: string;
  lastName: string;
  merchantAccountId: string;
  merchantAccountName: string;
};

function AddNewClassicClientForm({
  companyId,
  formData,
  onSubmit,
  onCancel,
  onboardingStatus,
}: Props): JSX.Element {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddNewClassicClientFormData>({
    mode: "onBlur",
  });
  const onFormSubmit = handleSubmit(onSubmit);

  const editionRef = useRef<HTMLSelectElement | null>(null);
  const { ref, ...editionProps } = register("edition", {
    value: formData?.edition,
    required: {
      value: true,
      message: "This field is required",
    },
  });

  useEffect(() => {
    if (onboardingStatus === "idle") {
      editionRef.current?.focus();
    }
  }, [onboardingStatus]);

  const country = watch("country");
  const isCountrySelected = Boolean(country);

  const {
    data: merchantAccounts,
    isLoading: isLoadingMerchantAccounts,
    error: merchantAccountsError,
  } = useMerchantAccountsQuery(companyId);

  const {
    data: timezones,
    isLoading: isLoadingTimezones,
    error: timezonesError,
  } = useTimezonesQuery(country, {
    enabled: isCountrySelected,
    onSuccess() {
      if (formData?.timezone) {
        setValue("timezone", formData.timezone);
      }
    },
  });

  return (
    <>
      <div>
        <div className="mt-3 mb-10 text-sm text-gray-400">
          * All the fields are mandatory
        </div>
        <form onSubmit={onFormSubmit} aria-label="Add new client">
          <div className="mb-5 flex grid-cols-4 grid-rows-4 flex-col gap-x-5 gap-y-10 sm:grid sm:gap-y-4">
            {/* EDITION */}
            <div>
              <Select
                id="edition"
                label="Edition"
                options={editions}
                errorMessage={errors?.edition?.message}
                required
                {...register("edition", {
                  value: formData?.edition,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                tabIndex={1}
              />
            </div>

            {/* ID */}
            <div>
              <Input
                id="jackrabbitId"
                label="Jackrabbit Id"
                errorMessage={errors?.jackrabbitId?.message}
                type="text"
                minLength={1}
                maxLength={42}
                {...register("jackrabbitId", {
                  onBlur: (e) => {
                    setValue("jackrabbitId", e.target.value.trim());
                  },
                  value: formData?.jackrabbitId,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>

            {/* LEGAL ENTITY TYPE */}
            <div></div>

            {/* COMPANY NAME */}
            <div className="col-span-2">
              <Input
                id="companyName"
                label="Company Name"
                errorMessage={errors?.companyName?.message}
                type="text"
                {...register("companyName", {
                  value: formData?.companyName,
                  required: { value: true, message: "This field is required" },
                })}
              />
            </div>

            {/* COUNTRY */}
            <div>
              <Select
                id="country"
                label="Country"
                options={countries}
                errorMessage={errors?.country?.message}
                required
                {...register("country", {
                  value: formData?.country,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>

            {/* TIMEZONE */}
            <div>
              <Select
                id="timezone"
                label="Timezone"
                className="w-56"
                loading={isCountrySelected && isLoadingTimezones}
                required
                options={
                  timezones?.map((x) => ({
                    value: x.code,
                    label: x.name,
                  })) ?? []
                }
                errorMessage={timezonesError || errors?.timezone?.message}
                {...register("timezone", {
                  disabled: !isCountrySelected || isLoadingTimezones,
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>

            {/* COMPANY EMAIL */}
            <div className="col-span-2">
              <Input
                id="companyEmail"
                label="Company Email"
                errorMessage={errors?.companyEmail?.message}
                type="text"
                {...register("companyEmail", {
                  value: formData?.companyEmail,
                  required: { value: true, message: "This field is required." },
                  pattern: {
                    value: emailRegexp,
                    message: "Please enter a valid email.",
                  },
                })}
              />
            </div>

            {/* MERCHANT ACCOUNT */}
            <div className="col-span-2">
              <Select
                id="merchantAccountId"
                label="Merchant Account"
                options={
                  merchantAccounts?.map((merchantAccount) => ({
                    value: merchantAccount.merchantAccountId,
                    label: merchantAccount.merchantAccountName,
                  })) || []
                }
                required
                disabled={isLoadingMerchantAccounts}
                loading={isLoadingMerchantAccounts}
                errorMessage={
                  merchantAccountsError || errors?.merchantAccountId?.message
                }
                {...register("merchantAccountId", {
                  value: formData?.merchantAccountId,
                  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedMerchantAccount = merchantAccounts?.at(
                      e.target.selectedIndex - 1
                    );

                    if (selectedMerchantAccount) {
                      setValue(
                        "merchantAccountName",
                        selectedMerchantAccount.merchantAccountName
                      );
                    }
                  },
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>

            {/* FIRST NAME */}
            <div className="relative sm:mt-6">
              <span className="text-md absolute -top-11 left-0 font-medium sm:left-2">
                Contact
              </span>
              <Input
                id="firstName"
                label="First Name"
                errorMessage={errors?.firstName?.message}
                type="text"
                {...register("firstName", {
                  value: formData?.firstName,
                  required: { value: true, message: "This field is required" },
                })}
              />
            </div>

            {/* LAST NAME */}
            <div className="relative flex flex-col sm:mt-6">
              <Input
                id="lastName"
                label="Last Name"
                errorMessage={errors?.lastName?.message}
                type="text"
                {...register("lastName", {
                  value: formData?.lastName,
                  required: { value: true, message: "This field is required" },
                })}
              />
            </div>
            <div className="flex flex-col"></div>
          </div>

          <div className="flex justify-end gap-4">
            <Button variant="secondary" onClick={onCancel} type="button">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!isValid || onboardingStatus === "loading"}
              className="flex"
              icon={
                onboardingStatus === "loading" && (
                  <Spinner className="mt-0.5 mr-1 text-white" />
                )
              }
            >
              <span>
                {onboardingStatus === "loading" ? "Onboarding..." : "Onboard"}
              </span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddNewClassicClientForm;
