import Error from "./Error";
import Header from "./Header";

function ErrorFullScreen(): JSX.Element {
  return (
    <>
      <Header showMenuIcon={false} />
      <div className="flex h-screen w-screen items-center justify-center">
        <Error message="Error while trying to authenticate. Please try again." />
      </div>
    </>
  );
}

export default ErrorFullScreen;
