import DetailField from "../../components/DetailField";
import PaymentIcon from "../../components/PaymentIcon";
import { Transaction } from "../../services/transactionsService";
import { getTransactionStatusColor } from "../../utils/transaction";
import HasAccess, { csrRoles } from "../../components/HasAccess";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import Panel from "../customerSettings/Panel";

export type Props = {
  transaction?: Transaction;
  isLoading?: boolean;
};

function TransactionSummary({
  transaction,
  isLoading = false,
}: Props): JSX.Element {
  const statusColor = getTransactionStatusColor(
    transaction?.paymentLifeCycleStatus ?? "",
    "transactions"
  );

  const adyenLinkUrl =
    process.env.REACT_APP_ADYEN_PSP_URL_TEMPLATE?.replace(
      "{pspReference}",
      transaction?.pspReference ?? ""
    ) ?? "";

  return (
    <>
      <Panel className="rounded-lg border border-gray-200">
        <Panel.Header title="Summary" className="rounded-b-none" />
        <Panel.Content>
          <div className="grid-rows-[repeat(3, 50px)] grid w-full grid-cols-1 gap-x-8 gap-y-4 p-4 md:grid-cols-2 md:p-2 lg:grid-cols-3">
            <DetailField label="Status" isLoading={isLoading}>
              <span className="flex items-center gap-x-2">
                <div className={`h-2 w-2 rounded-full ${statusColor}`}></div>
                {transaction?.paymentLifeCycleStatus}
              </span>
            </DetailField>
            <DetailField label="Amount" isLoading={isLoading}>
              {transaction?.amountValue && transaction?.amountCurrency
                ? `${transaction?.amountCurrency} ${transaction?.amountValue}`
                : ""}
            </DetailField>
            <DetailField label="Date" isLoading={isLoading}>
              {transaction?.transactionCreatedDate} (
              {transaction?.accountHolderTimeZone?.code})
            </DetailField>
            <DetailField label="PSP Reference" isLoading={isLoading}>
              <span className="flex gap-x-2">
                {transaction?.pspReference}
                <HasAccess allowedRoles={[...csrRoles]}>
                  <a
                    href={adyenLinkUrl}
                    target="adyenSite"
                    title="Go to payment in Adyen"
                  >
                    <ArrowUpOnSquareIcon className="h-5 w-5 text-primary-700" />
                  </a>
                </HasAccess>
              </span>
            </DetailField>
            <DetailField label="Transaction Type" isLoading={isLoading}>
              <span className="flex items-center gap-x-2">
                <PaymentIcon type={transaction?.paymentMethod || ""} />
              </span>
            </DetailField>
            <DetailField label="Auth Code" isLoading={isLoading}>
              {transaction?.authCode}
            </DetailField>
            <DetailField label="Merchant Reference" isLoading={isLoading}>
              {transaction?.merchantReference}
            </DetailField>
            <DetailField label="Merchant Account" isLoading={isLoading}>
              {transaction?.merchantAccount}
            </DetailField>
            <DetailField label="Currency" isLoading={isLoading}>
              {transaction?.amountCurrency}
            </DetailField>
            <DetailField label="Business" isLoading={isLoading}>
              {transaction?.business}
            </DetailField>
            <DetailField label="Account Holder" isLoading={isLoading}>
              {transaction?.accountHolderCode}
            </DetailField>
            <DetailField label="Location" isLoading={isLoading}>
              {transaction?.location}
            </DetailField>
          </div>
        </Panel.Content>
      </Panel>
      {/* <section className="mb-2 rounded border border-gray-200 shadow-sm">
        <h3 className="bg-slate-100 px-8 py-2 text-xl font-semibold md:px-6">
          Summary
        </h3>
      </section> */}
    </>
  );
}

export default TransactionSummary;
