import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useSnackbar } from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import useCancelTransactionMutation from "../../hooks/data/mutations/useCancelTransactionMutation";

type Props = {
  isOpen: boolean;
  pspReference: string;
  merchantAccount: string;
  onClose: () => void;
};

function CancelTransactionModal({
  isOpen,
  pspReference,
  merchantAccount,
  onClose,
}: Props): JSX.Element {
  const { mutate: cancelTransaction, isLoading } =
    useCancelTransactionMutation();
  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
    classes: "top-24",
  });

  function handleSubmitCancelClick() {
    cancelTransaction(
      { pspReference, merchantAccount },
      {
        onSuccess() {
          openSnackbar("Request to cancel the payment has been received.", {
            type: "success",
          });
        },
        onError() {
          openSnackbar("An error has occurred. Please try again.", {
            type: "error",
          });
        },
        onSettled() {
          onClose();
        },
      }
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Cancel payment"
      showCloseButton={false}
      panelClasses="sm:w-[36rem]"
      onClose={() => onClose()}
    >
      <div className="mt-5 flex flex-col">
        <p className="text-justify">
          You are about to permanently cancelling a payment and you will not be
          able to reverse this action.
        </p>
        <p className="py-2">Do you want to proceed?</p>
        <div className="mt-4 flex justify-center gap-x-16">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => onClose()}
            className="w-20"
          >
            No
          </Button>
          <Button
            size="sm"
            onClick={() => handleSubmitCancelClick()}
            disabled={isLoading}
            className="w-20 justify-center"
            icon={isLoading && <Spinner className="mt-0.5 mr-1 text-white" />}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CancelTransactionModal;
