import {
  getTimeZonesByCountryCode,
  GetTimeZonesResponse,
} from "../../../services/accountService";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export default function useTimezonesQuery(
  countryCode: string,
  queryOptions?: UseQueryOptions<GetTimeZonesResponse, string>
) {
  return useQuery<GetTimeZonesResponse, string>(
    ["timezones", countryCode],
    () => getTimeZonesByCountryCode(countryCode),
    queryOptions
  );
}
