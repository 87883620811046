import { NavLink } from "react-router-dom";
import useClickOutside from "../../hooks/useClickOutside";
import { logPageVisit } from "../../utils/appInsightsLogger";

type Props = {
  label: string;
  to: string;
  icon: React.ReactNode;
  accountHolderCode?: string;
  logPageVisit?: () => void;
};

function HomeIcon({
  label,
  to,
  icon,
  accountHolderCode,
  logPageVisit,
}: Props): JSX.Element {
  return (
    <NavLink
      onClick={() => {
        logPageVisit && logPageVisit();
        if (accountHolderCode) {
          localStorage.setItem("accountHolderCode", accountHolderCode);
        }
      }}
      to={to}
      className="flex cursor-pointer flex-col items-center justify-center transition-transform hover:scale-110"
    >
      {icon}
      <div className="text-2xl">{label}</div>
    </NavLink>
  );
}

export default HomeIcon;
