import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import CurrencyDollarIcon from "@heroicons/react/24/outline/CurrencyDollarIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/outline/ClipboardDocumentListIcon";
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon";
import Cog6ToothIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import HasAccess, { csrRoles } from "../../components/HasAccess";
import HomeIcon from "./HomeIcon";
import { getTransactionsPageDefaultUrl } from "../../utils/transaction";
import { getOnboardingPageDefaultUrl } from "../../utils/accounts";
import { logPageVisit } from "../../utils/appInsightsLogger";

function Home(): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();

  const logVisit = () => {
    logPageVisit("Transactions", currentUserAccount?.username);
  };

  return (
    <div className="flex items-center justify-center px-6">
      <div className="mt-32 flex w-full flex-wrap justify-around gap-x-10 gap-y-6 lg:w-1/3">
        <HomeIcon
          logPageVisit={logVisit}
          label="Transactions"
          to={getTransactionsPageDefaultUrl()}
          icon={<CurrencyDollarIcon className="w-24 text-primary-700" />}
        />
        <HomeIcon
          label="Reports"
          to="/reports"
          icon={<ClipboardDocumentListIcon className="w-24 text-primary-700" />}
        />
        <HasAccess allowedRoles={[...csrRoles]}>
          <HomeIcon
            label="Clients"
            to={getOnboardingPageDefaultUrl()}
            icon={<UserGroupIcon className="w-24 text-primary-700" />}
          />
        </HasAccess>
        <HomeIcon
          label={currentUserAccount?.type === "CSR" ? "System" : "Settings"}
          to={
            currentUserAccount?.type === "CSR"
              ? "/system-settings"
              : "/customer-settings"
          }
          icon={<Cog6ToothIcon className="w-24 text-primary-700" />}
        />
      </div>
    </div>
  );
}

export default Home;
