import React, { MutableRefObject, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  id?: string;
  label?: string;
  errorMessage?: string;
  disableDecimals?: boolean;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  containerProps?: React.HTMLAttributes<HTMLElement>;
  adornmentText?: string;
};

const Input = React.forwardRef<
  HTMLInputElement,
  Props & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      id = "",
      label = "",
      errorMessage = "",
      disableDecimals = false,
      labelProps = {},
      containerProps = {},
      adornmentText = "",
      ...inputProps
    },
    ref
  ): JSX.Element => {
    const { className: containerClassName = "", ...otherContainerProps } =
      containerProps;
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (disableDecimals && inputProps.type !== "number") {
        console.warn(
          `disableDecimals prop is valid only when Input type is numeric. Current Input type is ${inputProps.type}.`
        );
      }
    }, [disableDecimals, inputProps]);

    function handleKeyDown(e: React.KeyboardEvent) {
      if (inputProps.type === "number") {
        const keysToIgnore = disableDecimals ? ["KeyE", "Period"] : ["KeyE"];

        if (keysToIgnore.includes(e.code)) {
          e.preventDefault();
        }
      }
    }

    return (
      <span
        {...otherContainerProps}
        className={twMerge("relative flex flex-col", containerClassName)}
      >
        {adornmentText && (
          <div className="absolute top-[3px] left-1 flex h-7 w-9 items-center justify-center rounded bg-gray-200 px-1 text-sm text-gray-500">
            {adornmentText}
          </div>
        )}

        <input
          ref={ref || inputRef}
          id={id}
          placeholder={label}
          {...inputProps}
          onKeyDown={handleKeyDown}
          className={twMerge(
            "peer rounded-md border border-gray-300 px-2 py-1 placeholder-transparent shadow-sm focus:border-primary-700/50 focus:ring-1 focus:ring-primary-700 focus-visible:outline-none",
            errorMessage && "border-1 border-error-300",
            adornmentText && "pl-12",
            inputProps.disabled &&
              "cursor-not-allowed bg-gray-50 text-gray-400",
            inputProps.className
          )}
        />
        {label && (
          <label
            onClick={(e) => {
              if (ref) {
                (ref as MutableRefObject<HTMLInputElement>).current?.focus();
                return;
              }
              if (inputRef) {
                inputRef.current?.focus();
                return;
              }
            }}
            htmlFor={id}
            {...labelProps}
            className={twMerge(
              "absolute -top-6 cursor-text text-sm font-medium text-gray-800 transition-all peer-placeholder-shown:top-1.5 peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-500 peer-focus:-top-6 peer-focus:font-medium peer-focus:text-gray-800 peer-disabled:-top-6 peer-disabled:font-medium peer-disabled:text-gray-800",
              adornmentText ? "left-12" : "left-2"
            )}
          >
            {label}
          </label>
        )}
        <span
          data-testid={`error-${id}`}
          className="absolute top-9 left-2 mb-3 inline-block text-sm font-medium text-error-300"
        >
          {errorMessage}
        </span>
      </span>
    );
  }
);

export default Input;
