import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

function Skeleton({ className = "" }: Props): JSX.Element {
  return (
    <div
      className={twMerge(
        "h-5 w-24 animate-pulse rounded-md bg-slate-200",
        className
      )}
      data-testid="skeleton"
    />
  );
}

export default Skeleton;
