import React from "react";
import { twMerge } from "tailwind-merge";

export type ButtonProps = {
  variant?: "primary" | "secondary" | "link";
  size?: "sm" | "md" | "lg";
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  separator?: boolean;
  children?: React.ReactNode;
};

const baseClasses =
  "cursor-pointer rounded-md focus:outline-none focus:ring focus:ring-primary-700/50 focus:ring-opacity-50 focus:ring-offset-2 flex justify-center items-center gap-x-2";

const primaryClasses =
  "bg-primary-700 text-white hover:bg-primary-500 hover:shadow-md";
const secondaryClasses =
  "border border-primary-700 bg-white text-primary-700 hover:bg-slate-50 hover:shadow-md";
const linkClasses =
  "rounded-none border-b border-primary py-0 px-0 text-primary";
const disabledClasses =
  "cursor-not-allowed border-gray-200 bg-gray-200 text-gray-400 hover:bg-gray-200 hover:shadow-none";

const defaultIconProps = { className: "h-4 w-4" };

function Button({
  variant = "primary",
  size = "md",
  icon,
  iconPosition = "left",
  separator = false,
  children,
  ...buttonProps
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  const clonedIcon = React.isValidElement(icon)
    ? React.cloneElement(icon, {
        ...defaultIconProps,
        ...icon.props,
        className: `${defaultIconProps.className || ""} ${
          icon.props.className || ""
        }`,
      })
    : null;

  return (
    <button
      {...buttonProps}
      className={twMerge(
        baseClasses,
        variant === "primary" && primaryClasses,
        variant === "secondary" && secondaryClasses,
        buttonProps.disabled && disabledClasses,
        size === "sm" && "px-2 py-1",
        size === "md" && "px-3 py-2",
        size === "lg" && "px-4 py-3",
        iconPosition === "left" && "flex-row-reverse",
        variant === "link" && linkClasses,
        buttonProps.className
      )}
    >
      {children}
      {separator && <span className="h-full border-l border-white" />}
      {clonedIcon}
    </button>
  );
}

export default Button;
