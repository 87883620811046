import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
  TableMeta,
  SortingState,
  getSortedRowModel,
  OnChangeFn,
  VisibilityState,
} from "@tanstack/react-table";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import { PaginatorProps } from "../../src/types";
import TablePaginator from "./TablePaginator";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";

export type Props<T> = {
  id: string;
  data: T[];
  columns: ColumnDef<T, any>[];
  isLoading: boolean;
  noRowsText?: string;
  sorting?: SortingState;
  setSorting?: OnChangeFn<SortingState>;
  preventSortingColumns?: (keyof T)[];
  showPaginator?: boolean;
  paginatorOptions?: PaginatorProps;
  className?: string;
  meta?: TableMeta<any>;
  columnVisibility?: VisibilityState;
};

function Table<T extends {}>({
  id,
  data,
  columns,
  isLoading,
  noRowsText = "There are no rows to display.",
  sorting,
  setSorting,
  preventSortingColumns,
  showPaginator = true,
  paginatorOptions,
  className = "",
  meta,
  columnVisibility,
}: Props<T>): JSX.Element {
  const hasServerSideSorting = Boolean(sorting && setSorting);
  const [localSorting, setLocalSorting] = useState<SortingState>(sorting || []);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    state: {
      sorting: hasServerSideSorting ? sorting : localSorting,
      columnVisibility,
    },
    manualSorting: hasServerSideSorting,
    onSortingChange: hasServerSideSorting ? setSorting : setLocalSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    defaultColumn: {
      minSize: 5,
    },
    columnResizeMode: "onChange",
    enableColumnResizing: data.length > 0,
    meta,
  });

  const hasRows = getRowModel().rows.length > 0;

  const columnCanSort = (headerId: string) => {
    return !preventSortingColumns?.includes(headerId as keyof T);
  };

  return (
    <div
      className={twMerge(
        "mb-4 rounded-lg border border-gray-200 shadow-sm",
        className
      )}
    >
      <div
        className={twMerge(
          "overflow-x-auto overflow-y-hidden bg-gray-50",
          showPaginator ? "rounded-t-lg" : "rounded-lg"
        )}
      >
        <table
          id={id}
          className="w-full table-fixed border-collapse overflow-x-auto"
        >
          <thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={twMerge("border-b border-gray-200 bg-gray-50")}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={twMerge(
                      "relative border-r border-gray-200 text-left text-xs font-medium uppercase text-gray-600 transition-colors",
                      header.column.getIsResizing() && "bg-gray-100",
                      header.column.getCanSort() &&
                        !isLoading &&
                        columnCanSort(header.id) &&
                        "cursor-pointer",
                      isLoading && "pointer-events-none"
                    )}
                    style={{ width: header.getSize() }}
                  >
                    <span
                      onClick={header.column.getToggleSortingHandler()}
                      className={twMerge(
                        "flex select-none items-center justify-between gap-x-1 p-2",
                        isLoading && "text-gray-400"
                      )}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {(header.column.getIsSorted() ||
                        (hasServerSideSorting &&
                          header.column.id.toLowerCase() ===
                            sorting?.at(0)?.id.toLowerCase())) && (
                        <ChevronDownIcon
                          className={twMerge(
                            "h-4 w-4 flex-shrink-0 transition-transform duration-150",
                            header.column.getIsSorted() === "asc"
                              ? "rotate-180"
                              : "rotate-0"
                          )}
                        />
                      )}
                    </span>
                    {hasRows && (
                      <span
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className="absolute right-0 bottom-0 h-full w-1 select-none hover:cursor-col-resize"
                      />
                    )}
                    {header.column.getIsResizing() && (
                      <>
                        {["left", "right"].map((x) => (
                          <span
                            key={x}
                            className={`absolute top-0 ${x}-0 inline-block h-screen w-[1px] bg-gray-300`}
                          />
                        ))}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {hasRows ? (
              getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={twMerge(
                    "border-b border-gray-200 bg-white hover:bg-slate-100",
                    isLoading && "animate-pulse"
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="truncate px-2 py-2 text-left"
                      style={{
                        width: cell.column.getSize(),
                      }}
                    >
                      {isLoading ? (
                        <SkeletonCell />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <NoDataRow
                text={noRowsText}
                columnCount={columns.length}
                isLoading={isLoading}
                showPaginator={showPaginator}
              />
            )}
          </tbody>
        </table>
      </div>
      {showPaginator && paginatorOptions && (
        <TablePaginator {...paginatorOptions} id={id} />
      )}
    </div>
  );
}

function SkeletonCell(): JSX.Element {
  return (
    <div
      className="mx-auto my-0.5 h-5 w-1/2 rounded-md bg-slate-200"
      data-testid="skeleton"
    />
  );
}

function NoDataRow({
  text,
  columnCount,
  isLoading,
  showPaginator,
}: {
  text: string;
  columnCount: number;
  isLoading: boolean;
  showPaginator: boolean;
}): JSX.Element {
  if (isLoading) {
    return (
      <tr>
        {[...Array(columnCount)].map((_, i) => (
          <td key={i} className="py-5">
            <SkeletonCell />
          </td>
        ))}
      </tr>
    );
  }
  return (
    <tr
      className={twMerge(
        "bg-white",
        showPaginator && "border-b border-gray-200"
      )}
    >
      <td colSpan={columnCount}>
        <div className="flex justify-center p-6 text-gray-400">{text}</div>
      </td>
    </tr>
  );
}

export default Table;
