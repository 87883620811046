import {
  GetMerchantAccountsResponse,
  getMerchantAccountsSysConfig,
} from "../../../services/accountService";
import { useQuery } from "@tanstack/react-query";

export default function useMerchantAccountsSysConfigQuery(
  companyId: string = ""
) {
  return useQuery<GetMerchantAccountsResponse, string>(
    ["merchantAccounts", companyId],
    () => getMerchantAccountsSysConfig(companyId)
  );
}
