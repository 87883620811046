import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Button from "../../components/Button";
import Input from "../../components/Input";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import Select from "../../components/Select";
import { logReportInteraction } from "../../utils/appInsightsLogger";

const reportTypes = ["Daily", "Monthly", "Deposit", "1099-K"] as const;
type ReportType = typeof reportTypes[number];

function BasicReport(): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const [accountHolderCode, setAccountHolderCode] = useState<string>("");
  const yesterday = DateTime.now().minus({ days: 1 });
  const lastYear = DateTime.now().minus({ year: 1 });
  const [date, setDate] = useState<DateTime | null>(yesterday);
  const [reportType, setReportType] = useState<ReportType>("Daily");

  useEffect(() => {
    if (currentUserAccount?.type === "Customer") {
      setAccountHolderCode(currentUserAccount?.accountHolderCode ?? "");
    }
  }, [currentUserAccount?.accountHolderCode, currentUserAccount?.type]);

  useEffect(() => {
    const holderCode = localStorage.getItem("accountHolderCode");
    if (holderCode) {
      setAccountHolderCode(holderCode);
      localStorage.removeItem("accountHolderCode");
    }
  }, []);

  function handleGenerateReportClick() {
    switch (reportType) {
      case "Daily":
        logReportInteraction("Daily", currentUserAccount?.username);
        window.open(
          `/reports/daily/${accountHolderCode.trim()}/${date?.toFormat(
            "yyyy-MM-dd"
          )}`
        );
        break;
      case "Monthly":
        logReportInteraction("Monthly", currentUserAccount?.username);
        window.open(
          `/reports/monthly/${accountHolderCode.trim()}/${date?.toFormat(
            "yyyy-MM-dd"
          )}`
        );
        break;
      case "Deposit":
        logReportInteraction("Deposit", currentUserAccount?.username);
        window.open(
          `/reports/deposits/${accountHolderCode.trim()}/${date?.toFormat(
            "yyyy-MM-dd"
          )}`
        );
        break;
      case "1099-K":
        const codeParts = accountHolderCode.trim().split("-");
        codeParts[0] = codeParts[0].toUpperCase();
        logReportInteraction("1099-K", currentUserAccount?.username);
        window.open(
          `/reports/1099-K/${codeParts.join("-")}/${date?.toFormat("yyyy")}`
        );
    }
  }

  function getDateFormat() {
    switch (reportType) {
      case "Daily":
        return "MM/dd/yyyy";
      case "Monthly":
      case "Deposit":
        return "MM/yyyy";
      case "1099-K":
        return "yyyy";
    }
  }

  function getCurrentDate(reportType: ReportType) {
    switch (reportType) {
      case "Daily":
        return yesterday;
      case "Monthly":
      case "Deposit":
        return date?.startOf("month");
      case "1099-K":
        return lastYear?.startOf("year");
      default:
        return date;
    }
  }

  return (
    <div className="mt-4 flex w-48 flex-col gap-y-7">
      <Select
        id="reportType"
        label="Report Type"
        options={reportTypes.map((x) => ({ value: x, label: x }))}
        value={reportType}
        onChange={(e) => {
          const newReportType = reportTypes[e.target.selectedIndex - 1];
          setReportType(newReportType);

          if (date) {
            setDate(getCurrentDate(newReportType) ?? date);
          }
        }}
      />
      <Input
        label="Account Holder"
        value={accountHolderCode}
        onChange={(e) => setAccountHolderCode(e.target.value)}
        disabled={currentUserAccount?.type === "Customer"}
      />
      <DatePicker
        selected={date?.toJSDate()}
        maxDate={
          reportType === "1099-K" ? lastYear.toJSDate() : yesterday.toJSDate()
        }
        customInput={<Input label="Date" />}
        dateFormat={getDateFormat()}
        showMonthYearPicker={
          reportType === "Monthly" || reportType === "Deposit"
        }
        showYearPicker={reportType === "1099-K"}
        onChange={(newDate) => {
          if (newDate) {
            setDate(DateTime.fromJSDate(newDate));
          }
        }}
      />
      <Button
        onClick={handleGenerateReportClick}
        disabled={accountHolderCode === "" || !date}
      >
        Generate Report
      </Button>
    </div>
  );
}

export default BasicReport;
