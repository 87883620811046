import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import {
  addNote,
  AddNoteBody,
  AddNoteResponse,
  Transaction,
} from "../../../services/transactionsService";
import { formatISODateToLocal, longDateFormat } from "../../../utils/dateTime";

export default function useAddNoteMutation() {
  const queryClient = useQueryClient();

  return useMutation<AddNoteResponse, Error, AddNoteBody>(
    ({ pspReference, noteText }) => addNote(pspReference, noteText),
    {
      onSuccess(newNote) {
        const transactionData = queryClient.getQueryData<Transaction>([
          "transaction",
          newNote.pspReference,
        ]);

        queryClient.setQueryData(["transaction", newNote.pspReference], {
          ...transactionData,
          paymentNotes: [
            {
              noteText: newNote.noteText,
              createdBy: newNote.createdBy,
              dateCreated: formatISODateToLocal(
                newNote.dateCreated,
                longDateFormat
              ),
            },
            ...(transactionData?.paymentNotes ?? []),
          ],
        });
        queryClient.invalidateQueries(["transaction", newNote.pspReference]);
      },
    }
  );
}
