export function formatAmount(
  value: number | string | undefined,
  currency: string = "",
  append = false
): string {
  const formattedAmount = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  }).format(Number(value));

  return currency
    ? append
      ? `${formattedAmount} ${currency}`
      : `${currency} ${formattedAmount}`
    : formattedAmount;
}
