import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateAccountHolderResponse,
  CreateAccountHolderBody,
  createAccountHolder,
} from "../../../services/accountService";

export default function useCreateAccountHolderMutation() {
  const queryClient = useQueryClient();

  return useMutation<
    CreateAccountHolderResponse,
    string,
    CreateAccountHolderBody
  >((accountHolderData) => createAccountHolder(accountHolderData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["accountHolders"]);
    },
  });
}
